import { useTranslation } from "react-i18next";
import img from "@/assets/images/404.png";
import img2x from "@/assets/images/404@2x.png";

export default function Page404() {
  const { t } = useTranslation();

  return (
    <div>
      <img
        className="mx-auto"
        style={{
          marginTop: "150px",
          width: "361px",
          height: "211px",
        }}
        src={img}
        srcSet={img2x + " 2x"}
        alt="404"
      />
      <p className="mx-auto text-center" style={{
				marginTop: '50px',
				fontSize: '16px',
				lineHeight: '24px',
				width: '500px',
			}}>{t("page404.msg")}</p>
    </div>
  );
}
