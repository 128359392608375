import AutoScrollToTop from "@/components/AutoScrollTop";
import { Outlet } from "react-router-dom";

export default function PortalLayout() {
  return (
    <>
      <Outlet />
      <AutoScrollToTop />
    </>
  );
}
