import { forwardRef, ForwardedRef, useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { copyRightOptions } from "@/config/options";
import { languageOptions } from "@/config/i18n";
import Spinner from "@/components/Spinner";
import MultipleSelectChip from "@/components/MultipleSelectChip";
import * as writingApi from "@/api/writing";
import * as tagApi from "@/api/tag";
import * as categoryApi from "@/api/category";
import { StoryEntity } from "@/entity/story";
import config from "@/config/config";

const lngOptions = languageOptions.filter((item) => config.siteLangs.includes(item.value));

interface Props {
  isEdit?: boolean;
  isPublish?: boolean;
  story?: StoryEntity | null;
  cover?: string;
  onSaveSuccess?: () => void;
}

const StoryDetails = forwardRef((props: Props, ref: ForwardedRef<HTMLFormElement>) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [categoryOptions, setCategoryOptions] = useState<{ value: number; label: string }[]>([]);
  const [tagOptions, setTagOptions] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const isEdit = props.isEdit || props.isPublish;
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: isEdit ? props.story!.title : "",
      description: isEdit ? props.story!.description : "",
      category_id: isEdit ? props.story!.category_id : void 0,
      tag_names: isEdit ? props.story!.tag_names || [] : [],
      copyright: isEdit ? props.story!.copyright : void 0,
      lang: isEdit ? props.story!.lang : "en-US",
      human_rating: isEdit ? props.story!.human_rating : false,
      is_completed: isEdit ? props.story!.is_completed : false,
      is_commitment: isEdit ? props.story!.is_commitment : false,
    },
  });

  const copyRightTip = copyRightOptions.find((item) => item.value === getValues("copyright"))?.desc;

  useEffect(() => {
    const loadOptions = async () => {
      setLoading(true);
      try {
        const [{ items: categoryItems }, { items: tagItems }] = await Promise.all([
          categoryApi.categoryList(),
          tagApi.tagList(),
        ]);

        setCategoryOptions(
          categoryItems.map((c) => ({
            value: c.id,
            label: c.name,
          }))
        );
        setTagOptions(tagItems.map((item) => item.name));
      } finally {
        setLoading(false);
      }
    };

    loadOptions();
  }, []);

  const rules = {
    title: {
      ...(props.isPublish ? { required: t("writing.storyTitleRequired")! } : {}),
      maxLength: { value: 80, message: t("writing.storyTitleLengthMsg") },
    },
    description: {
      ...(props.isPublish ? { required: t("writing.storyDescriptionRequired")! } : {}),
      maxLength: { value: 2000, message: t("writing.storyDescriptionLengthMsg") },
    },
    category_id: props.isPublish
      ? { valueAsNumber: true, validate: (v?: number) => (!!v ? void 0 : t("writing.storyCategoryRequired")) }
      : {},
    lang: { required: t("writing.storyLanguageRequired")! },
    copyright: props.isPublish
      ? { valueAsNumber: true, validate: (v?: number) => (!!v ? void 0 : t("writing.storyCopyRightRequired")) }
      : {},
    is_commitment: props.isPublish ? { required: t("writing.storyCommitmentRequired")! } : {},
  };

  const onSubmit = async (params: writingApi.CreateStoryParams) => {
    setLoading(true);
    try {
      params.title = params.title.trim() || t("writing.defaultStoryTitle");
      if (isEdit) {
        await writingApi.updateStory({ story_id: props.story!.id, ...params });
        if (!props.isPublish) {
          toast.success(t("common.saved"));
        }
      } else {
        const story = await writingApi.createStory({ ...params, cover: props.cover });
        const part = await writingApi.createPart({
          story_id: story.id,
          content: "",
        });

        navigate(`/writing/story/${story.id}/part/${part.id}`);
      }
    } finally {
      setLoading(false);
    }

    if (props.onSaveSuccess) {
      props.onSaveSuccess();
    }
  };

  return (
    <Spinner loading={loading}>
      <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)} ref={ref}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <Controller
                name="title"
                control={control}
                rules={rules.title}
                render={({ field }) => (
                  <FormControl error={!!errors.title}>
                    <FormLabel sx={{ fontWeight: 500 }} htmlFor="title">
                      {t("writing.storyTitle")}
                    </FormLabel>
                    <OutlinedInput id="title" placeholder={t("writing.defaultStoryTitle")!} size="small" {...field} />
                    {errors.title && (
                      <FormHelperText error={!!errors.title}>{errors.title.message! as string}</FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <Controller
                name="description"
                control={control}
                rules={rules.description}
                render={({ field }) => (
                  <FormControl error={!!errors.description}>
                    <FormLabel sx={{ fontWeight: 500 }} htmlFor="description">
                      {t("writing.storyDescription")}
                    </FormLabel>
                    <OutlinedInput id="description" {...field} size="small" multiline rows={5} />
                    {errors.description && (
                      <FormHelperText error={!!errors.description}>
                        {errors.description.message! as string}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="category_id"
              control={control}
              rules={rules.category_id}
              render={({ field }) => (
                <FormControl error={!!errors.category_id} className="w-full">
                  <FormLabel sx={{ fontWeight: 500 }} htmlFor="category_id">
                    {t("writing.storyCategory")}
                  </FormLabel>
                  <Select
                    id="category_id"
                    {...field}
                    fullWidth
                    displayEmpty
                    size="small"
                    renderValue={(selected: number) => {
                      if (!selected) {
                        return <Typography color={"darkgray"}>{t("writing.storyCategoryPlaceholder")}</Typography>;
                      }
                      return categoryOptions.find((item) => item.value === selected)?.label ?? "";
                    }}
                  >
                    <MenuItem key={0} value={void 0}>
                      {t("writing.storyCategoryPlaceholder")}
                    </MenuItem>
                    {categoryOptions.map((cat) => (
                      <MenuItem key={cat.value} value={cat.value}>
                        {cat.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.category_id && (
                    <FormHelperText error={!!errors.category_id}>
                      {errors.category_id.message! as string}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="tag_names"
              control={control}
              render={({ field }) => (
                <FormControl error={!!errors.tag_names} className="w-full">
                  <FormLabel sx={{ fontWeight: 500 }} htmlFor="tags">
                    {t("writing.storyTags")}
                  </FormLabel>
                  <MultipleSelectChip {...field} options={tagOptions} />
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="lang"
              control={control}
              rules={rules.lang}
              render={({ field }) => (
                <FormControl error={!!errors.lang} className="w-full">
                  <FormLabel sx={{ fontWeight: 500 }} htmlFor="lang">
                    {t("writing.storyLanguage")}
                  </FormLabel>
                  <Select
                    id="lang"
                    fullWidth
                    displayEmpty
                    size="small"
                    {...field}
                    renderValue={(selected: string) => {
                      if (!selected) {
                        return <Typography color={"darkgray"}>{t("writing.storyLanguagePlaceholder")}</Typography>;
                      }
                      return selected;
                    }}
                  >
                    {lngOptions.map((lang) => (
                      <MenuItem key={lang.value} value={lang.value}>
                        {lang.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.lang && (
                    <FormHelperText error={!!errors.lang}>{errors.lang.message! as string}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="copyright"
              control={control}
              rules={rules.copyright}
              render={({ field }) => (
                <FormControl error={!!errors.copyright} className="w-full">
                  <FormLabel sx={{ fontWeight: 500 }} htmlFor="copyright">
                    {t("writing.storyCopyRight")}
                  </FormLabel>
                  <Select
                    id="copyright"
                    fullWidth
                    displayEmpty
                    size="small"
                    {...field}
                    renderValue={(selected: number) => {
                      if (!selected) {
                        return <Typography color={"darkgray"}>{t("writing.storyCopyRightPlaceholder")}</Typography>;
                      }
                      return copyRightOptions.find((item) => item.value === selected)?.label ?? "";
                    }}
                  >
                    {copyRightOptions.map((cp) => (
                      <MenuItem key={cp.value} value={cp.value}>
                        {cp.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.copyright && (
                    <FormHelperText error={!!errors.copyright}>{errors.copyright.message! as string}</FormHelperText>
                  )}
                  {copyRightTip && (
                    <Typography variant="caption" color="text.secondary" sx={{ marginTop: "1rem" }}>
                      {t(copyRightTip)}
                    </Typography>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="human_rating"
              control={control}
              render={({ field }) => (
                <FormControl>
                  <FormLabel sx={{ fontWeight: 500 }} htmlFor="human_rating">
                    {t("writing.storyHumanRating")}
                  </FormLabel>
                  <Switch id="human_rating" {...field} />
                  <Typography variant="caption" color="text.secondary" sx={{ marginTop: "0.25rem" }}>
                    {t("writing.storyHumanRatingDesc")}
                  </Typography>
                </FormControl>
              )}
            />
          </Grid>
          {!!isEdit && (
            <Grid item xs={12}>
              <Controller
                name="is_completed"
                control={control}
                render={({ field }) => (
                  <FormControl>
                    <FormLabel sx={{ fontWeight: 500 }} htmlFor="is_completed">
                      {t("writing.storyStatus")}
                    </FormLabel>
                    <Switch id="is_completed" {...field} />
                  </FormControl>
                )}
              />
            </Grid>
          )}
          {!!props.isPublish && (
            <Grid item xs={12}>
              <Controller
                name="is_commitment"
                control={control}
                rules={rules.is_commitment}
                render={({ field }) => (
                  <>
                    <FormControlLabel
                      control={<Checkbox id="is_commitment" {...field} />}
                      label={<span className="font-color-normal">{t("writing.storyCommitmentText")}</span>}
                    />
                    {errors.is_commitment && (
                      <FormHelperText error={!!errors.is_commitment}>{errors.is_commitment.message! as string}</FormHelperText>
                    )}
                  </>
                )}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </Spinner>
  );
});
export default StoryDetails;
