import { Theme, useTheme } from "@mui/material/styles";
import {Box, OutlinedInput, MenuItem, Select, Chip} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import {ReactComponent as DeleteIcon} from "@/assets/icons/delete-12.svg";
import { ForwardedRef, forwardRef } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

function getStyles(item: string, value: readonly string[], theme: Theme) {
  return {
    fontWeight: value.indexOf(item) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

interface Props {
  options: string[];
  value: string[];
  onChange: (value: string[]) => void;
}

const MultipleSelectChip = forwardRef((props: Props, ref: ForwardedRef<HTMLDivElement | null>) => {
  const theme = useTheme();

  const handleChange = (event: SelectChangeEvent<typeof props.value>) => {
    const {
      target: { value },
    } = event;
    props.onChange(typeof value === "string" ? value.split(",") : value);
  };

  const handleDelete = (v: string) => {
    props.onChange(props.value.filter(item => item !== v));
  };

  return (
    <Select
      multiple
      fullWidth
      value={props.value}
      size="small"
      onChange={handleChange}
      input={<OutlinedInput />}
      ref={ref}
      renderValue={selected => (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
          {selected.map(v => (
            <Chip
              key={v}
              label={"# " + v}
              sx={{
                backgroundColor: "var(--bg-color-highlight)",
                color: theme.palette.primary.main,
                fontSize: "0.75rem",
                height: "1.375rem",
                lineHeight: "1.375rem",
                ".MuiChip-label": {
                  paddingLeft: "8px",
                  paddingRight: "8px"
                },
                ".MuiChip-deleteIcon": {
                  marginRight: "8px",
                  marginLeft: "-4px"
                }
              }}
              onDelete={() => handleDelete(v)}
              deleteIcon={<DeleteIcon />}
              onMouseDown={event => {
                event.stopPropagation();
              }}
            />
          ))}
        </Box>
      )}
      MenuProps={MenuProps}
    >
      {props.options.map(item => (
        <MenuItem key={item} value={item} style={getStyles(item, props.value, theme)}>
          {item}
        </MenuItem>
      ))}
    </Select>
  );
})

export default MultipleSelectChip