/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";

interface FilterItemProps {
  selected: boolean;
  label: string;
  className?: string;
  onClick: (event: SyntheticEvent) => void;
}

export const FilterItem = (props: FilterItemProps) => {
  const selectedCss = props.selected
    ? "background: var(--bg-color-highlight); border-color: var(--bg-color-highlight)"
    : "";

  return (
    <span
      className={props.className}
      css={css`
        display: inline-block;
        color: var(--primary-color);
        background: #fff;
        border: 1px solid var(--border-color-default);
        line-height: 20px;
        padding: 5px 16px;
        text-align: center;
        margin-right: 24px;
        border-radius: 20px;
        cursor: pointer;
        ${selectedCss}
      `}
      onClick={props.onClick}
    >
      {props.label}
    </span>
  );
};

interface RadioFilterProps {
  title?: string;
  options: Array<{ value: number; label: string }>;
  addAll?: boolean;
  value?: number;
  onChange: (value: number) => void;
}

export function RadioFilter(props: RadioFilterProps) {
  const { t } = useTranslation();
  const options = [...(props.addAll ? [{ value: 0, label: t("common.all") }] : []), ...props.options];

  return (
    <div
      className="filter"
      css={css`
        margin-top: 1rem;
        margin-bottom: 0.5rem;
      `}
    >
      {props.title && (
        <div
          className="filter-title"
          css={css`
            font-size: 1rem;
            line-height: 1.4375;
            margin-bottom: 1rem;
            color: var(--font-color-grey);
          `}
        >
          {props.title}
        </div>
      )}
      <div className="filter-content">
        {options.map((item) => (
          <FilterItem
            onClick={() => props.onChange(item.value)}
            key={item.value}
            label={item.label}
            selected={item.value === props.value || (!item.value && !props.value)}
          />
        ))}
      </div>
    </div>
  );
}

interface CheckboxFilterProps {
  title?: string;
  options: Array<{ value: number; label: string }>;
  addAll?: boolean;
  value?: number[];
  onChange: (value?: number[]) => void;
}

export function CheckboxFilter(props: CheckboxFilterProps) {
  const { t } = useTranslation();
  const options = [...(props.addAll ? [{ value: 0, label: t("common.all") }] : []), ...props.options];

  const handleChange = (id: number) => {
    if (id === 0) {
      props.onChange();
    } else if (props.value?.includes(id)) {
      props.onChange(props.value!.filter((v) => v !== id));
    } else {
      props.onChange([...(props.value || []), id]);
    }
  };

  return (
    <div
      className="filter"
      css={css`
        margin-top: 1rem;
        margin-bottom: 0.5rem;
      `}
    >
      {props.title && (
        <div
          className="filter-title"
          css={css`
            font-size: 1rem;
            line-height: 1.4375;
            margin-bottom: 1rem;
            color: var(--font-color-grey);
          `}
        >
          {props.title}
        </div>
      )}
      <div className="filter-content mb-[-16px]">
        {options.map((item) => (
          <FilterItem
            onClick={() => handleChange(item.value)}
            key={item.value}
            label={item.label}
            className="mb-[16px]"
            selected={props.value?.includes(item.value) || (!item.value && !props.value?.length)}
          />
        ))}
      </div>
    </div>
  );
}
