import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { initConfig } from "./config/config";
import { initI18n } from "./utils/i18n";
import { initLogin } from "@/utils/user";
import App from "./App";

function loginBroadcast() {
  try {
    // 登录成功后刷新页面
    if (window.BroadcastChannel) {
      const channelName = "bigcan_login_success";
      const bc = new BroadcastChannel(channelName);
      bc.onmessage = (e) => {
        if (e.data === "login_success") {
          bc.postMessage("login_success_ack");
          window.location.reload();
        }
      };
      if (window.location.search.includes("login_success")) {
        bc.onmessage = (e) => {
          if (e.data === "login_success_ack") {
            window.close();
          }
        };
        bc.postMessage("login_success");
      }
    }
  } catch (e) {
    console.error(e);
  }
}

async function init() {
  let user: Awaited<ReturnType<typeof initLogin>> = null;
  try {
    const config = await initConfig();
    document.title = config.siteName;
    await initI18n(config.siteLangs);
    user = await initLogin();
  } catch (e) {
    console.error(e);
  }
  document.getElementById("root")!.innerHTML = "";
  const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

  root.render(<App user={user} />);
}

loginBroadcast();
init();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
