import { Fragment, ReactElement, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, List, ListItem, ListItemText, Box, Divider, Typography } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import * as writingApi from "@/api/writing";
import { PartEntity } from "@/entity/part";
import { formatTime } from "@/utils/format";
import { ClickableRichTooltip } from "@/components/RichTooltip";
import Spinner from "@/components/Spinner";
import { ReactComponent as PartActiveIcon } from "@/assets/icons/part-active.svg";

interface Props {
  story_id: number;
  parts?: PartEntity[];
  active_part_id?: number;
  children: ReactElement;
}

const PartsMenu = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [parts, setParts] = useState(props.parts || []);
  const [loading, setLoading] = useState(false);

  const handleMenuItemClick = (part_id: number) => {
    navigate("/writing/story/" + props.story_id + "/part/" + part_id);
  };

  const loadParts = useCallback(async () => {
    if (props.story_id) {
      const {parts} = await writingApi.getStoryParts({ story_id: props.story_id });
      setParts(parts);
    }
  }, [props.story_id]);

  useEffect(() => {
    if (!props.parts) {
      loadParts();
    }
  }, [props.story_id]);

  const handleNewPart = async () => {
    setLoading(true);
    try {
      const story_id = props.story_id;
      const part = await writingApi.createPart({
        story_id: props.story_id,
        content: "",
      });

      navigate(`/writing/story/${story_id!}/part/${part.id}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Spinner loading={loading}></Spinner>
      <ClickableRichTooltip
        padding={0}
        content={
          <Box>
            <List sx={{ width: "20rem", padding: 0, height: '576px', overflowY: 'auto' }} dense>
              {parts.map(part => (
                <Fragment key={part.id}>
                  <ListItem
                    onClick={event => handleMenuItemClick(part.id)}
                    className="cursor-pointer"
                    sx={{
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                    secondaryAction={
                      props.active_part_id && props.active_part_id === part.id && <PartActiveIcon />
                    }
                  >
                    <ListItemText
                      primary={
                        <Typography className="font-color-grey line-clamp-2" variant="button">
                          {part.title}
                        </Typography>
                      }
                      secondary={
                        <span className="font-color-grey block">
                          <span className={part.is_published ? "font-color-primary" : ""}>
                            {part.is_published ? t("common.published") : t("common.draft")}
                          </span>
                          <span className="ml-2">{formatTime(part.update_time, true)}</span>
                        </span>
                      }
                    />
                  </ListItem>
                  <Divider />
                </Fragment>
              ))}
            </List>
            <Box style={{ padding: "8px 0", textAlign: "center" }}>
              <Button
                onClick={handleNewPart}
                variant="contained"
                startIcon={<AddOutlinedIcon />}
                sx={{ paddingLeft: "40px", paddingRight: "40px" }}
              >
                {t("writing.newPart")}
              </Button>
            </Box>
          </Box>
        }
        placement="bottom-end"
      >
        {props.children}
      </ClickableRichTooltip>
    </>
  );
};
export default PartsMenu;
