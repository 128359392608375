import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useNavigateLogin } from "@/hooks";
import { AuthContext } from "@/context";
import LoginForm from "./components/LoginForm";
import { ReactComponent as FacebookIcon } from "@/assets/icons/facebook.svg";
import { ReactComponent as GoogleIcon } from "@/assets/icons/google.svg";
import LoginFooter from "./components/LoginFooter";
import LoginTitle from "./components/LoginTitle";
import { getGoogleOauth2Url, getFacebookOauth2Url } from "@/api/user";

export default function Login() {
  const { t } = useTranslation();
  const { navigateBack } = useNavigateLogin();
  const [loginWithEmail, setLoginWithEmail] = useState(false);

  const { isLogin } = useContext(AuthContext);
  useEffect(() => {
    if (isLogin) {
      navigateBack();
    }
  });

  if (loginWithEmail) {
    return <LoginForm onBack={() => setLoginWithEmail(false)} />;
  }

  return (
    <>
      <LoginTitle onBack={navigateBack}></LoginTitle>
      <div className="login-content">
        <div className="text-center">
          <a href="/" className="logo inline-block h-[65px]">
            <img src="/logo.png" alt="" className="h-full" />
          </a>
        </div>
        <div className="slogan text-center text-base mt-4">{t("common.slogan")}</div>
        <div className="mt-7">
          <Button
            fullWidth
            variant="contained"
            startIcon={<FacebookIcon className="mr-4" />}
            sx={{
              "height": "56px",
              "color": "#fff",
              "background": "#3C5898",
              "&:hover": {
                background: "#3C5898",
              },
            }}
            target="_blank"
            href={getFacebookOauth2Url()}
          >
            {t("login.facebookLogin")}
          </Button>
        </div>
        <div className="mt-4">
          <Button
            color="info"
            fullWidth
            variant="outlined"
            startIcon={<GoogleIcon className="mr-4" />}
            sx={{
              height: "56px",
            }}
            target="_blank"
            href={getGoogleOauth2Url()}
          >
            {t("login.googleLogin")}
          </Button>
        </div>
        <div className="my-4">
          <Divider>
            <span className="font-color-grey">{t("common.or")}</span>
          </Divider>
        </div>
        <div>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ height: "56px" }}
            onClick={() => setLoginWithEmail(true)}
          >
            {t("login.loginWithEmail")}
          </Button>
        </div>
        <LoginFooter />
      </div>
    </>
  );
}
