/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Divider, MenuItem, MenuList, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { ReadingListEntity } from "@/entity/reading-list";
import * as readingListApi from "@/api/reading-list";
import Spinner from "@/components/Spinner";
import ReadingListAddDialog from "./components/ReadingListAddDialog";
import { ClickableRichTooltip } from "@/components/RichTooltip";
import { useConfirm } from "@/hooks";
import { formatCover } from "@/utils/format";
import Empty from "@/components/Empty";
import PageTitle from "@/components/PageTitle";

export default function ReadingListList() {
  const { t } = useTranslation();
  const { confirm } = useConfirm();
  const [loading, setLoading] = useState(false);
  const [readinglistItems, setReadingListItems] = useState<ReadingListEntity[]>([]);
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  const load = async () => {
    try {
      setLoading(true);
      const data = await readingListApi.list({
        with_story_ids: true,
      });
      setReadingListItems(data.items);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    load();
  }, []);

  const handleDelete = async (readingList: ReadingListEntity) => {
    if (
      await confirm({
        title: t("readingList.deleteReadingList"),
        content: t("readingList.deleteReadingListTip"),
      })
    ) {
      await readingListApi.del({
        id: readingList.id,
      });
      load();
    }
  };

  const getMenu = (readingList: ReadingListEntity) => {
    const menuItems = [
      {
        label: t("common.delete"),
        onClick: handleDelete,
      },
    ].map((item, index) => [
      ...(index > 0 ? [<Divider />] : []),
      <MenuItem key={item.label} onClick={() => item.onClick(readingList)}>
        {" "}
        {item.label}{" "}
      </MenuItem>,
    ]);

    return (
      <MenuList
        dense
        sx={{
          "paddingTop": 0,
          "paddingBottom": 0,
          "& .MuiMenuItem-root+.MuiDivider-root": {
            margin: 0,
          },
        }}
      >
        {menuItems}
      </MenuList>
    );
  };

  return (
    <>
      <PageTitle name="readingList" />
      <div className="content-wrap">
        <Typography variant="h2" sx={{ margin: "32px 0", fontSize: "40px", fontWeight: 500, lineHeight: 1.45 }}>
          {t("readingList.readingList")}
        </Typography>
        <div className="readinglist-panel pt-5">
          <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => setAddDialogOpen(true)}>
            {t("readingList.addReadingList")}
          </Button>
          {!readinglistItems.length && !loading && <Empty />}
          <Spinner loading={loading} height="136px">
            <ul className="readinglist-list mt-5">
              {readinglistItems.map((item) => (
                <li key={item.id} className="flex border border-color-default px-6 py-2.5 -mt-[1px]">
                  <Link
                    to={`/readingList/${item.id}`}
                    className="relative"
                    css={css`
                      width: 54px;
                      height: 72px;
                      &:before,
                      &:after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        z-index: -1;
                        width: 100%;
                        height: 100%;
                        transform: scale(0.9) translate(-48%, -50%);
                        transform-origin: top right;
                        background: #e6eded;
                        border: 0.5px solid #b9bec1;
                      }
                      &:after {
                        z-index: -2;
                        transform: scale(0.8) translate(-47%, -50%);
                        background: #ede8d8;
                        border: 0.5px solid #b9bec1;
                      }
                    `}
                  >
                    <img
                      {...formatCover(item.latest_story?.cover, "small")}
                      alt={item.name}
                      css={css`
                        width: 100%;
                        height: 100%;
                        border: 0.5px solid #b9bec1;
                      `}
                    />
                  </Link>
                  <div className="info ml-7 flex-1">
                    <Link to={`/readingList/${item.id}`} className="name">
                      <Typography
                        variant="h4"
                        sx={{
                          fontSize: "18px",
                          fontWeight: "500",
                          lineHeight: 1.45,
                          marginBottom: "8px",
                        }}
                      >
                        {item.name}
                      </Typography>
                    </Link>
                    <Typography
                      variant="body1"
                      color="red"
                      sx={{
                        fontSize: "15px",
                      }}
                    >
                      {t("readingList.storiesCount", { count: item.story_ids?.length || 0 })!}
                    </Typography>
                  </div>
                  <div className="flex items-center">
                    <ClickableRichTooltip padding={0} content={getMenu(item)} placement="bottom-end">
                      <Button size="small" variant="outlined" color="info">
                        <MoreHorizIcon className="font-color-grey" />
                      </Button>
                    </ClickableRichTooltip>
                  </div>
                </li>
              ))}
            </ul>
          </Spinner>
        </div>
      </div>
      <ReadingListAddDialog open={addDialogOpen} onClose={() => setAddDialogOpen(false)} onCreated={() => load()} />
    </>
  );
}
