import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Switch,
  Typography,
} from "@mui/material";
import * as tagApi from "@/api/tag";
import * as preferencesApi from "@/api/preferences";
import Spinner from "@/components/Spinner";
import MultipleSelectChip from "@/components/MultipleSelectChip";
import { ReactComponent as BackIcon } from "@/assets/icons/back-arrow.svg";
import { ReactComponent as GotoIcon } from "@/assets/icons/goto-arrow.svg";

interface Props {
  onClose: () => void;
}
export default function Preferences(props: Props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isTagsView, setIsTagsView] = useState(false);
  const [humanRating, setHumanRating] = useState(false);
  const [tagOptions, setTagOptions] = useState<string[]>(["张三", "里斯"]);
  const [bannedTags, setBannedTags] = useState<string[]>([]);

  const handleDeleteTags = (tag: string) => {
    setBannedTags((prev) => prev.filter((item) => item !== tag));
  };

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        const [data, { items }] = await Promise.all([preferencesApi.get(), tagApi.tagList()]);
        setHumanRating(data.human_rating);
        setBannedTags(data.banned_tags);
        setTagOptions(items.map((item) => item.name));
      } finally {
        setLoading(false);
      }
    };

    load();
  }, []);

  const handleSubmit = async () => {
    await preferencesApi.set({
      human_rating: humanRating,
      banned_tags: bannedTags,
    });
    toast.success(t("common.success"));
    props.onClose();
  };

  const renderContent = () => {
    if (isTagsView) {
      return (
        <>
          <DialogTitle>
            <div className="flex items-center">
              <BackIcon className="mr-4 cursor-pointer" onClick={() => setIsTagsView(false)} />
              {t("layout.preferences.editTags")}
            </div>
            <Divider className="relative -bottom-4"></Divider>
          </DialogTitle>
          <DialogContent sx={{ width: 1000 }}>
            <div className="bannedTags pt-6 pb-12">
              <div className="mb-6">
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "18px",
                    lineHeight: "26px",
                    marginBottom: "16px",
                  }}
                >
                  {t("layout.preferences.bannedTags")}
                </Typography>
                <Typography variant="body1" className="font-color-grey">
                  {t("layout.preferences.bannedTagsDesc")}
                </Typography>
              </div>
              <div>
                <Typography variant="body1" className="font-color-grey" sx={{marginBottom: '8px'}}>
                  {t("layout.preferences.tagsSelect")}
                </Typography>
                <MultipleSelectChip value={bannedTags} onChange={setBannedTags} options={tagOptions} />
              </div>
            </div>
          </DialogContent>
        </>
      );
    }

    return (
      <>
        <DialogTitle>
          <div>{t("layout.preferences.title")}</div>
          <Divider className="relative -bottom-4"></Divider>
        </DialogTitle>
        <Spinner loading={loading}>
          <DialogContent sx={{ width: 1000 }}>
            <div className="humanRating flex justify-between items-center py-6">
              <div className="flex-1">
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "18px",
                    lineHeight: "26px",
                    marginBottom: "16px",
                  }}
                >
                  {t("layout.preferences.sensitiveContent")}
                </Typography>
                <Typography variant="body1" className="font-color-grey">
                  {t("layout.preferences.sensitiveContentDesc")}
                </Typography>
              </div>
              <div>
                <Switch checked={humanRating} onChange={(event) => setHumanRating(event.target.checked)} />
              </div>
            </div>
            <Divider></Divider>
            <div className="bannedTags">
              <div className="flex justify-between items-center py-6">
                <div className="flex-1">
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "18px",
                      lineHeight: "26px",
                      marginBottom: "16px",
                    }}
                  >
                    {t("layout.preferences.bannedTags")}
                  </Typography>
                  <Typography variant="body1" className="font-color-grey">
                    {t("layout.preferences.bannedTagsDesc")}
                  </Typography>
                </div>
                <div>
                  <Button variant="text" onClick={() => setIsTagsView(true)}>
                    <span className="font-color-default text-base font-medium">{t("layout.preferences.editTags")}</span>
                    <GotoIcon className="ml-2" />
                  </Button>
                </div>
              </div>
              {bannedTags.length > 0 && (
                <div className="tags mb-6">
                  {bannedTags.map((tag) => (
                    <Chip
                      key={tag}
                      label={"#" + tag}
                      color="primary"
                      size="small"
                      onDelete={() => handleDeleteTags(tag)}
                    />
                  ))}
                </div>
              )}
            </div>
            <Divider></Divider>
          </DialogContent>
        </Spinner>

        <DialogActions sx={{ padding: "16px" }}>
          <Button variant="contained" color="info" onClick={props.onClose} sx={{ marginRight: "16px", width: "96px" }}>
            {t("common.cancel")}
          </Button>
          <Button variant="contained" type="submit" sx={{ width: "96px" }} onClick={handleSubmit}>
            {t("common.confirm")}
          </Button>
        </DialogActions>
      </>
    );
  };

  return (
    <Dialog open onClose={props.onClose} maxWidth="lg" scroll="paper">
      {renderContent()}
    </Dialog>
  );
}
