import React, { ReactElement, useContext, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { AuthContext } from "@/context";
import { useNavigateLogin } from "@/hooks";
import * as libraryApi from "@/api/library";

interface Props {
  story_id: number;
  hasLoading?: boolean;
  children: ReactElement;
}
export default function AddToLibrary({ children, story_id, hasLoading = false }: Props) {
  const { t } = useTranslation();
  const { isLogin } = useContext(AuthContext);
  const { navigateLogin } = useNavigateLogin();
  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    if (!isLogin) {
      navigateLogin();
    }

    try {
      setLoading(true);
      await libraryApi.add({ story_id });
      toast.success(t("common.success"));
    } finally {
      setLoading(false);
    }
  };

  return React.cloneElement(children, {
    ...children.props,
    ...(hasLoading ? { loading } : null),
    onClick,
  });
}
