import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function FooterText() {
  const { t } = useTranslation();

  const text = t("login.footerText");

  return (
    <div className="mt-2 text-center font-color-grey">
      {!!text && (
        <>
          {text.substring(0, text.indexOf("{{terms}}"))}
          <Link to="/support/terms" className="font-color-grey underline">
            {t("login.footerTextTerms")}
          </Link>
          {text.substring(text.indexOf("{{terms}}") + 9, text.indexOf("{{privacy}}"))}
          <Link to="/support/privacy" className="font-color-grey underline">
            {t("login.footerTextPrivacy")}
          </Link>
          {text.substring(text.indexOf("{{privacy}}") + 11)}
        </>
      )}
    </div>
  );
}
