import { PartEntity } from "@/entity/part";
import { StoryEntity } from "@/entity/story";
import $http from "@/utils/http";

export interface MyStoryListParams extends ApiListParams {
  with_parts?: boolean;
  is_published?: boolean;
}
export function myStoryList(
  params?: MyStoryListParams
): Promise<ApiListResult<StoryEntity & { parts?: PartEntity[] }>> {
  return $http.request({
    url: "writing/myStoryList",
    method: "get",
    params,
  });
}

export interface MyStoryDetailResult extends StoryEntity {
  parts: PartEntity[]; // 章节
}
export function myStoryDetail(params: { story_id: number }): Promise<MyStoryDetailResult> {
  return $http.request({
    url: "writing/myStoryDetail",
    method: "get",
    params,
  });
}

export function getStoryParts(params: { story_id: number }): Promise<{ parts: PartEntity[] }> {
  return $http.request({
    url: "writing/storyParts",
    method: "get",
    params,
  });
}

export interface CreateStoryParams {
  title: string;
  description: string;
  category_id?: number;
  copyright?: number;
  tag_names?: string[];
  lang: string;
  human_rating: boolean;
  is_completed: boolean;
  cover?: string;
}
export function createStory(data: CreateStoryParams): Promise<StoryEntity> {
  return $http.request({
    url: "writing/createStory",
    method: "post",
    data,
  });
}

export function updateStory(data: CreateStoryParams & { story_id: number }): Promise<StoryEntity> {
  return $http.request({
    url: "writing/updateStory",
    method: "post",
    data,
  });
}

export interface PublishStoryParams {
  story_id: number;
}
export function publishStory(data: PublishStoryParams): Promise<StoryEntity> {
  return $http.request({
    url: "writing/publishStory",
    method: "post",
    data,
  });
}

export type UnpublishStoryParams = PublishStoryParams;
export function unpublishStory(data: UnpublishStoryParams): Promise<StoryEntity> {
  return $http.request({
    url: "writing/unpublishStory",
    method: "post",
    data,
  });
}

export interface CompleteStoryParams {
  story_id: number;
}
export function completeStory(data: CompleteStoryParams): Promise<StoryEntity> {
  return $http.request({
    url: "writing/completeStory",
    method: "post",
    data,
  });
}

export interface UpdateCoverParams {
  story_id: number;
  cover: string;
}
export function updateCover(data: UpdateCoverParams) {
  return $http.request({
    url: "writing/updateCover",
    method: "post",
    data,
  });
}

export interface DeleteStoryParams {
  story_id: number;
}
export function deleteStory(data: DeleteStoryParams): Promise<StoryEntity> {
  return $http.request({
    url: "writing/deleteStory",
    method: "post",
    data,
  });
}

export interface CreatePartParams {
  story_id: number;
  title?: string;
  content: string;
}
export function createPart(data: CreatePartParams): Promise<PartEntity> {
  return $http.request({
    url: "writing/createPart",
    method: "post",
    data,
  });
}

export interface GetPartParams {
  story_id: number;
  part_id: number;
}
export function getPart(params: GetPartParams): Promise<PartEntity & { story_id: number; story_title: string }> {
  return $http.request({
    url: "writing/getPart",
    method: "get",
    params,
  });
}

export interface EditPartParams {
  story_id: number;
  part_id: number;
  title: string;
  content: string;
}
export function editPart(data: EditPartParams) {
  return $http.request({
    url: "writing/editPart",
    method: "post",
    data,
  });
}

export interface SetPartPriceParams {
  story_id: number;
  part_id: number;
  price: number;
}
export function setPartPrice(data: SetPartPriceParams) {
  return $http.request({
    url: "writing/setPartPrice",
    method: "post",
    data,
  });
}

export interface PublishPartParams {
  story_id: number;
  part_id: number;
}
export function publishPart(data: PublishPartParams) {
  return $http.request({
    url: "writing/publishPart",
    method: "post",
    data,
  });
}

export interface PublishPartParams {
  story_id: number;
  part_id: number;
}
export function unpublishPart(data: PublishPartParams) {
  return $http.request({
    url: "writing/unpublishPart",
    method: "post",
    data,
  });
}

export interface DeletePartParams {
  story_id: number;
  part_id: number;
}
export function deletePart(data: DeletePartParams) {
  return $http.request({
    url: "writing/deletePart",
    method: "post",
    data,
  });
}
