import { toast } from "react-toastify";
import axios, { InternalAxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { isFormData } from "./utils";
import { Errors } from "@/config/enum";
import i18n from "./i18n";

declare module "axios" {
  interface AxiosRequestConfig {
    toast?: boolean;
    loginRequired?: boolean;
  }
}

const $http = axios.create({
  baseURL: "/api/",
  toast: true,
});

$http.interceptors.request.use((options: InternalAxiosRequestConfig) => {
  options.headers = options.headers || {};
  options.headers["x-custom-timezone"] = (new Date().getTimezoneOffset()) / 60;

  if (!isFormData(options.data)) {
    options.headers["content-type"] = "application/json; charset=UTF-8";
  }
  if (i18n.language) {
    options.headers["x-custom-lang"] = i18n.language;
  }

  return options;
});

$http.interceptors.response.use(
  function (response: AxiosResponse) {
    let message: string;
    if (!response.data) {
      message = "Response data is empty";
      response.config.toast && toast.error(message);
      return Promise.reject(message);
    }

    if (response.data?.code !== 0) {
      const message = response.data?.message || response.data?.error?.message;
      response.config.toast && toast.error(String(message));
      return Promise.reject(message);
    }

    return response.data?.data;
  },
  function (err: AxiosError<any>) {
    if (err?.response?.status === 401 && !window.location.pathname.startsWith("/login")) {
      window.location.href = "/login?redirect=" + encodeURIComponent(window.location.pathname);
      return Promise.reject(Errors.Unauthorized);
    }
    if (err?.response?.status === 404) {
      return Promise.reject(Errors.NotFound);
    }

    if (err?.response?.data?.message) {
      err.config?.toast && toast.error(String(err.response.data.message));
      return Promise.reject(err.response.data.message);
    }
    if (err.message && err.message.includes("timeout")) {
      err?.config?.toast && toast.error("Request timeout, please try again later");
      return Promise.reject("timeout");
    }
    
    err.config?.toast && toast.error("Request error: " + err.message || "Unknown error");
    return Promise.reject(err.message);
  }
);

export default $http;