import { getServerConfig } from "@/api/config";
import defaultImage from "@/assets/images/default.png";

const config = {
  defaultImage: defaultImage,
  siteName: "Bigcan",
  siteLangs: ["en-US"],
};

export default config;

export function initConfig() {
  return getServerConfig().then((srvCfg) => {
    Object.assign(config, srvCfg);
    return config;
  });
}
