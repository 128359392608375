import { ChangeEvent, KeyboardEvent } from "react";
import { useTranslation } from "react-i18next";
import { InputAdornment, OutlinedInput } from "@mui/material";
import { ReactComponent as SearchIcon } from "@/assets/icons/search2.svg";
import { ReactComponent as CloseIcon } from "@/assets/icons/close-bold.svg";
import { useState } from "react";

interface Props {
  search: string;
  onSearch: (search: string) => void;
}
export default function StoryListSearch(props: Props) {
  const { t } = useTranslation();
  const [value, setValue] = useState<string>(props.search);

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setValue(e.target!.value);
  }


	function handleKeyDown (e: KeyboardEvent) {
		if (e.key === "Enter" && value.trim()) {
			props.onSearch(value.trim());
		}
	}

  function handleClear() {
    setValue("")
    props.onSearch("");
  }

  return (
    <OutlinedInput
      className="w-full"
      value={value}
      onChange={handleChange}
			onKeyDown={handleKeyDown}
      size="small"
      placeholder={t("storyList.searchPlaceholder")!}
      sx={{
        borderRadius: "20px",
        paddingLeft: "24px",
      }}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
      endAdornment={
        props.search ? (
          <InputAdornment position="end">
            <CloseIcon className="cursor-pointer" onClick={handleClear} />
          </InputAdornment>
        ) : null
      }
    />
  );
}
