import { useTranslation } from "react-i18next";
import HotStoryList from "./HotStoryList";
import BlockTitle from "./BlockTitle";

export default function HotStory() {
  const items = [
    {
      id: 1,
      cover: "/example/1.png",
      title: "我可以召唤所有的神",
      category: {
        id: 10,
        name: "玄幻",
      },
      author: {
        id: 10,
        username: "Meng RuShen",
      },
      description:
        "那是 2169 年。世界末日终于到来了。全球变暖、丧尸、核战争，同时发生。只要一个临界点，世界就会崩溃。然后，企业界的霸主 Nova Corp 似乎说服了全球超级大国去做不可能的事情：让每个人都上网。他们做到了。每个人，无论贫富，都被带入了 VRMMORPG：World Online。一个一切皆有可能的奇幻世界。一个乌......",
    },
    {
      id: 2,
      cover: "/example/2.png",
      title: "游戏背后的老板",
      category: {
        id: 10,
        name: "玄幻",
      },
      author: {
        id: 10,
        username: "Meng RuShen",
      },
      description:
        "那是 2169 年。世界末日终于到来了。全球变暖、丧尸、核战争，同时发生。只要一个临界点，世界就会崩溃。然后，企业界的霸主 Nova Corp 似乎说服了全球超级大国去做不可能的事情：让每个人都上网。他们做到了。每个人，无论贫富，都被带入了 VRMMORPG：World Online。一个一切皆有可能的奇幻世界。一个乌......",
    },
    {
      id: 3,
      cover: "/example/3.png",
      title: "The Imbecile Lord Is Married",
      category: {
        id: 20,
        name: "玄幻",
      },
      author: {
        id: 10,
        username: "Meng Ru Shen Ji",
      },
      description:
        "那是 2169 年。世界末日终于到来了。全球变暖、丧尸、核战争，同时发生。只要一个临界点，世界就会崩溃。然后，企业界的霸主 Nova Corp 似乎说服了全球超级大国去做不可能的事情：让每个人都上网。他们做到了。每个人，无论贫富，都被带入了 VRMMORPG：World Online。一个一切皆有可能的奇幻世界。一个乌......",
    },
    {
      id: 4,
      cover: "/example/1.png",
      title: "我可以召唤所有的神",
      category: {
        id: 10,
        name: "玄幻",
      },
      author: {
        id: 10,
        username: "Meng RuShen",
      },
      description:
        "那是 2169 年。世界末日终于到来了。全球变暖、丧尸、核战争，同时发生。只要一个临界点，世界就会崩溃。然后，企业界的霸主 Nova Corp 似乎说服了全球超级大国去做不可能的事情：让每个人都上网。他们做到了。每个人，无论贫富，都被带入了 VRMMORPG：World Online。一个一切皆有可能的奇幻世界。一个乌......",
    },
    {
      id: 5,
      cover: "/example/2.png",
      title: "游戏背后的老板",
      category: {
        id: 10,
        name: "玄幻",
      },
      author: {
        id: 10,
        username: "Meng RuShen",
      },
      description:
        "那是 2169 年。世界末日终于到来了。全球变暖、丧尸、核战争，同时发生。只要一个临界点，世界就会崩溃。然后，企业界的霸主 Nova Corp 似乎说服了全球超级大国去做不可能的事情：让每个人都上网。他们做到了。每个人，无论贫富，都被带入了 VRMMORPG：World Online。一个一切皆有可能的奇幻世界。一个乌......",
    },
    {
      id: 6,
      cover: "/example/3.png",
      title: "The Imbecile Lord Is Married",
      category: {
        id: 20,
        name: "玄幻",
      },
      author: {
        id: 10,
        username: "Meng Ru Shen Ji",
      },
      description:
        "那是 2169 年。世界末日终于到来了。全球变暖、丧尸、核战争，同时发生。只要一个临界点，世界就会崩溃。然后，企业界的霸主 Nova Corp 似乎说服了全球超级大国去做不可能的事情：让每个人都上网。他们做到了。每个人，无论贫富，都被带入了 VRMMORPG：World Online。一个一切皆有可能的奇幻世界。一个乌......",
    },
    {
      id: 11,
      cover: "/example/1.png",
      title: "我可以召唤所有的神",
      category: {
        id: 10,
        name: "玄幻",
      },
      author: {
        id: 10,
        username: "Meng RuShen",
      },
      description:
        "那是 2169 年。世界末日终于到来了。全球变暖、丧尸、核战争，同时发生。只要一个临界点，世界就会崩溃。然后，企业界的霸主 Nova Corp 似乎说服了全球超级大国去做不可能的事情：让每个人都上网。他们做到了。每个人，无论贫富，都被带入了 VRMMORPG：World Online。一个一切皆有可能的奇幻世界。一个乌......",
    },
    {
      id: 12,
      cover: "/example/2.png",
      title: "游戏背后的老板",
      category: {
        id: 10,
        name: "玄幻",
      },
      author: {
        id: 10,
        username: "Meng RuShen",
      },
      description:
        "那是 2169 年。世界末日终于到来了。全球变暖、丧尸、核战争，同时发生。只要一个临界点，世界就会崩溃。然后，企业界的霸主 Nova Corp 似乎说服了全球超级大国去做不可能的事情：让每个人都上网。他们做到了。每个人，无论贫富，都被带入了 VRMMORPG：World Online。一个一切皆有可能的奇幻世界。一个乌......",
    },
    {
      id: 13,
      cover: "/example/3.png",
      title: "The Imbecile Lord Is Married",
      category: {
        id: 20,
        name: "玄幻",
      },
      author: {
        id: 10,
        username: "Meng Ru Shen Ji",
      },
      description:
        "那是 2169 年。世界末日终于到来了。全球变暖、丧尸、核战争，同时发生。只要一个临界点，世界就会崩溃。然后，企业界的霸主 Nova Corp 似乎说服了全球超级大国去做不可能的事情：让每个人都上网。他们做到了。每个人，无论贫富，都被带入了 VRMMORPG：World Online。一个一切皆有可能的奇幻世界。一个乌......",
    },
    {
      id: 14,
      cover: "/example/1.png",
      title: "我可以召唤所有的神",
      category: {
        id: 10,
        name: "玄幻",
      },
      author: {
        id: 10,
        username: "Meng RuShen",
      },
      description:
        "那是 2169 年。世界末日终于到来了。全球变暖、丧尸、核战争，同时发生。只要一个临界点，世界就会崩溃。然后，企业界的霸主 Nova Corp 似乎说服了全球超级大国去做不可能的事情：让每个人都上网。他们做到了。每个人，无论贫富，都被带入了 VRMMORPG：World Online。一个一切皆有可能的奇幻世界。一个乌......",
    },
  ];
  const { t } = useTranslation();

	const handleMore = () => {
		// todo
	}

  return (
    <div className="hotStory">
      <BlockTitle divider actionText={t("common.more")!} onActionClick={handleMore}>{t("home.hotStory")}</BlockTitle>
			<div className="flex mt-6">
				<HotStoryList className="flex-1 mr-10" key="powerRanking" title={t("home.powerRanking")} items={items} />
				<HotStoryList className="flex-1 mr-10" key="collectionRanking" title={t("home.collectionRanking")} items={items} />
				<HotStoryList className="flex-1" key="activeRanking" title={t("home.activeRanking")} items={items} />
			</div>
    </div>
  );
}
