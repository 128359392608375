import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Badge, BadgeProps, Button, Divider, Pagination, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { NotifyEntity } from "@/entity/notify";
import { serializeQueryParams } from "@/utils/utils";
import * as notifyApi from "@/api/notify";
import PageTitle from "@/components/PageTitle";
import Spinner from "@/components/Spinner";
import Empty from "@/components/Empty";
import { formatTime } from "@/utils/format";
import { NotifyStatus, NotifyType } from "@/config/enum";
import { ReactComponent as MarkAllReadIcon } from "@/assets/icons/all-read.svg";
import { ReactComponent as BellIcon } from "@/assets/icons/bell.svg";

const NotifyBadge = styled(Badge)<BadgeProps>(() => ({
  "verticalAlign": "top",
  "& .MuiBadge-badge": {
    position: "static",
    transform: "none",
    marginLeft: "3px",
  },
  "& .MuiBadge-invisible": {
    display: "none",
  },
}));

export default function NotifyList() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const page = +searchParams.get("page")! || 1;
  const page_size = 20;
  const [notifyCount, setNotifyCount] = useState(0);
  const [active, setActive] = useState("unread");
  const [state, setState] = useState({
    total: 0,
    items: [] as NotifyEntity[],
  });

  const load = async () => {
    try {
      setLoading(true);
      const data = await notifyApi.list({
        page,
        page_size,
        is_unread: active === "unread",
      });
      setState(data);
    } finally {
      setLoading(false);
    }
  };

  const loadNotifyCount = async () => {
    const {count} = await notifyApi.getUnreadCount();
    setNotifyCount(count);
  };

  const onPageChange = (page: number) => {
    navigate(
      "?" +
        serializeQueryParams({
          page,
        })
    );
  };

  const renderContent = (row: NotifyEntity) => {
    switch (row.type) {
      case NotifyType.CommentAutor:
        return (
          <>
            <Link
              to={
                (row.part_id ? `/part/${row.story_id}/${row.part_id}` : `/story/${row.story_id}`) +
                `?notify_type=${row.type}&notify_id=${row.id}`
              }
              className="font-color-primary mr-[2px]"
            >
              {row.story?.title}
            </Link>
            {t("notify.commentAuthorTpl", { username: row.send_user?.username || "-" })}
          </>
        );
      case NotifyType.CommentReply:
        return <>{t("notify.commentReplyTpl", { username: row.send_user?.username || "-" })}</>;
      default:
        return null;
    }
  };

  const handleDetail = (row: NotifyEntity) => {
    notifyApi.read({ notify_id: row.id });
    navigate(
      (row.part_id ? `/part/${row.story_id}/${row.part_id}` : `/story/${row.story_id}`) +
        `?notify_type=${row.type}&notify_id=${row.id}`
    );
  };

  const handleAllRead = () => {
    notifyApi.readAll();
    loadNotifyCount();
    load();
  };

  useEffect(() => {
    loadNotifyCount();
  }, [])

  useEffect(() => {
    load();
  }, [page, active]);

  return (
    <div className="notify relative">
      <PageTitle name="notify" />
      <div className="flex justify-between">
        <ToggleButtonGroup
          color="primary"
          value={active}
          size="small"
          exclusive
          onChange={(_, v: string) => setActive(v)}
        >
          <ToggleButton value="unread">
            <NotifyBadge badgeContent={notifyCount} color="error">
              <span>{t("notify.unreadList")}</span>
            </NotifyBadge>
          </ToggleButton>
          <ToggleButton value="all">{t("notify.allList")}</ToggleButton>
        </ToggleButtonGroup>
        {notifyCount > 0 && (
          <Button
            variant="contained"
            color="primary"
            title={t("notify.markAllRead")!}
            onClick={handleAllRead}
            sx={{
              width: "32px",
              minWidth: "32px",
              height: "32px",
              padding: 0,
            }}
          >
            <MarkAllReadIcon />
          </Button>
        )}
      </div>
      <Divider
        sx={{
          marginTop: "16px",
          backgroundColor: '#000'
        }}
      />

      {loading || !state.items.length ? (
        <div className="pt-10">{loading ? <Spinner loading={loading} /> : <Empty icon={<BellIcon />} />}</div>
      ) : (
        <ul>
          {state.items.map((row) => (
            <li key={row.id}>
              <div className="flex py-4 border-b border-color-highlight">
                <div className="flex-1">
                  <div className="content text-base">{renderContent(row)}</div>
                  <div className="mt-2 font-color-grey">{formatTime(row.create_time)}</div>
                </div>
                <Button variant="text" onClick={() => handleDetail(row)} sx={{
                  color: row.status === NotifyStatus.Read ? "var(--font-color-grey)" : ""
                }}>
                  {t("notify.viewDetail")}
                </Button>
              </div>
            </li>
          ))}
        </ul>
      )}
      <div className="clearfix mt-3">
        <Pagination
          className="float-right"
          count={Math.ceil(state.total / page_size)}
          page={page}
          variant="outlined"
          shape="rounded"
          color="primary"
          boundaryCount={2}
          onChange={(_, page) => onPageChange(page)}
        />
      </div>
    </div>
  );
}
