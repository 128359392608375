import { PurchaseEntity } from "@/entity/purchase";
import { RechargeEntity } from "@/entity/recharge";
import { SubscribeStoryEntity } from "@/entity/subscribe-story";
import $http from "@/utils/http";

export function getBalance(): Promise<{ points: number }> {
  return $http.request({
    url: "fund/balance",
    method: "get",
  });
}

export interface RecordListParams extends ApiListParams {
  month?: string;
}

export function getRechargeRecords(params: RecordListParams): Promise<ApiListResult<RechargeEntity>> {
	return $http.request({
		url: "fund/recharge/record",
		method: "get",
		params,
	});
}

export function getPurchaseRecords(params: RecordListParams): Promise<ApiListResult<PurchaseEntity>> {
	return $http.request({
		url: "fund/purchase/record",
		method: "get",
		params,
	});
}

export function getSubscribeRecords(params: ApiListParams): Promise<ApiListResult<SubscribeStoryEntity>> {
  return $http.request({
    url: "fund/subscribe/record",
    method: "get",
    params,
  });
}

export interface BuyPartParams {
  story_id: number;
  part_id: number;
}
export interface BuyPartResult {
  success: boolean;
  reason?: "not_enough_fund";
}
export function buyPart(params: BuyPartParams): Promise<BuyPartResult> {
  return $http.request({
    url: "fund/buyPart",
    method: "post",
    data: params,
  });
}

export interface SubscribeStoryParams {
  story_id: number;
}
export function subscribeStory(params: SubscribeStoryParams) {
  return $http.request({
    url: "fund/subscribeStory",
    method: "post",
    data: params,
  });
}

export interface UnsubscribeStoryParams {
  story_id: number;
}
export function UnsubscribeStory(params: UnsubscribeStoryParams) {
  return $http.request({
    url: "fund/unsubscribeStory",
    method: "post",
    data: params,
  });
}