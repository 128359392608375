import { Outlet } from "react-router-dom";
import styles from "./LoginLayout.module.css";

export default function LoginLayout() {
  return (
    <div className={styles.loginContainer}>
			<div className={styles.wrap}>
				<div className={styles.content }>
					<Outlet />
				</div>
			</div>
    </div>
  );
}
