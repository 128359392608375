import { LibraryEntity } from "@/entity/library";
import $http from "@/utils/http";

export interface LibraryListParams extends ApiListParams {}
export function list(params?: LibraryListParams): Promise<ApiListResult<LibraryEntity>> {
  return $http.request({
    url: "library/list",
    method: "get",
    params,
  });
}

export type AddLibraryParams = Pick<LibraryEntity, "story_id">;
export function add(params: AddLibraryParams): Promise<LibraryEntity> {
  return $http.request({
    url: "library/add",
    method: "post",
    data: params,
  });
}

export type DeleteLibraryParams = Pick<LibraryEntity, "story_id">;
export function del(params: DeleteLibraryParams): Promise<boolean> {
  return $http.request({
    url: "library/delete",
    method: "post",
    data: params,
  });
}
