/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Box, Grid, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useTranslation } from "react-i18next";
import StoryDetails from "./components/StoryDetails";
import StoryParts from "./components/StoryParts";
import * as writingApi from "@/api/writing";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Spinner from "@/components/Spinner";
import Navbar from "./components/StoryEditNav";
import StoryCover from "./components/StoryCover";

interface Props {}

export default function StoryEdit(props: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const formRef = useRef<HTMLFormElement>(null);
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const [value, setValue] = useState(searchParams.get("active") || "info");
  const [story, setStory] = useState<writingApi.MyStoryDetailResult | null>(null);

  const load = useCallback(async () => {
    setLoading(true);
    try {
      const data = await writingApi.myStoryDetail({ story_id: +params.story_id! });
      setStory(data);
    } finally {
      setLoading(false);
    }
  }, [params.story_id])

  useEffect(() => {
    if (params.story_id) {
      load();
    }
  }, [params.story_id]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const onOk = () => {
    formRef.current?.requestSubmit();
  };

  const onCancel = () => {
    navigate("/writing");
  };

  return (
    <>
      <Spinner loading={loading} height="200px" />
      {story && (
        <>
          <Navbar onOk={onOk} onCancel={onCancel} okText={t("common.save")} title={story.title} isEdit />
          <div className="content-wrap mt-6">
            <Grid container spacing={2} justifyContent="space-around">
              <Grid item xs={3}>
                <StoryCover isEdit story_id={story.id} cover={story.cover} />
              </Grid>
              <Grid item xs={9}>
                <div
                  css={css`
                    border: 1px solid var(--border-color-default);
                    border-radius: 5px;
                  `}
                >
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList onChange={handleTabChange}>
                        <Tab label={t("writing.storyDetails")} value="info" sx={{ marginRight: "3rem" }}/>
                        <Tab label={t("writing.catalog")} value="parts"  />
                      </TabList>
                    </Box>
                    <TabPanel value="info" style={{ padding: "1.5rem 2rem" }}>
                      <StoryDetails ref={formRef} isEdit story={story} />
                    </TabPanel>
                    <TabPanel value="parts" style={{ padding: 0 }}>
                      <StoryParts story={story!} onReload={load} />
                    </TabPanel>
                  </TabContext>
                </div>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </>
  );
}
