import { NotifyEntity } from "@/entity/notify";
import $http from "@/utils/http";

export interface NotifyListParams extends ApiListParams {
  is_unread?: boolean;
}
export function list(params?: NotifyListParams): Promise<ApiListResult<NotifyEntity>> {
  return $http.request({
    url: "notify/list",
    method: "get",
    params,
  });
}

export function getUnreadCount(): Promise<{ count: number }> {
  return $http.request({
    url: "notify/unreadCount",
    method: "get",
  });
}

export function read(data: { notify_id: number }): Promise<{ success: boolean }> {
  return $http.request({
    url: "notify/read",
    method: "post",
    data,
  });
}

export function readAll(): Promise<{ success: boolean }> {
  return $http.request({
    url: "notify/readAll",
    method: "post",
  });
}
