import React from "react";

export interface IOptions {
	open: boolean;
	title: string;
	content?: string | React.ReactElement | null;
	cancelText?: string;
	confirmText?: string;
	onCancel: () => void;
	onConfirm: () => void;
}

export const ConfirmContext = React.createContext<{
  options?: null | IOptions;
  setOptions?: (options: null | IOptions) => void;
}>({});

