import { ReactComponent as LoadingIcon } from "@/assets/icons/loading.svg";
import styles from "./Spinner.module.css";
import { useTranslation } from "react-i18next";
import { cloneElement } from "react";

interface Props {
  loading: boolean;
  tip?: boolean | string;
  children?: React.ReactElement;
  horizontal?: boolean;
  height?: string;
  className?: string;
}

export default function Spinner({ loading, children, horizontal, height, className, tip = true }: Props) {
  const { t } = useTranslation();
  const tipText = typeof tip === "string" ? tip : tip ? t("common.loading") : "";
  return (
    <div className={styles.spinner__wrap + " " + (className ? className : "")}>
      {loading && (
        <div className={styles.spinner + " " + (!children ? styles.spinner__static : "")}>
          <div
            className={styles.spinner__inner + " " + (horizontal ? styles.spinner__horizontal : "")}
            style={{ minHeight: height ? height : "auto" }}
          >
            <LoadingIcon className={styles.spinner__icon} />
            {!!tipText && <div className={styles.spinner__tip}>{tipText}</div>}
          </div>
        </div>
      )}
      {!!children &&
        cloneElement(children, {
          className:
            (children.props.className || "") + " " + styles.spinner__content + " " + (loading ? styles.spinner__blur : ""),
        })}
    </div>
  );
}
