import $http from "@/utils/http";
import { PreferencesEntity } from "@/entity/preferences";

export function get(): Promise<PreferencesEntity> {
  return $http.request({
    url: "preferences/get",
    method: "get",
  });
}

export function set(data: PreferencesEntity) {
  return $http.request({
    url: "preferences/set",
    method: "post",
    data,
  });
}