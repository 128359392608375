/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Typography, Button, Divider, TextField } from "@mui/material";
import { ReadingListEntity, ReadingListStoryEntity } from "@/entity/reading-list";
import * as readingListApi from "@/api/reading-list";
import CompleteChip from "@/components/CompleteChip";
import SensitiveChip from "@/components/SensitiveChip";
import { ReactComponent as BackIcon } from "@/assets/icons/back.svg";
import { ReactComponent as ReadCountIcon } from "@/assets/icons/read-count-16.svg";
import { ReactComponent as PartsIcon } from "@/assets/icons/parts-12.svg";
import emptyPng from "@/assets/images/readinglist-empty.png";
import { formatCover } from "@/utils/format";
import { useConfirm } from "@/hooks";

export default function ReadingList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { confirm } = useConfirm();
  const query = useParams();
  const id = +query.id!;

  if (!id) {
    navigate("/404");
  }

  const [isEdit, setIsEdit] = useState(false);
  const [name, setName] = useState("");
  const [needValidate, setNeedValidate] = useState(false);
  const [info, setInfo] = useState<ReadingListEntity>();
  const [storyItems, setStoryItems] = useState<ReadingListStoryEntity[]>([]);

  const load = async () => {
    const [storyData, infoData] = await Promise.all([
      readingListApi.storyList({ reading_list_id: id }),
      readingListApi.info({ id }),
    ]);
    setStoryItems(storyData.items);
    setInfo(infoData);
    setName(infoData.name);
  };

  useEffect(() => {
    load();
  }, [id]);

  const handleUpdate = async () => {
    if (validate(true) != null) {
      setNeedValidate(true);
    }

    const trimedName = name.trim();

    await readingListApi.updateName({
      id,
      name: trimedName,
    });
    setInfo({
      ...info!,
      name: trimedName,
    });
    setIsEdit(false);
  };

  const handleDelete = async () => {
    if (
      await confirm({
        title: t("readingList.deleteReadingList"),
        content: t("readingList.deleteReadingListTip"),
      })
    ) {
      await readingListApi.del({
        id,
      });
      navigate("/library?active=readingList");
    }
  };

  const handleRemoveStory = async (story_id: number) => {
    if (
      await confirm({
        title: t("readingList.removeReadingListStory"),
        content: t("readingList.removeReadingListStoryTip"),
      })
    ) {
      await readingListApi.removeStory({
        reading_list_id: id,
        story_id,
      });
      setStoryItems(storyItems.filter(item => item.story_id !== story_id));
    }
  };

  const validate = (force = false) => {
    if (!needValidate && !force) {
      return null;
    }

    if (!name) {
      return {
        error: true,
        helperText: t("readingList.nameRequired"),
      };
    }

    if (name.trim().length > 40) {
      return {
        error: true,
        helperText: t("readingList.nameMaxLength"),
      };
    }

    return null;
  };

  return (
    <div className="content-wrap pt-4">
      <div className="back-header mb-8 border-b border-color-default flex items-center">
        <Link to="/readingList" className="block h-[60px] leading-[60px]">
          <BackIcon className="inline-block mr-4 align-top mt-[22.5px]" />
          {t("readingList.readingList")}
        </Link>
      </div>
      <div className="flex items-start">
        <div className="left pr-9">
          <div
            className="relative cover"
            css={css`
              width: 279px;
              height: 372px;
              &:before,
              &:after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: -1;
                width: 100%;
                height: 100%;
                transform: scale(0.9) translate(-48%, -50%);
                transform-origin: top right;
                background: #e6eded;
                border: 0.5px solid #b9bec1;
              }
              &:after {
                z-index: -2;
                transform: scale(0.8) translate(-47%, -50%);
                background: #ede8d8;
                border: 0.5px solid #b9bec1;
              }
            `}
          >
            <img
              {...formatCover(storyItems[0]?.story?.cover)}
              alt=""
              css={css`
                width: 100%;
                height: 100%;
                border: 0.5px solid #b9bec1;
              `}
            />
          </div>
          <div className="name mt-4 mb-6">
            {isEdit ? (
              <TextField
                value={name}
                onChange={e => setName(e.target.value)}
                variant="outlined"
                autoFocus
                size="small"
                fullWidth
                {...validate()}
              />
            ) : (
              <>
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: "20px",
                    lineHeight: "1.45",
                  }}
                >
                  {info?.name}
                </Typography>
                <Typography
                  className="font-color-grey"
                  variant="body1"
                  sx={{
                    marginTop: "8px",
                  }}
                >
                  {t("readingList.storiesCount", { count: info?.story_ids?.length })}
                </Typography>
              </>
            )}
          </div>
          <div>
            {isEdit ? (
              <>
                <Button variant="contained" color="primary" onClick={() => handleUpdate()}>
                  {t("common.done")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  css={css`
                    margin-left: 16px;
                  `}
                  onClick={() => handleDelete()}
                >
                  {t("readingList.deleteReadingListShort")}
                </Button>
              </>
            ) : (
              <Button variant="contained" color="primary" onClick={() => setIsEdit(true)}>
                {t("common.edit")}
              </Button>
            )}
          </div>
          <div className="shares mt-2"></div>
        </div>
        {!storyItems.length ? (
          <div className="empty flex-1 mt-16">
            <img src={emptyPng} alt="" className="mx-auto" />
            <Typography variant="body1" color="initial" className="text-center">
              {t("readingList.emptyReadingList")}
            </Typography>
          </div>
        ) : (
          <div className="right flex-1 min-w-0 ml-10 border border-color-default">
            {storyItems.map(({ id, story }, index) => (
              <Fragment key={id}>
                {index > 0 && <Divider />}
                <div className="story-box cursor-pointer flex p-4" key={story.id}>
                  <Link to={"/story/" + story.id} className="cover block">
                    <img
                      {...formatCover(story.cover, "small")}
                      alt={story.title}
                      css={css`
                        width: 96px;
                        height: 128px;
                      `}
                    />
                  </Link>
                  <div className="flex-1 min-w-0 ml-3">
                    <div className="flex">
                      <div className="flex-1">
                        <Link to={"/story/" + story.id} className="block title truncate text-base font-medium">
                          {story.title}
                        </Link>
                        <div
                          className="stat"
                          css={css`
                            font-size: 12px;
                            line-height: 18px;
                            margin-top: 2px;
                            margin-bottom: 2px;
                          `}
                        >
                          <span className="inline-block font-color-grey mr-6">
                            <ReadCountIcon
                              className="inline-block"
                              css={css`
                                vertical-align: top;
                                margin-right: 3px;
                                margin-top: 2px;
                              `}
                            />
                            <span>{story.read_count}</span>
                          </span>
                          <span className="inline-block font-color-grey mr-6">
                            <PartsIcon
                              className="inline-block"
                              css={css`
                                vertical-align: top;
                                margin-right: 3px;
                                margin-top: 2px;
                              `}
                            />
                            <span>
                              {story.parts_count}
                              {t("common.chapters")}
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="flex items-start ml-2">
                        <Button
                          size="small"
                          variant="text"
                          color="error"
                          sx={{ fontSize: "13px" }}
                          onClick={() => handleRemoveStory(story.id)}
                        >
                          {t("common.remove")}
                        </Button>
                      </div>
                    </div>
                    <div className="descriptiom text-[13px] leading-[19px] font-color-grey h-[57px] line-clamp-3">
                      {story.description}
                    </div>
                    <div
                      css={css`
                        height: 18px;
                        line-height: 18px;
                        margin-top: 4px;
                      `}
                    >
                      <CompleteChip is_completed={story.is_completed} />
                      {story.human_rating && <SensitiveChip />}
                    </div>
                  </div>
                </div>
              </Fragment>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
