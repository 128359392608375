import { ReactElement, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "@/context";
import { Typography, useTheme } from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import { formatTime } from "@/utils/format";
import { CommentEntity } from "@/entity/comment";
import { likeComment, undoLikeComment } from "@/api/comment";
import UserAvatar from "@/components/UserAvatar";
import { ReactComponent as HeartIcon } from "@/assets/icons/heart.svg";
import { ReactComponent as HeartFilledIcon } from "@/assets/icons/heart-filled.svg";
import { ReactComponent as CaretIcon } from "@/assets/icons/caret-right.svg";
import styles from "./CommentCard.module.css";

interface Props {
  children?: ReactElement;
  isChild?: boolean;
  comment: CommentEntity & { replies?: any[] };
  ReplyListComponent?: ReactElement;
  handleReply?: (comment: CommentEntity) => void;
  onDelete?: (comment: CommentEntity) => void;
}
export default function CommentCard({ comment, handleReply, isChild, children, onDelete }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isLiked, setIsLiked] = useState(comment.is_liked);
  const { user } = useContext(AuthContext);

  const toggleLike = async () => {
    if (!comment.is_liked) {
      comment.is_liked = true;
      comment.like_count += 1;
      setIsLiked(comment.is_liked);
      await likeComment({
        story_id: comment.story_id,
        part_id: comment.part_id,
        comment_id: comment.id,
      });
    } else {
      comment.is_liked = false;
      comment.like_count -= 1;
      setIsLiked(comment.is_liked);
      await undoLikeComment({
        story_id: comment.story_id,
        part_id: comment.part_id,
        comment_id: comment.id,
      });
    }
  };

  const renderContent = (content: string) => {
    if (content.startsWith("@")) {
      const user = content.match(/^@[^\s]+/)?.[0];
      const text = content.substring(user!.length + 1);

      return (
        <>
          <span className={styles.commentReplyUser}>{user}</span>
          {text}
        </>
      );
    }
    return content;
  };

  return (
    <div className={styles.commentCard + (isChild ? " " + styles.commentChild : "")}>
      <UserAvatar className={styles.commentAvatar} user={comment.user!} size="s" />
      <div className={styles.commentContent}>
        <div className="flex justify-between">
          <div className="flex items-center flex-1">
            <Typography variant="h5" component="h3" className={styles.commentUser}>
              {comment.user && comment.user.username}
            </Typography>
            {isChild && !!comment.reply_user && (
              <>
                <CaretIcon className="ml-1" />
                <span className="text-xs font-color-grey">{comment.reply_user.username}</span>
              </>
            )}
          </div>
          <div className={styles.commentActions}>
            {user?.id === comment.user_id && onDelete && (
              <button className={styles.deleteButton} onClick={() => onDelete(comment)}>
                <DeleteIcon fontSize="small" />
              </button>
            )}
          </div>
        </div>
        <div className={styles.commentText}>{renderContent(comment.content)}</div>
        <div className="flex justify-between items-center">
          <div className={styles.commentMeta}>
            <span className={styles.commentTime}>{formatTime(comment.create_time, true)}</span>
            {!!handleReply && (
              <button className={styles.replyButton} onClick={() => handleReply(comment)}>
                {t("reading.reply")}
              </button>
            )}
          </div>
          <span className="commentLike flex">
            {isLiked ? (
              <HeartFilledIcon className="cursor-pointer" color={theme.palette.secondary.dark} onClick={toggleLike} />
            ) : (
              <HeartIcon className="font-color-grey cursor-pointer" onClick={toggleLike} />
            )}
            {!!comment.like_count && <span className="text-xs font-color-grey ml-[6px]">{comment.like_count}</span>}
          </span>
        </div>
        {children}
      </div>
    </div>
  );
}
