import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Button, Chip, Divider, Tab, TabProps, Typography, TypographyProps } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Errors } from "@/config/enum";
import { formatCover } from "@/utils/format";
import * as readingApi from "@/api/reading";
import Spinner from "@/components/Spinner";
import CompleteChip from "@/components/CompleteChip";
import SensitiveChip from "@/components/SensitiveChip";
import PartMenu from "./components/PartMenu";
import CommentFeaturedList from "./components/CommentFeatured";
import AddToLibraryButton from "./components/AddToLibraryButton";
import PageTitle from "@/components/PageTitle";
import MaybeLike from "./components/MaybeLike";
import Page404 from "../404";
import { ReactComponent as ReadCountIcon } from "@/assets/icons/stat-reads.svg";
import { ReactComponent as PartsIcon } from "@/assets/icons/stat-parts.svg";
import styles from "./ReadingStory.module.css";


const BlockTitle = styled((props: TypographyProps) => <Typography variant="h3" {...props} />)(() => ({
  fontSize: "24px",
  lineHeight: "35px",
  marginBottom: "32px",
  fontWeight: 500,
}));

const CustomTab = styled((props: TabProps) => <Tab {...props} />)(({ theme }) => ({
  fontSize: "30px",
  lineHeight: "44px",
  fontWeight: 500,
  marginLeft: 0,
  marginRight: "64px",
}));

interface ReadingStoryProps {}

export default function ReadingStory(props: ReadingStoryProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [story, setStory] = useState<null | Awaited<ReturnType<typeof readingApi.getStory>>>(null);
  const story_id = +params.story_id!;

  const [tab, setTab] = useState("about");
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        const data = await readingApi.getStory({
          story_id,
          with_history_part: true,
          with_first_part: true,
          with_parts_count: true,
          with_in_library: true,
        });
        setStory(data);
      } catch (error: any) {
        if (error === Errors.NotFound) {
          setNotFound(true);
        }
      } finally {
        setLoading(false);
      }
    };
    load();
  }, [story_id]);

  if (notFound) {
    return <Page404 />;
  }

  if (!story) {
    return <Spinner loading={loading} height={"100vh"}></Spinner>;
  }

  const gotoReading = () => {
    const part = story.history_part || story.first_part;
    if (!part) {
      return;
    }

    navigate(`/part/${story.id}/${part.id}`);
  };

  return (
    <>
      <PageTitle title={story?.title} />
      <Spinner loading={loading}>
        <div className={styles.storyContainer}>
          <div className={styles.storyHeader}>
            <div className={styles.storyHeaderInner + " content-wrap"}>
              <div className={styles.storyCover}>
                <div className={styles.medal}>
                  <span className={styles.medalNum}>
                    TOP<span className="ml-1">13</span>
                  </span>
                  <span className={styles.medalText}>/{t("reading.hotList")}</span>
                </div>
                <img {...formatCover(story.cover)} alt="" className="rounded-md" />
              </div>
              <div className={styles.storyInfo}>
                <div className={styles.storyInfoTop}>
                  <Typography variant="h5" component="h2" className={styles.storyTitle}>
                    {story.title}
                  </Typography>
                  <div className={styles.storyChips}>
                    <CompleteChip is_completed={story.is_completed} />
                    {story.human_rating && <SensitiveChip />}
                    <span className="ml-2">{story.author!.username}</span>
                  </div>
                  <div className={styles.storyStat}>
                    <div className={styles.readCount}>
                      <div className={styles.storyStatLabel}>
                        <ReadCountIcon />
                        {t("reading.readCount")}
                        <span className={styles.storyStatValue}>{story.read_count}</span>
                      </div>
                    </div>
                    <div className={styles.partsCount}>
                      <div className={styles.storyStatLabel}>
                        <PartsIcon />
                        {t("reading.chapterCount")}
                        <span className={styles.storyStatValue}>
                          {story.parts_count || story.parts?.length || 0}
                          {t("common.chapters")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.storyInfoBottom}>
                  <div className={styles.storyActions}>
                    <Button className={styles.startReading} color="primary" variant="contained" onClick={gotoReading}>
                      {t("reading.startReading")}
                    </Button>
                    <AddToLibraryButton story={story} className={styles.addTo} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.storyBody + " content-wrap"}>
            <TabContext value={tab}>
              <TabList onChange={handleTabChange} sx={{ marginBottom: "36px", paddingLeft: 0 }}>
                <CustomTab label={t("reading.about")} value="about" />
                <CustomTab label={t("reading.catalog")} value="parts" />
              </TabList>
              <TabPanel value="about" sx={{ padding: 0 }}>
                <BlockTitle
                  sx={{
                    marginBottom: "40px",
                  }}
                >
                  {t("reading.description")}
                </BlockTitle>
                <div className={styles.storyDesc}>
                  <Typography variant="body1" sx={{ fontSize: "16px", lineHeight: 2 }}>
                    {story.description}
                  </Typography>
                </div>
                <BlockTitle
                  sx={{
                    marginTop: "60px",
                  }}
                >
                  {t("reading.tags")}
                </BlockTitle>
                <div className={styles.storyTags}>
                  {(story.tags || []).map((tag) => (
                    <Link key={tag.id} to={`/tag/${tag.id}`}>
                      <Chip label={"#" + tag.name} color="primary" />
                    </Link>
                  ))}
                </div>
                <div style={{ marginTop: "48px" }}>
                  <CommentFeaturedList story_id={story_id} part_id={0} />
                </div>
                <BlockTitle
                  style={{
                    marginTop: "60px",
                    marginBottom: "16px",
                  }}
                >
                  {t("reading.maybeLike")}
                </BlockTitle>
                <Divider />
                <MaybeLike />
              </TabPanel>
              <TabPanel value="parts" sx={{ padding: 0 }}>
                {story && <PartMenu story_id={story.id} />}
              </TabPanel>
            </TabContext>
          </div>
        </div>
      </Spinner>
    </>
  );
}
