import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import { LoadingButton } from "@mui/lab";
import { StoryEntity } from "@/entity/story";
import * as libraryApi from "@/api/library";
import { useNavigateLogin } from "@/hooks";
import { AuthContext } from "@/context";
import { ReactComponent as TickIcon } from "@/assets/icons/tick.svg";

interface Props {
  story?: Pick<StoryEntity, "id" | "in_library">;
  className?: string;
}
export default function AddToLibrary({ story, className }: Props) {
  const { t } = useTranslation();
  const { isLogin } = useContext(AuthContext);
  const { navigateLogin } = useNavigateLogin();
  const [loading, setLoading] = useState(false);
  const [inLibrary, setInLibrary] = useState(false);

  const onClick = async () => {
    if (!isLogin) {
      navigateLogin();
    }

    if (!story) {
      return;
    }

    try {
      setLoading(true);
      if (inLibrary) {
        await libraryApi.del({ story_id: story.id });
        setInLibrary(false);
      } else {
        await libraryApi.add({ story_id: story.id });
        setInLibrary(true);
      }
      toast.success(t("common.success"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (story && "in_library" in story) {
      setInLibrary(story.in_library!);
    }
  }, [story, story?.in_library]);

  return (
    <LoadingButton
      color="primary"
      variant="contained"
      className={className}
      startIcon={inLibrary ? <TickIcon /> : <AddIcon />}
      onClick={onClick}
      loading={loading}
    >
      {inLibrary ? t("reading.inLibrary") : t("reading.addLibrary")}
    </LoadingButton>
  );
}
