import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as EmptyIcon } from "@/assets/icons/empty.svg";

export interface Props {
  description?: ReactNode | string;
  icon?: ReactNode;
}
export default function Empty(props: Props) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center">
      { props.icon ? props.icon : (<EmptyIcon className="mb-2" />)}
      {!!props.description ? <div>{props.description}</div> : <p>{t("component.empty.description")}</p>}
    </div>
  );
}
