import { NavLink } from "react-router-dom";
import styles from "./Nav.module.css";

interface PageNavItem {
  id: number;
  title: string;
  key: string;
  path: string;
}

interface Props {
  items: PageNavItem[];
}

export default function Nav(props: Props) {
  return (
    <ul className={styles.list}>
      {props.items.map((item) => (
        <li key={item.id}>
          <NavLink
            className={({ isActive }) => (isActive ? "active" : "") + " " + styles.listItem}
            to={item.path}
          >
            {item.title}
          </NavLink>
        </li>
      ))}
    </ul>
  );
}
