import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

export function useNavigateLogin() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("redirect");
  const location = useLocation();

  return {
    loginPath: location.state?.from?.startsWith("/login")
      ? "/"
      : "/login?redirect=" + encodeURIComponent(redirect || pathname),
    signupPath: "/signup?redirect=" + encodeURIComponent(redirect || pathname),
    backPath: redirect,
    navigateBack: () => {
      if (redirect) {
        navigate(redirect, { replace: true, state: { from: pathname } });
      } else {
        navigate("/", { replace: true, state: { from: pathname } });
      }
      return null;
    },
    navigateLogin: () => {
      if (pathname === "/login") {
        return null;
      }

      if (location.state?.from?.startsWith("/login")) {
        return navigate("/", { replace: true });
      }

      navigate("/login?redirect=" + encodeURIComponent(redirect || pathname), { replace: true });
      return null;
    },
    navigateSignup: () => {
      if (pathname === "/signup") {
        return null;
      }

      navigate("/signup?redirect=" + encodeURIComponent(redirect || pathname), { replace: true });
      return null;
    },
  };
}
