import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckboxFilter } from "./StoryListFilter";
import * as tagApi from "@/api/tag";
import { BoxTitle } from "./StoryListHead";

interface Props {
  value?: number[];
  onChange: (value?: number[]) => void;
}
export default function StoryListTags(props: Props) {
  const { t } = useTranslation();
  const [options, setOptions] = useState<Array<{ value: number; label: string }>>([]);

  useEffect(() => {
    const load = async () => {
      const { items } = await tagApi.tagList();
      setOptions(items.map((item) => ({ value: item.id, label: "#" + item.name })));
    };

    load();
  }, []);

  return (
    <>
      <BoxTitle>{t("storyList.tags")!}</BoxTitle>
      <CheckboxFilter addAll value={props.value} onChange={props.onChange} options={options} />
    </>
  );
}
