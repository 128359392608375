import React, {useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, Paper, Grid, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import StoryDetails from "./components/StoryDetails";
import Navbar from "./components/StoryEditNav";
import StoryCover from "./components/StoryCover";

interface Props {}

export default function StoryNew(props: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const [value, setValue] = useState("details");
  const [cover, setCover] = useState<string | undefined>();

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const onOk = () => {
    formRef.current?.requestSubmit();
  }

  const onCancel = () => {
    navigate("/writing")
  }

  return (
    <>
      <Navbar onOk={onOk} onCancel={onCancel} okText={t('common.next')} title={t("writing.defaultStoryTitle")} />
      <div className="content-wrap mt-6">
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={3}>
            <StoryCover onCoverChange={setCover} />
          </Grid>
          <Grid item xs={8}>
            <Paper elevation={3}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={handleTabChange}>
                    <Tab label={t("writing.storyDetails")} value="details" />
                  </TabList>
                </Box>
                <TabPanel value="details">
                  <StoryDetails ref={formRef} cover={cover} />
                </TabPanel>
              </TabContext>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
