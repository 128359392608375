import PageTitle from "@/components/PageTitle";
import ThisWeekRecommend from "./components/ThisWeekRecommend";
import EditorRecommend from "./components/EditorRecommend";
import EveryWeekRecommend from "./components/EveryWeekRecommend";
import HotStory from "./components/HotStory";
import HotTags from "./components/HotTags";
import NewArrivals from "./components/NewArrivals";
import EditorPicks from "./components/EditorPicks";
import CompletedStories from "./components/CompletedStories";
import MaybeLike from "./components/MaybeLike";

const Home = () => {
  return (
    <>
      <PageTitle name="home" />
      <div className="content-wrap pt-10">
        <div className="flex mb-20">
          <ThisWeekRecommend />
          <EditorRecommend className="block ml-12 min-w-0" />
        </div>
        <div className="mb-20">
          <EveryWeekRecommend />
        </div>
        <div className="mb-20">
          <HotStory />
        </div>
        <div className="mb-20">
          <HotTags />
        </div>
        <div className="flex mb-20">
          <NewArrivals />
          <EditorPicks className="ml-16 min-w-0" />
        </div>
      </div>
      <div className="bg-color-default pt-8 pb-8 mb-20">
        <div className="content-wrap">
          <CompletedStories />
        </div>
      </div>
      <div className="content-wrap mb-20">
        <MaybeLike />
      </div>
    </>
  );
};

export default Home;
