import { useTheme } from "@mui/material";
import { RadioFilter } from "./StoryListFilter";
import Typography from "@mui/material/Typography";
import { PropsWithChildren } from "react";
import StorySorter from "./StoryListOrder";
import { storyStatusOptions, storyOrderOptions } from "@/config/options";
import { useTranslation } from "react-i18next";
import { StoryListParams } from "@/api/reading";

export const BoxTitle = (props: PropsWithChildren) => {
  const theme = useTheme();
  return (
    <Typography
      variant="h4"
      sx={{
        fontSize: "20px",
        fontWeight: 500,
        lineHeight: "29px",
        padding: "16px 0",
        borderBottom: "1px solid " + theme.palette.divider,
      }}
    >
      {props.children}
    </Typography>
  );
};

interface Props {
  params: StoryListParams;
  hideOrder?: boolean;
  onParamsChange: (params: any) => void;
}

export default function StoryListHead({ params, onParamsChange, hideOrder }: Props) {
  const { t } = useTranslation();
  const statusOptions = TransOptions(storyStatusOptions);
  const orderOptions = TransOptions(storyOrderOptions);

  function TransOptions<T extends Array<any>>(options: T) {
    return options.map((item) => ({
      ...item,
      label: t(item.label),
    }));
  }

  const handleParamsChange = (params: any) => {
    onParamsChange(params);
  };

  return (
    <div className="storyListHead">
      <div className="filters">
        <BoxTitle>{t("common.filters")}</BoxTitle>
        <RadioFilter
          title={t("storyList.storyStatus")!}
          options={statusOptions}
          value={params.status}
          addAll
          onChange={(v) => handleParamsChange({ ...params, status: v })}
        />
      </div>
      {!hideOrder && (
        <div className="orders">
          <BoxTitle>
            {params.search
              ? t('storyList.seachLabel', { search: params.search })
              : t("common.sortby")}
          </BoxTitle>
          <StorySorter
            options={orderOptions}
            value={params.order}
            onChange={(v) => handleParamsChange({ ...params, order: v })}
          />
        </div>
      )}
    </div>
  );
}
