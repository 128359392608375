import { CategoryEntity } from "@/entity/category";
import $http from "@/utils/http";

export function categoryList(): Promise<{items: CategoryEntity[]}> {
	return $http.request({
		url: "category/list",
		method: "get",
	});
}

export function categoryInfo(params: {category_id: number}): Promise<CategoryEntity> {
	return $http.request({
		url: "category/info",
		method: "get",
		params,
	});
}