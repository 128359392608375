import { ReactElement, useContext } from "react";
import { ConfirmContext } from "./ConfirmContext";

export interface ConfirmOptions {
	title: string;
	content?: string | ReactElement | null;
	cancelText?: string;
	confirmText?: string;
}

export default function useConfirm() {
	const ctx = useContext(ConfirmContext);

	function confirm (options: ConfirmOptions) {
		return new Promise((resolve) => {
			ctx.setOptions!({
				open: true,
				...options,
				onCancel: () => {
					ctx.setOptions!(null)
					resolve(false)
				},
				onConfirm: () => {
					ctx.setOptions!(null)
					resolve(true)
				},
			})
		})
	}

	return {confirm}
}