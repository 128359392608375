import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import PageTitle from "@/components/PageTitle";
import Spinner from "@/components/Spinner";
import StoryListAside from "./components/StoryListAside";
import StoryListHead from "./components/StoryListHead";
import StoryListMain from "./components/StoryListMain";
import StoryListSearch from "./components/StoryListSearch";
import { StoryOrder } from "@/config/enum";
import * as readingApi from "@/api/reading";
import { StoryEntity } from "@/entity/story";
import { serializeQueryParams } from "@/utils/utils";
import SearchEmptyPng from "@/assets/images/search-empty.png";

export default function Search() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const query = useParams();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [read, setReady] = useState(true);
  const search = query.search?.trim() || void 0;

  const params = useMemo(() => ({
    page: +searchParams.get("page")! || 1,
    page_size: 10,
    category_id: +searchParams.get("category_id")! || void 0,
    order: (searchParams.get("order") as StoryOrder) || StoryOrder.UpdateTime,
    status: +searchParams.get("status")! || void 0,
    search,
  }), [query]);
  const [state, setState] = useState({
    total: 0,
    items: [] as StoryEntity[],
  });

  const navigateWithParams = (updateParams: Partial<readingApi.StoryListParams>, new_search?: number | string) => {
    navigate(
      "/search/" +
        (new_search ?? search ?? "") +
        "?" +
        serializeQueryParams({
          ...params,
          ...updateParams,
          search: void 0,
          page_size: void 0,
        })
    );
  };

  const handleSearch = (new_search: string) => {
    navigateWithParams({ page: 1 }, new_search);
  };

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        const data = await readingApi.storyList(params);
        setState({
          total: data.total,
          items: data.items,
        });
        setReady(true);
      } finally {
        setLoading(false);
      }
    };
    load();
  }, [params]);

  const renderBody = () => {
    if (read && search && !state.items.length) {
      return (
        <>
          <StoryListHead params={params} onParamsChange={(v) => navigateWithParams(v)} hideOrder />
          <div>
            <Typography variant="body1" color="initial" sx={{ margin: "2rem", textAlign: "center" }}>
              {t("storyList.searchEmpty", { search })}
            </Typography>
            <img className="mx-auto" src={SearchEmptyPng} alt="" />
          </div>
        </>
      );
    }

    return (
      <>
        <StoryListHead params={params} onParamsChange={(v) => navigateWithParams(v)} />
        <StoryListMain
          page={params.page!}
          page_size={params.page_size!}
          {...state}
          onPageChange={(page) => navigateWithParams({ ...params, page })}
        />
      </>
    );
  };

  return (
    <>
      <PageTitle name="search" title={search} />
      <Spinner loading={loading}>
        <div className="content-wrap flex justify-around pt-4">
          <StoryListAside
            value={params.category_id}
            onChange={(v?: number) => navigateWithParams({ ...params, category_id: v })}
          />
          <div className="main flex-1 ml-8">
            <StoryListSearch search={search || ""} onSearch={(w: string) => handleSearch(w)}></StoryListSearch>
            {renderBody()}
          </div>
        </div>
      </Spinner>
    </>
  );
}
