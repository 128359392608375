import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Pagination, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import Spinner from "@/components/Spinner";
import Empty from "@/components/Empty";
import { SubscribeStoryEntity } from "@/entity/subscribe-story";
import * as fundApi from "@/api/fund";
import { serializeQueryParams } from "@/utils/utils";
import { formatSubscribeStatus, formatTime } from "@/utils/format";
import { SubscribeStoryStatus } from "@/config/enum";

export default function SubscribeRecord() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = +searchParams.get("page")! || 1;
  const page_size = 20;
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    total: 0,
    items: [] as SubscribeStoryEntity[],
  });

  const load = async () => {
    try {
      setLoading(true);
      const result = await fundApi.getSubscribeRecords({
        page,
        page_size,
      });
      setState(result);
    } finally {
      setLoading(false);
    }
  };

  const onPageChange = (page: number) => {
    navigate(
      "/fund/subscribe/record?" +
        serializeQueryParams({
          page,
        })
    );
  };

  const handleSubscribe = async (row: SubscribeStoryEntity) => {
    await fundApi.subscribeStory({
      story_id: row.story_id,
    });
    load();
  };

  const handleCancel = async (row: SubscribeStoryEntity) => {
    await fundApi.UnsubscribeStory({
      story_id: row.story_id,
    });
    load();
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <>
      <Table sx={{ minWidth: "100%" }} size="medium">
        <TableHead>
          <TableRow>
            <TableCell>{t("common.story")}</TableCell>
            <TableCell>{t("fund.subscribeTime")}</TableCell>
            <TableCell>{t("fund.purchasePoints")}</TableCell>
            <TableCell>{t("fund.subscribeStatus")}</TableCell>
            <TableCell align="center">{t("common.operation")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading || !state.items.length ? (
            <TableRow>
              <TableCell colSpan={5}>
                {loading ? <Spinner loading={loading} /> : <Empty />}
              </TableCell>
            </TableRow>
          ) : (
            state.items.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.story_title}</TableCell>
                <TableCell>{formatTime(row.create_time)}</TableCell>
                <TableCell>{row.points}</TableCell>
                <TableCell>{formatSubscribeStatus(row.status)}</TableCell>
                <TableCell align="center">
                  {row.status === SubscribeStoryStatus.On ? (
                    <Button variant="text" sx={{ color: "#000" }} size="mini" onClick={() => handleCancel(row)}>
                      {t("fund.subscribeCancel")}
                    </Button>
                  ) : (
                    <Button variant="text" size="mini" sx={{ color: "#155BD4" }} onClick={() => handleSubscribe(row)}>
                      {t("fund.subscribeAgain")}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      <div className="clearfix mt-3">
        <Pagination
          className="float-right"
          count={Math.ceil(state.total / page_size)}
          page={page}
          variant="outlined"
          shape="rounded"
          color="primary"
          boundaryCount={2}
          onChange={(_, page) => onPageChange(page)}
        />
      </div>
    </>
  );
}
