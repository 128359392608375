import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Stack, Box, Divider, Typography, Button, Skeleton, MenuList, MenuItem, Tabs, Tab, Pagination } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { grey } from "@mui/material/colors";
import PageTitle from "@/components/PageTitle";
import PartsMenu from "./components/PartsMenu";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useTranslation } from "react-i18next";
import StoryStat from "./components/StoryStat";
import { StoryEntity } from "@/entity/story";
import * as writingApi from "@/api/writing";
import { formatCover, formatTime } from "@/utils/format";
import { ClickableRichTooltip } from "@/components/RichTooltip";
import { useConfirm } from "@/hooks";
import styles from "./WritingList.module.css";

export default function WritingList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { confirm } = useConfirm();
  const [activeTab, setActiveTab] = useState("published");
  const [isReady, setIsReady] = useState(false);
  const [state, setState] = useState<Awaited<ReturnType<typeof writingApi.myStoryList>>>({
    page: 1,
    page_size: 20,
    items: [],
    total: 0,
  });

  const load = async () => {
    const params: writingApi.MyStoryListParams = { with_parts: true, page: state.page, page_size: state.page_size };
    if (activeTab === "published") {
      params.is_published = true;
    }
    const data = await writingApi.myStoryList(params);
    setState(data);
    setIsReady(true);
  };

  const handleRead = async (story: StoryEntity) => {
    navigate("/story/" + story.id);
  };

  const handleUnpublish = async (story: StoryEntity) => {
    if (
      await confirm({
        title: t("writing.unpublishStoryConfirmTitle"),
        content: t("writing.unpublishStoryConfirmMsg"),
      })
    ) {
      await writingApi.unpublishStory({ story_id: story.id });
      if (activeTab === "published") {
        load();
      }
    }
  };

  const handleDelete = async (story: StoryEntity) => {
    if (
      await confirm({
        title: t("writing.deleteStoryConfirmTitle"),
        content: t("writing.deleteStoryConfirmMsg"),
      })
    ) {
      await writingApi.deleteStory({ story_id: story.id });
      load();
    }
  };

  const handleComplete = async (story: StoryEntity) => {
    await writingApi.completeStory({ story_id: story.id });
    toast.success(t("writing.completeStorySuccess"));
  };

  const getStoryMenu = (story: StoryEntity) => {
    const menuItems = [
      {
        label: t("writing.viewAsReader"),
        onClick: handleRead,
      },
      ...(story.is_published
        ? [
            {
              label: t("common.unpublish"),
              onClick: handleUnpublish,
            },
          ]
        : []),
      {
        label: t("writing.deleteStory"),
        onClick: handleDelete,
      },
      ...(story.is_published
        ? [
            {
              label: t("writing.completeStory"),
              onClick: handleComplete,
            },
          ]
        : []),
    ].map((item) => [
      <MenuItem key={item.label} onClick={() => item.onClick(story)}>
        {item.label}
      </MenuItem>,
    ]);

    return (
      <MenuList
        dense
        sx={{
          minWidth: "160px",
        }}
      >
        {menuItems}
      </MenuList>
    );
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    state.page = 1;
    setActiveTab(newValue);
    load();
  };

  const handlePageChange = (page: number) => {
    state.page = page;
    load();
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <>
      <PageTitle name="myStories" />
      <Box className="content-wrap">
        <Stack spacing={2}>
          <div className={styles.header}>
            <Typography variant="h2" className={styles.title} gutterBottom>
              {t("writing.myStories")}
            </Typography>
            {!!state.items.length && (
              <Link to="/writing/story/new">
                <Button
                  variant="contained"
                  startIcon={<AddOutlinedIcon />}
                  sx={{ paddingLeft: "40px", paddingRight: "40px" }}
                >
                  {t("writing.newStory")}
                </Button>
              </Link>
            )}
          </div>
          <div className={styles.content}>
            <Tabs value={activeTab} onChange={handleTabChange} className={styles.tabs}>
              <Tab label={t("common.published")} value="published" sx={{ marginRight: "3rem" }} />
              <Tab label={t("writing.allStories")} value="all" />
            </Tabs>
            <div className={styles.storyList}>
              {!isReady &&
                Array.from(new Array(5)).map((_, index) => (
                  <Fragment key={index}>
                    <div className="flex items-start px-6 py-4">
                      <Skeleton variant="rectangular" width={90} height={120} />
                      <div className="flex-1 ml-4 mr-8">
                        <Skeleton animation="wave" width="80%" />
                        <Skeleton animation="wave" width="50%" />
                        <Skeleton animation="wave" width="20%" />
                        <Skeleton animation="wave" width="20%" />
                      </div>
                      <Skeleton variant="rectangular" width={136} height={36} animation="wave" />
                    </div>
                    {index % 2 === 0 && <Divider />}
                  </Fragment>
                ))}
              {isReady && state.items.length === 0 && (
                <div className={styles.empty}>
                  <AutoStoriesIcon sx={{ color: grey["700"], fontSize: "120px" }} />
                  <Typography variant="body1" sx={{ color: grey["700"] }}>
                    {t("writing.noStory")}
                  </Typography>
                  <center>
                    <Link to="/writing/story/new">
                      <Button
                        variant="contained"
                        startIcon={<AddOutlinedIcon />}
                        sx={{ paddingLeft: "40px", paddingRight: "40px" }}
                      >
                        {t("writing.newStory")}
                      </Button>
                    </Link>
                  </center>
                </div>
              )}
              {state.items.map((story, index) => (
                <Fragment key={story.id}>
                  <div className={styles.storyContainer}>
                    <div className={styles.leftContainer} onClick={() => navigate(`/writing/story/${story.id}`)}>
                      <div className={styles.storyCover}>
                        <img {...formatCover(story.cover, "small")} alt={story.title} />
                      </div>
                      <div className={styles.storyInfo}>
                        <Link to={`/writing/story/${story.id}`} className={styles.storyTitle}>
                          <Typography variant="h3" className={styles.storyTitle}>
                            {story.title}
                          </Typography>
                        </Link>
                        <div className={styles.storyStatus}>
                          {story.is_published ? (
                            <span className={styles.published}>{t("common.published")}</span>
                          ) : (
                            <span className={styles.draft}>{t("common.draft")}</span>
                          )}
                        </div>
                        <div className={styles.storyTime}>
                          {t("writing.updateTimeLabel")} {formatTime(story.update_time, true)}
                        </div>
                        <StoryStat {...story} />
                      </div>
                    </div>
                    <div className={styles.rightContainer}>
                      <PartsMenu parts={story.parts || []} story_id={story.id}>
                        <Button variant="contained" sx={{ minWidth: "136px" }}>
                          {t("writing.continueWriting")}
                        </Button>
                      </PartsMenu>
                      <div style={{ marginTop: "15px", textAlign: "right" }}>
                        <ClickableRichTooltip padding={0} content={getStoryMenu(story)} placement="bottom-end">
                          <Button size="small" variant="outlined" color="info">
                            <MoreHorizIcon className="font-color-grey" />
                          </Button>
                        </ClickableRichTooltip>
                      </div>
                    </div>
                  </div>
                  <Divider />
                </Fragment>
              ))}

              <div className="clearfix my-3 mr-6">
                <Pagination
                  className="float-right"
                  count={Math.ceil(state.total / state.page_size)}
                  page={state.page}
                  variant="outlined"
                  shape="rounded"
                  color="primary"
                  boundaryCount={2}
                  onChange={(_, page) => handlePageChange(page)}
                />
              </div>
            </div>
          </div>
        </Stack>
      </Box>
    </>
  );
}
