import React, { ReactElement, useRef, useState } from "react";
import { PopperPlacementType } from "@mui/material";
import RichTooltip from "./RichTooltip";

interface Props {
  content: ReactElement | ((open: boolean) => ReactElement);
  children: ReactElement;
  disabled?: boolean;
  arrow?: boolean;
  color?: string;
  padding?: string|number;
  placement?: PopperPlacementType;
  autoCloseOnClick?: boolean;
  trigger?: "click" | "hover";
}

const ClickableRichTooltip = ({
  placement,
  arrow,
  content,
  children,
  padding,
  color,
  trigger = "click",
  disabled = false,
  autoCloseOnClick = true,
}: Props) => {
  const [open, setOpen] = useState(false);
  const timer = useRef<number | null>(null);

  if (disabled) {
    return React.cloneElement(children, { ...children.props, disabled: true });
  }

  const existingOnClick = children.props.onClick;
  const newOnClick = (e: MouseEvent) => {
    setOpen(!open);
    existingOnClick && existingOnClick(e);
  };

  const existingOnEnter = children.props.onEnter;
  const newOnEnter = (e: MouseEvent) => {
    onEnter();
    existingOnEnter && existingOnEnter(e);
  };

  const existingOnLeave = children.props.onLeave;
  const newOnLeave = (e: MouseEvent) => {
    onLeave();
    existingOnLeave && existingOnLeave(e);
  };

  const onEnter = () => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    setOpen(true);
  };

  const onLeave = () => {
    timer.current = window.setTimeout(() => {
      setOpen(false);
    }, 300);
  };

  const onContentClick = () => {
    if (autoCloseOnClick) {
      setOpen(false);
    }
  }

  return (
    <RichTooltip
      open={open}
      padding={padding}
      onClose={() => setOpen(false)}
      arrow={arrow}
      placement={placement}
      content={content}
      onContentClick={onContentClick}
      color={color}
      {...(trigger === "hover"
        ? {
            onMouseEnter: onEnter,
            onMouseLeave: onLeave,
          }
        : null)}
    >
      {React.cloneElement(children, {
        ...children.props,
        ...(trigger === "hover" ? { onMouseEnter: newOnEnter, onMouseLeave: newOnLeave } : { onClick: newOnClick }),
      })}
    </RichTooltip>
  );
};

export default ClickableRichTooltip;
