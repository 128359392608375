
export const languageOptions = [
  {
    label: "English",
    value: "en-US",
  },
  {
    label: "简体中文",
    value: "zh-CN",
  },
  // {
  //   label: "繁體中文 (Chinese, Traditional)",
  //   value: "zh-TW",
  // },
  // {
  //   label: "Deutsch (German)",
  //   value: "de-DE",
  // },
  // {
  //   label: "Español (Spanish)",
  //   value: "es-ES",
  // },
  // {
  //   label: "Français (French)",
  //   value: "fr-FR",
  // },
  // {
  //   label: "Italiano (Italian)",
  //   value: "it-IT",
  // },
  // {
  //   label: "日本語 (Japanese)",
  //   value: "ja-JP",
  // },
  // {
  //   label: "한국어 (Korean)",
  //   value: "ko-KR",
  // },
  // {
  //   label: "Nederland (Dutch, Netherlands)",
  //   value: "nl-NL",
  // },
  // {
  //   label: "Português (Portuguese, Brazil)",
  //   value: "pt-BR",
  // },
  // {
  //   label: "Português (Portuguese, Portugal)",
  //   value: "pt-PT",
  // },
  // {
  //   label: "Polskie (Polish)",
  //   value: "pl-PL",
  // },
  // {
  //   label: "فارسی (Persian)",
  //   value: "fa-IR",
  // },
  // {
  //   label: "Pусский (Russian)",
  //   value: "ru-RU",
  // },
  // {
  //   label: "Türkçe (Turkish)",
  //   value: "tr-TR",
  // },
  // {
  //   label: "Tiếng Việt (Vietnamese)",
  //   value: "vi-VN",
  // },
];

export const languages: string[] = languageOptions.map((i) => i.value);
