/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { NavLink, Outlet } from "react-router-dom";
import { ReactComponent as LibraryIcon } from "@/assets/icons/bookshelf-24.svg";
import { ReactComponent as BookmarkIcon } from "@/assets/icons/bookmark-24.svg";
import { ReactComponent as HistoryIcon } from "@/assets/icons/history-24.svg";

const navCss = css`
  display: flex;
  align-items: center;
  height: 40px;
  line-height: 40px;
  padding: 0 16px;
  cursor: pointer;
  margin-bottom: 16px;
  &:hover {
    color: var(--primary-color);
  }
  &.active {
    background: #f5f7f8;
    color: var(--primary-color);
    border-radius: 4px;
  }
`;

export default function Library() {
  const { t } = useTranslation();
  return (
    <>
      <div className="content-wrap">
        <div className="flex pt-8 gap-10">
          <ul className="w-[224px]">
            <li>
              <NavLink to="/library/" css={navCss}>
                <LibraryIcon className="mr-2" />
                {t("library.library")}
              </NavLink>
            </li>
            <li>
              <NavLink to="/library/bookmark" css={navCss}>
                <BookmarkIcon className="mr-2" />
                {t("library.bookmark")}
              </NavLink>
            </li>
            <li>
              <NavLink to="/library/history" css={navCss}>
                <HistoryIcon className="mr-2" />
                {t("library.history")}
              </NavLink>
            </li>
          </ul>
          <div className="flex-1 min-h-[60vh] ">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}
