/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";

interface Props {
  is_completed: boolean;
}
export default function CompleteChip({is_completed}: Props) {
  const {t} = useTranslation();
  return (
    <span
      css={css`
        font-size: 12px;
        line-height: 18px;
        padding: 3px 9px;
        color: #fff;
        margin-right: 8px;
        background: ${is_completed ? '#43CF7C' : '#8d5a10'};
        border-radius: 12px;
      `}
    >{is_completed ? t("common.complete") : t("common.ongoing")}</span>
  );
}
