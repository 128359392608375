import config from "@/config/config";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export default function PageTitle({ title, name }: { title?: string, name?: string }) {
  const {t} = useTranslation();
  if (!title && name) {
    title = t("pageTitle." + name)!;
  }

  return (
    <Helmet>
      <title>{title ? title + ' - ' : ''}{config.siteName} </title>
    </Helmet>
  );
}
