import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import config from "@/config/config";

export default function Footer() {
	const {t} = useTranslation();

  return (
    <footer className="h-[160px] min-h-[160px] flex items-center justify-center" style={{
      backgroundColor: "var(--bg-color-default)"
    }}>
      <ul className="list-none flex text-[13px] leading-5 mx-auto space-x-4">
        <li>
          <Link className="font-color-grey" to="/support/terms">{t("layout.footer.terms")}</Link>
        </li>
        <li>
          <Link className="font-color-grey" to="/support/privacy">{t("layout.footer.privacy")}</Link>
        </li>
        <li>
          <Link className="font-color-grey" to="/support/contact">{t("layout.footer.contact")}</Link>
        </li>
        <li>
          <span className="font-color-grey">
            &copy; 2023 {config.siteName}
          </span>
        </li>
      </ul>
    </footer>
  );
}
