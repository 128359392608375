// 获取评论列表
import { CommentEntity } from "@/entity/comment";
import $http from "@/utils/http";

export interface CommentListParams {
  story_id: number;
  part_id: number;
  cursor?: number;
  limit?: number;
  parent_id?: number;
}

export type CommentListResultItem = CommentEntity & {
  replyData?: {
    items?: Array<CommentEntity>;
    cursor?: number;
    has_more?: boolean;
    expand?: boolean;
  };
};
export interface CommentListResult {
  items: CommentListResultItem[];
  total?: number;
  cursor: number;
  has_more: boolean;
}
export const commentList = (params: CommentListParams): Promise<CommentListResult> => {
  return $http.request({
    url: "comment/list",
    method: "get",
    params,
  });
};

export interface CommentFeaturedListParams {
  story_id: number;
  part_id: number;
  limit?: number;
}
export const commentFeaturedList = (
  params: CommentFeaturedListParams
): Promise<{
  items: CommentEntity[];
  total?: number;
}> => {
  return $http.request({
    url: "comment/featuredList",
    method: "get",
    params,
  });
};

export type NotifyCommentListResultItem = CommentListResultItem & {
  items?: CommentListResultItem[];
};
export const notifyCommentList = (params: {
  notify_id: number;
}): Promise<{
  items: NotifyCommentListResultItem[];
}> => {
  return $http.request({
    url: "comment/notifyCommentList",
    method: "get",
    params,
  });
};

export interface CreateCommentParams {
  story_id: number;
  part_id: number;
  parent_id?: number;
  content: string;
  reply_id?: number;
  reply_user_id?: number;
}
export const createComment = (params: CreateCommentParams): Promise<CommentEntity> => {
  return $http.request({
    url: "comment/create",
    method: "post",
    data: params,
  });
};

export interface LikeCommentParams {
  story_id: number;
  part_id: number;
  comment_id: number;
}
export const likeComment = (params: LikeCommentParams) => {
  return $http.request({
    url: "comment/like",
    method: "post",
    data: params,
  });
};

export const undoLikeComment = (params: LikeCommentParams) => {
  return $http.request({
    url: "comment/undoLike",
    method: "post",
    data: params,
  });
};

export interface DeleteCommentParams {
  story_id: number;
  part_id: number;
  comment_id: number;
}
export const deleteComment = (params: DeleteCommentParams): Promise<{ success: boolean }> => {
  return $http.request({
    url: "comment/delete",
    method: "post",
    data: params,
  });
};
