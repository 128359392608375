/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactElement, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ClickableRichTooltip } from "@/components/RichTooltip";
import { CategoryEntity } from "@/entity/category";
import * as categoryApi from "@/api/category";

interface Props {
  children: ReactElement;
}

export default function BrowsePopover(props: Props) {
  const { t } = useTranslation();
  const [categoryItems, setCategoryItems] = useState<Array<CategoryEntity>>([]);

  useEffect(() => {
    const load = async () => {
      const { items } = await categoryApi.categoryList();
      setCategoryItems(items);
    };

    load();
  }, []);

  const content = (
    <dl
      className="clearfix"
      css={css`
        width: 450px;
      `}
    >
      <dt
        className="text-base"
        css={css`
          font-weight: 700;
          padding-bottom: 10px;
        `}
      >
        {t("layout.nav.browse")}
      </dt>
      {categoryItems.map((item) => (
        <dd
          key={item.id}
          className="w-1/3 float-left truncate text-base"
          css={css`
            padding: 8px 0;
            :hover {
              font-weight: 500;
            }
          `}
        >
          <NavLink to={`/category/${item.id}`}>
            {item.name}
          </NavLink>
        </dd>
      ))}
    </dl>
  );

  return (
    <ClickableRichTooltip content={content} placement="bottom-start" trigger="hover">
      {props.children}
    </ClickableRichTooltip>
  );
}
