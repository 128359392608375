import { AuthContext } from "@/context";
import { useNavigateLogin } from "@/hooks";
import { useContext } from "react";
import { Navigate } from "react-router-dom";

interface Props {
  component: React.FC<any> | React.ComponentClass<any>;
  [key: string]: any;
}
export default function Guard(props: Props) {
  const { loginPath } = useNavigateLogin();
  const { isLogin } = useContext(AuthContext);

  if (!isLogin) {
    return <Navigate to={loginPath} replace />;
  }

  return <props.component {...props} />;
}
