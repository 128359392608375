/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Box, ClickAwayListener, Fade, Paper, Popper, PopperPlacementType, useTheme } from "@mui/material";
import React, { MouseEvent, ReactElement } from "react";

interface Props {
  content: ReactElement | ((open: boolean) => ReactElement);
  children: ReactElement;
  open: boolean;
  onClose?: () => void;
  arrow?: boolean;
  color?: string;
  padding?: string|number;
  placement?: PopperPlacementType;
  onMouseEnter?: (e: MouseEvent<HTMLDivElement>) => void;
  onMouseLeave?: (e: MouseEvent<HTMLDivElement>) => void;
  onContentClick?: (e: MouseEvent<HTMLDivElement>) => void;
}

const RichTooltip = ({
  placement = "top",
  arrow = true,
  open,
  color,
  content,
  onClose = () => {},
  children,
  onMouseEnter,
  onMouseLeave,
  onContentClick,
  padding,
}: Props) => {
  const theme = useTheme();
  color = color || theme.palette.background.paper;

  const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null);
  const [childNode, setChildNode] = React.useState<HTMLElement | null>(null);

  const renderContentNode = () => {
    return typeof content === "function" ? content(open) : content;
  }

  return (
    <>
      {React.cloneElement(children, { ...children.props, ref: setChildNode })}
      <Popper
        open={open}
        anchorEl={childNode}
        placement={placement}
        transition
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        modifiers={[
          {
            name: "preventOverflow",
            enabled: true,
            options: {
              rootBoundary: "viewport",
            },
          },
          {
            name: "arrow",
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
          {
            name: "offset",
            enabled: true,
            options: {
              offset: [0, 10],
            },
          },
        ]}
        sx={{
          zIndex: 2000,
          '&[data-popper-placement*="bottom"] .rich-tooltip-arrow': {
            top: 0,
            left: 0,
            marginTop: "-0.71em",
            marginLeft: 0,
            marginRight: 4,
            "&::before": {
              transformOrigin: "0 100%",
            },
          },
          '&[data-popper-placement*="top"] .rich-tooltip-arrow': {
            bottom: 0,
            left: 0,
            marginBottom: "-0.71em",
            marginLeft: 4,
            marginRight: 4,
            "&::before": {
              transformOrigin: "100% 0",
            },
          },
          '&[data-popper-placement*="right"] .rich-tooltip-arrow': {
            left: 0,
            marginLeft: "-0.71em",
            height: "1em",
            width: "0.71em",
            marginTop: 4,
            marginBottom: 4,
            "&::before": {
              transformOrigin: "100% 100%",
            },
          },
          '&[data-popper-placement*="left"] .rich-tooltip-arrow': {
            right: 0,
            marginRight: "-0.71em",
            height: "1em",
            width: "0.71em",
            marginTop: 4,
            marginBottom: 4,
            "&::before": {
              transformOrigin: "0 0",
            },
          },
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper elevation={4} sx={{ backgroundColor: color, maxWidth: 1000 }}>
              <ClickAwayListener onClickAway={onClose}>
                <div>
                  {arrow ? (
                    <span
                      className="rich-tooltip-arrow"
                      ref={setArrowRef}
                      css={css`
                        overflow: hidden;
                        position: absolute;
                        width: 1em;
                        height: 0.71em;
                        box-sizing: border-box;
                        color: ${color};
                        :before {
                          content: "";
                          margin: auto;
                          display: block;
                          width: 100%;
                          height: 100%;
                          box-shadow: ${theme.shadows[1]};
                          background-color: currentColor;
                          transform: rotate(45deg);
                        }
                      `}
                    />
                  ) : null}
                  <Box onClick={onContentClick} sx={{ padding: padding ?? theme.spacing(2) }}>
                    {renderContentNode()}
                  </Box>
                </div>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default RichTooltip;
