import { ReactComponent as ReadCountIcon } from "@/assets/icons/read-count.svg";
import { ReactComponent as LikeCountIcon } from "@/assets/icons/like-count.svg";
import { ReactComponent as CommentCountIcon } from "@/assets/icons/comment-count.svg";
import styles from "./StoryStat.module.css";

interface Props {
  read_count?: number;
  like_count?: number;
  comment_count?: number;
}

export default function StoryStat(props: Props)  {
  return (
    <>
      <div className={styles.countsItem}>
        <ReadCountIcon />
        <span>{props.read_count ?? '0'}</span>
      </div>
      <div className={styles.countsItem}>
        <LikeCountIcon />
        <span>{props.like_count ?? '0'}</span>
      </div>
      <div className={styles.countsItem}>
        <CommentCountIcon />
        <span>{props.comment_count ?? '0'}</span>
      </div>
    </>
  )
}
