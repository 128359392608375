/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";
import BlockTitle from "./BlockTitle";
import { Typography } from "@mui/material";

interface Props {
	className?: string;
}
export default function EditorRecommend(props: Props) {
	const {t} = useTranslation();

	const items = [
		{
			id: "hot",
			cover: "/example/editor-rec.png",
			title: "热门作品",
			subtitle: "推荐排行榜",
		},
		{
			id: "complete",
			cover: "/example/editor-rec.png",
			title: "完结故事",
			subtitle: "完结故事排行榜",
		},
		{
			id: "month",
			cover: "/example/editor-rec.png",
			title: "月票排行",
			subtitle: "根据用户投票排行",
		},
		{
			id: "new",
			cover: "/example/editor-rec.png",
			title: "新书推荐",
			subtitle: "最新书籍排行",
		}
	]

	return (
		<div className={props.className}>
			<BlockTitle divider>{t("home.editorRecommend")}</BlockTitle>
			<ul className="flex" css={css`margin-left: -12px; margin-right: -12px;`}>
				{items.map(item => (
					<li key={item.id} css={css`
						margin: 24px 12px 0;
					`}>
						<div className="cover" css={css`
							width: 140px;
							height: 187px;
						`}>
							<img src={item.cover} alt="" css={css`width: 100%; height: 100%;`} />
						</div>
						<div className="info">
							<Typography variant="h4" className="title" css={css`
								margin-top: 14px;
								font-size: 16px;
								font-weight: 500;
								line-height: 1.45;
							`}>{item.title}</Typography>
							<div className="subtitle font-color-grey">{item.subtitle}</div>
						</div>
					</li>
				))}
			</ul>
		</div>
	)
}