import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Divider, Button, Typography, useTheme } from "@mui/material";
import { AuthContext } from "@/context";
import * as profileApi from "@/api/profile";
import Spinner from "@/components/Spinner";
import PageTitle from "@/components/PageTitle";
import ProfileTabs from "./components/ProfileTabs";
import ProfileEdit from "./components/ProfileEdit";
import UserAvatar from "@/components/UserAvatar";
import { ReactComponent as BannerImg } from "@/assets/images/profile-banner.svg";
// import { ReactComponent as HeartIcon } from "@/assets/icons/heart.svg";
import { ReactComponent as CalendarIcon } from "@/assets/icons/calendar.svg";
import { ReactComponent as LocationIcon } from "@/assets/icons/location.svg";
import { formatLocation, formatTime } from "@/utils/format";

interface Props {}
export default function Profile(props: Props) {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { user_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState<Awaited<ReturnType<typeof profileApi.getPublicInfo>>>();
  const [isEdit, setIsEdit] = useState(false);
  // const theme = useTheme();

  const isSelf = +user_id! === user?.id;

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        const ret = await profileApi.getPublicInfo({ user_id: +user_id! });
        setProfileData(ret);
      } finally {
        setLoading(false);
      }
    };

    load();
  }, [user_id]);

  return (
    <>
      <PageTitle name="profile" />
      <div className="profile-page">
        <section className="bg-color-default">
          <div className="content-wrap">
            <div className="banner relative">
              <BannerImg className="w-full h-[380px]" />
              <div className="absolute right-2 bottom-6 flex justify-between items-center text-center">
                <div className="px-6">
                  <div className="text-2xl mb-[2px]">{profileData?.works_count}</div>
                  <div>{t("profile.works")}</div>
                </div>
                {/* <Divider orientation="vertical" sx={{ height: "25px", borderColor: "#B9BEC1", margin: "0 24px" }} />
                <div className="px-6">
                  <div className="text-2xl mb-[2px]">{profileData?.readingList_count}</div>
                  <div>{t("profile.readingList")}</div>
                </div> */}
              </div>
            </div>
          </div>
        </section>
        {isEdit ? (
          <ProfileEdit onClose={() => setIsEdit(false)} />
        ) : (
          <section>
            <Spinner loading={loading}>
              <div className="profile-info bg-color-default pt-6 pb-8 mb-6">
                <div className="content-wrap">
                  <div className="info">
                    <div className="flex justify-between items-center mb-4 h-20">
                      <UserAvatar user={profileData} size="l" />
                      {isSelf && (
                        <div className="actions">
                          {/* <Button
                          variant="contained"
                          size="small"
                          color="secondary"
                          // className="selected"
                          sx={{
                            width: 64,
                            height: 34,
                            marginRight: "16px",
                          }}
                        >
                          <HeartIcon color={theme.palette.secondary.dark} />
                        </Button> */}
                          <Button
                            variant="contained"
                            color="info"
                            size="small"
                            sx={{ height: 34, paddingLeft: "16px", paddingRight: "16px", color: "var(--grey-color)" }}
                            onClick={() => setIsEdit(true)}
                          >
                            {t("profile.editProfile")}
                          </Button>
                        </div>
                      )}
                    </div>
                    <div className="username text-2xl font-medium mb-6">{profileData?.username}</div>
                    <Typography variant="body2" className="font-color-grey line-clamp-3" sx={{ width: "60%" }}>
                      {profileData?.about || t("profile.aboutEmpty")}
                    </Typography>
                    <div className="mt-5 font-color-grey">
                      <CalendarIcon className="inline-block mr-1" />
                      <span>{profileData && formatTime(profileData?.create_time, true)}</span>
                      <span className="ml-2">{t("profile.joined")}</span>
                      <LocationIcon className="inline-block ml-14 mr-2" />
                      <span>{formatLocation(profileData?.location)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </Spinner>
            <div className="page-body">
              <ProfileTabs />
            </div>
          </section>
        )}
      </div>
    </>
  );
}
