import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { AuthContext } from "@/context";
import { regionOptions } from "@/config/options";
import * as profileApi from "@/api/profile";
import * as uploadApi from "@/api/upload";
import { getFileMd5 } from "@/utils/utils";
import UserAvatar from "@/components/UserAvatar";
import Spinner from "@/components/Spinner";

const SIZE_LIMIT = 1024 * 1024 * 2;

interface Props {
  onClose: () => void;
}
export default function ProfileEdit(props: Props) {
	const { user, setUser } = useContext(AuthContext);
  const { t } = useTranslation();
	const [avatar, setAvatar] = useState<string>("");
  const [uploading, setUploading] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
		setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      location: "",
      about: "",
			avatar: "",
    },
  });

  const rules = {
    username: {
      required: t("profile.usernameRequired"),
      minLength: { value: 3, message: t("profile.usernameLengthMsg") },
      maxLength: { value: 30, message: t("profile.usernameLengthMsg") },
    },
		location: {

		},
    about: {
      maxLength: { value: 2000, message: t("profile.aboutLengthMsg") },
    },
  };

	useEffect(() => {
		const load = async () => {
			const ret = await profileApi.getPrivateInfo();
			setValue("username", ret.username);
			setValue("location", ret.location || "");
			setValue("about", ret.about || "");
			setAvatar(ret.avatar || "");
		}

		load();
	}, [])

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    if (file.size > SIZE_LIMIT) {
      toast.error(t("profile.avatarSizeLimit"));
      return;
    }

    const reader = new FileReader();
    reader.onload = e => {
      const cover = e.target!.result;
      setAvatar(cover as string);
    };
    reader.readAsDataURL(file);

    try {
      setUploading(true);
      const md5 = await getFileMd5(file);
  
      const { path } = await uploadApi.uploadImage({
        file,
        md5,
      });
      setValue("avatar", path);
    } finally {
      setUploading(false);
    }
  };

  const onSubmit = async (params: {username: string; location: string; about: string; avatar?: string}) => {
    if (uploading) {
      toast.warning(t("profile.waitUploadingTip"));
      return;
    }

		const ret = await profileApi.updateInfo(params);
		toast.success(t("common.success"));
		await setUser({
			...user!,
      avatar: ret.avatar || user!.avatar,
			username: ret.username || user!.username,
		})
    props.onClose();
	};

  return (
    <div className="content-wrap w-[788px]">
      <div className="avatar-wrap relative w-[120px] h-[120px] mx-auto my-12">
        <UserAvatar user={{...user!, avatar}} size="xl" />
        <Spinner className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" loading={uploading} tip={t("common.uploading")!} />
        <label className="absolute right-[-6px] bottom-0 w-10 h-10 rounded-[50%] bg-[#E7E7E7] p-2 cursor-pointer">
          <CameraAltIcon />
					<input hidden accept="image/*" type="file" onChange={handleFileChange} />
        </label>
      </div>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
				<div className="mb-6">
					<Controller
						name="username"
						control={control}
						rules={rules.username}
						render={({ field }) => (
							<FormControl error={!!errors.username} className="w-full">
								<FormLabel sx={{ fontWeight: 500 }} htmlFor="username">
									{t("profile.usernameLabel")}
								</FormLabel>
								<OutlinedInput id="username" placeholder={t("profile.usernamePlaceholder")!} size="small" {...field} />
								{errors.username && (
									<FormHelperText error={!!errors.username}>{errors.username.message! as string}</FormHelperText>
								)}
							</FormControl>
						)}
					/>
				</div>
				<div className="mb-6">

        <Controller
          name="location"
          control={control}
          rules={rules.location}
          render={({ field }) => (
            <FormControl error={!!errors.location} className="w-full">
              <FormLabel sx={{ fontWeight: 500 }} htmlFor="location">
                {t("profile.locationLabel")}
              </FormLabel>
              <Select
                id="location"
                fullWidth
                displayEmpty
                size="small"
                {...field}
                renderValue={(selected: string) => {
                  if (!selected) {
                    return <Typography color={"darkgray"}>{t("profile.locationPlaceholder")}</Typography>;
                  }
									const reg = regionOptions.find(item => item.value === selected);
									return reg ? t(reg.label)! : "";
                }}
              >
                {regionOptions.map(region => (
                  <MenuItem key={region.value} value={region.value}>
                    {t(region.label)!}
                  </MenuItem>
                ))}
              </Select>
              {errors.location && (
                <FormHelperText error={!!errors.location}>{errors.location.message! as string}</FormHelperText>
              )}
            </FormControl>
          )}
        />
				</div>
				<div className="mb-6">
					<Controller
						name="about"
						control={control}
						rules={rules.about}
						render={({ field }) => (
							<FormControl error={!!errors.about} className="w-full">
								<FormLabel sx={{ fontWeight: 500 }} htmlFor="about">
									{t("profile.aboutLabel")}
								</FormLabel>
								<OutlinedInput id="about" {...field} size="small" placeholder={t("profile.aboutPlaceholder")!} multiline rows={5} />
								{errors.about && (
									<FormHelperText error={!!errors.about}>{errors.about.message! as string}</FormHelperText>
								)}
							</FormControl>
						)}
					/>
				</div>
				<div className="mb-6">
					<Button variant="contained" color="primary" type="submit">{t("common.save")!}</Button>
					<Button variant="contained" color="info" sx={{marginLeft: '16px'}} onClick={props.onClose}>{t("common.cancel")!}</Button>
				</div>
      </form>
    </div>
  );
}
