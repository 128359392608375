import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { IconButton, Pagination, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { ReadHistoryEntity } from "@/entity/read-history";
import { serializeQueryParams } from "@/utils/utils";
import * as readHistoryApi from "@/api/read-history";
import { formatTime } from "@/utils/format";
import PageTitle from "@/components/PageTitle";
import Spinner from "@/components/Spinner";
import Empty from "@/components/Empty";
import Search from "@/components/Search";
import { ReactComponent as DeleteIcon } from "@/assets/icons/delete-24.svg";

export default function ReadHistory() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const search = searchParams.get("search")! || void 0;
  const page = +searchParams.get("page")! || 1;
  const page_size = 20;
  const [state, setState] = useState({
    total: 0,
    items: [] as ReadHistoryEntity[],
  });

  const load = async () => {
    try {
      setLoading(true);
      const data = await readHistoryApi.list({
        page,
        page_size,
        search,
      });
      setState(data);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (row: ReadHistoryEntity) => {
    setLoading(true);
    try {
      await readHistoryApi.del({
        story_id: row.story_id,
        part_id: row.part_id,
      });
    } finally {
      setLoading(false);
    }
    load();
  }

  const onPageChange = (page: number) => {
    navigate(
      "?" +
        serializeQueryParams({
          page,
          search: search || void 0,
        })
    );
  };

  const onSearchChange = (search?: string) => {
    navigate(
      "?" +
        serializeQueryParams({
          page,
          search: search || void 0,
        })
    );
  };

  useEffect(() => {
    load();
  }, [page, search]);

  return (
    <div className="library relative">
      <PageTitle name="library" />
      <div className="float-right mb-4">
        <Search search={search} onSearch={onSearchChange} />
      </div>
      <Table sx={{ minWidth: "100%" }} size="small">
        <TableHead>
          <TableRow>
            <TableCell width={120}>{t("library.category")}</TableCell>
            <TableCell>{t("library.storyNameAndAuthor")}</TableCell>
            <TableCell>{t("library.date")}</TableCell>
            <TableCell>{t("library.latestRead")}</TableCell>
            <TableCell align="center" width={100}>
              {t("library.status")}
            </TableCell>
            <TableCell align="center" width={80}>
              {t("library.manage")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading || !state.items.length ? (
            <TableRow>
              <TableCell colSpan={6}>{loading ? <Spinner loading={loading} /> : <Empty />}</TableCell>
            </TableRow>
          ) : (
            state.items.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.story?.category?.name}</TableCell>
                <TableCell>
                  <Link className="block font-color-primary max-w-[200px] truncate" to={"/story/" + row.story_id}>
                    {row.story?.title}
                  </Link>
                  <div className="mt-2">{row.story?.author?.username}</div>
                </TableCell>
                <TableCell>{formatTime(row.update_time, true)}</TableCell>
                <TableCell>
                  <Link className="block font-color-primary max-w-[200px] truncate" to={"/part/" + row.part?.story_id + "/" + row.part?.id}>
                    {row.part?.title}
                  </Link>
                </TableCell>
                <TableCell align="center">
                  {row.story?.is_completed ? t("common.complete") : t("common.ongoing")}
                </TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => handleDelete(row)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      <div className="clearfix mt-3">
        <Pagination
          className="float-right"
          count={Math.ceil(state.total / page_size)}
          page={page}
          variant="outlined"
          shape="rounded"
          color="primary"
          boundaryCount={2}
          onChange={(_, page) => onPageChange(page)}
        />
      </div>
    </div>
  );
}
