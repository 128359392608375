import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as profileApi from "@/api/profile";
import { StoryOrder } from "@/config/enum";
import { StoryEntity } from "@/entity/story";
import Spinner from "@/components/Spinner";
import StoryListMain from "@/pages/StoryList/components/StoryListMain";

export default function WorksPanel() {
  const { user_id } = useParams();
  const isSelf = !user_id;
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState<profileApi.GetWorksParams>({
    page: 1,
    page_size: 10,
    order: StoryOrder.UpdateTime,
  });
  const [state, setState] = useState({
    total: 0,
    items: [] as StoryEntity[],
  });

  const onPageChange = (page: number) => {
    setParams({ ...params, page });
  };

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        const data = await profileApi.getWorks({
          ...params,
        });
        setState({
          ...data,
          total: data.total,
          items: data.items,
        });
      } finally {
        setLoading(false);
      }
    };
    load();
  }, []);

  return (
    <Spinner loading={loading}>
      <StoryListMain
        page={params.page!}
        page_size={params.page_size!}
        {...state}
        onPageChange={onPageChange}
        allowAddLibrary={!isSelf}
      />
    </Spinner>
  );
}
