import { Outlet } from "react-router-dom";
import Header from "./Header";
import AutoScrollToTop from "@/components/AutoScrollTop";
import Footer from "../components/Footer";

export default function PortalLayout() {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="mb-8 flex-1">
        <Outlet />
        <AutoScrollToTop />
      </div>
      <Footer />
    </div>
  );
}
