import { AuthContext } from "@/context";
import { useContext } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";

interface Props {
  component: React.FC<any> | React.ComponentClass<any>;
  [key: string]: any;
}
let readList: Array<{ id: string; time: number }> = [];
try {
  readList = JSON.parse(localStorage.getItem("readList") || "[]");
} catch {}

export default function ReadLimitGuard(props: Props) {
  const params = useParams();
  let location = useLocation();
  const { isLogin } = useContext(AuthContext);
  const limit = 10; // 未登录用户每日阅读限制

  if (!isLogin) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const todayTs = Math.floor(today.getTime() / 1e3);
    let lastLength = readList.length;
    readList = readList.filter((item) => item.time > todayTs);
    let isChanged = lastLength !== readList.length;

    if (!params.part_id || (readList.length >= limit && !readList.find((item) => item.id === params.part_id))) {
      if (isChanged) {
        localStorage.setItem("readList", JSON.stringify(readList));
      }
      return <Navigate to="/" state={{ from: `${location.pathname}${location.search}${location.hash}` }} replace />;
    }

    if (!readList.find((item) => item.id === params.part_id)) {
      readList.unshift({ id: params.part_id!, time: Math.floor(Date.now() / 1e3) });
      isChanged = true;
    }

    if (isChanged) {
      localStorage.setItem("readList", JSON.stringify(readList));
    }
  }

  return <props.component {...props} />;
}