/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

interface Props {
  options: Array<{ value: string; label: string }>;
  value?: string;
  onChange: (value: string) => void;
}

export default function StoryListOrder(props: Props) {
  const selectedCss = (value: string) => (value === props.value ? "background: var(--bg-color-highlight); border-color: var(--bg-color-highlight)" : "");

  return (
    <div
      className="order-content"
      css={css`
        padding-top: 1rem;
        margin-bottom: 1rem;
      `}
    >
      {props.options.map(item => (
        <span
          onClick={() => props.onChange(item.value)}
          key={item.value}
          css={css`
            display: inline-block;
            color: var(--primary-color);
            background: #fff;
            border: 1px solid var(--border-color-default);
            line-height: 20px;
            padding: 7px 16px;
            text-align: center;
            margin-right: 24px;
            border-radius: 20px;
            cursor: pointer;
            ${selectedCss(item.value)}
          `}
        >
          {item.label}
        </span>
      ))}
    </div>
  );
}
