export const regionOptions = [
  {
      "value": "Jordan",
      "label": "regions.Jordan"
  },
  {
      "value": "Northern Mariana Islands",
      "label": "regions.NorthernMarianaIslands"
  },
  {
      "value": "Serbia",
      "label": "regions.Serbia"
  },
  {
      "value": "Andorra",
      "label": "regions.Andorra"
  },
  {
      "value": "Turks and Caicos Islands",
      "label": "regions.TurksAndCaicosIslands"
  },
  {
      "value": "Bermuda",
      "label": "regions.Bermuda"
  },
  {
      "value": "Antarctica",
      "label": "regions.Antarctica"
  },
  {
      "value": "Bolivia",
      "label": "regions.Bolivia"
  },
  {
      "value": "Libya",
      "label": "regions.Libya"
  },
  {
      "value": "Mali",
      "label": "regions.Mali"
  },
  {
      "value": "Armenia",
      "label": "regions.Armenia"
  },
  {
      "value": "Mauritius",
      "label": "regions.Mauritius"
  },
  {
      "value": "Maldives",
      "label": "regions.Maldives"
  },
  {
      "value": "American Samoa",
      "label": "regions.AmericanSamoa"
  },
  {
      "value": "North Macedonia",
      "label": "regions.NorthMacedonia"
  },
  {
      "value": "Ethiopia",
      "label": "regions.Ethiopia"
  },
  {
      "value": "Greenland",
      "label": "regions.Greenland"
  },
  {
      "value": "Iraq",
      "label": "regions.Iraq"
  },
  {
      "value": "Guatemala",
      "label": "regions.Guatemala"
  },
  {
      "value": "Trinidad and Tobago",
      "label": "regions.TrinidadAndTobago"
  },
  {
      "value": "Peru",
      "label": "regions.Peru"
  },
  {
      "value": "Suriname",
      "label": "regions.Suriname"
  },
  {
      "value": "Sweden",
      "label": "regions.Sweden"
  },
  {
      "value": "New Caledonia",
      "label": "regions.NewCaledonia"
  },
  {
      "value": "French Polynesia",
      "label": "regions.FrenchPolynesia"
  },
  {
      "value": "Benin",
      "label": "regions.Benin"
  },
  {
      "value": "Estonia",
      "label": "regions.Estonia"
  },
  {
      "value": "Falkland Islands",
      "label": "regions.FalklandIslands"
  },
  {
      "value": "Guam",
      "label": "regions.Guam"
  },
  {
      "value": "Zimbabwe",
      "label": "regions.Zimbabwe"
  },
  {
      "value": "Slovakia",
      "label": "regions.Slovakia"
  },
  {
      "value": "Netherlands",
      "label": "regions.Netherlands"
  },
  {
      "value": "Ecuador",
      "label": "regions.Ecuador"
  },
  {
      "value": "Saudi Arabia",
      "label": "regions.SaudiArabia"
  },
  {
      "value": "United Arab Emirates",
      "label": "regions.UnitedArabEmirates"
  },
  {
      "value": "Afghanistan",
      "label": "regions.Afghanistan"
  },
  {
      "value": "United States Minor Outlying Islands",
      "label": "regions.UnitedStatesMinorOutlyingIslands"
  },
  {
      "value": "Central African Republic",
      "label": "regions.CentralAfricanRepublic"
  },
  {
      "value": "Panama",
      "label": "regions.Panama"
  },
  {
      "value": "Vatican City",
      "label": "regions.VaticanCity"
  },
  {
      "value": "Syria",
      "label": "regions.Syria"
  },
  {
      "value": "Vanuatu",
      "label": "regions.Vanuatu"
  },
  {
      "value": "Honduras",
      "label": "regions.Honduras"
  },
  {
      "value": "Kiribati",
      "label": "regions.Kiribati"
  },
  {
      "value": "Chile",
      "label": "regions.Chile"
  },
  {
      "value": "Burkina Faso",
      "label": "regions.BurkinaFaso"
  },
  {
      "value": "Saint Kitts and Nevis",
      "label": "regions.SaintKittsAndNevis"
  },
  {
      "value": "Saint Barthélemy",
      "label": "regions.SaintBarthélemy"
  },
  {
      "value": "Mexico",
      "label": "regions.Mexico"
  },
  {
      "value": "China",
      "label": "regions.China"
  },
  {
      "value": "Timor-Leste",
      "label": "regions.TimorLeste"
  },
  {
      "value": "British Indian Ocean Territory",
      "label": "regions.BritishIndianOceanTerritory"
  },
  {
      "value": "South Sudan",
      "label": "regions.SouthSudan"
  },
  {
      "value": "Eswatini",
      "label": "regions.Eswatini"
  },
  {
      "value": "Uzbekistan",
      "label": "regions.Uzbekistan"
  },
  {
      "value": "Indonesia",
      "label": "regions.Indonesia"
  },
  {
      "value": "Paraguay",
      "label": "regions.Paraguay"
  },
  {
      "value": "Monaco",
      "label": "regions.Monaco"
  },
  {
      "value": "Grenada",
      "label": "regions.Grenada"
  },
  {
      "value": "Croatia",
      "label": "regions.Croatia"
  },
  {
      "value": "Poland",
      "label": "regions.Poland"
  },
  {
      "value": "Bosnia and Herzegovina",
      "label": "regions.BosniaAndHerzegovina"
  },
  {
      "value": "Canada",
      "label": "regions.Canada"
  },
  {
      "value": "Portugal",
      "label": "regions.Portugal"
  },
  {
      "value": "Brazil",
      "label": "regions.Brazil"
  },
  {
      "value": "Mauritania",
      "label": "regions.Mauritania"
  },
  {
      "value": "Israel",
      "label": "regions.Israel"
  },
  {
      "value": "Curaçao",
      "label": "regions.Curaçao"
  },
  {
      "value": "Brunei",
      "label": "regions.Brunei"
  },
  {
      "value": "Angola",
      "label": "regions.Angola"
  },
  {
      "value": "Malta",
      "label": "regions.Malta"
  },
  {
      "value": "Belarus",
      "label": "regions.Belarus"
  },
  {
      "value": "Turkey",
      "label": "regions.Turkey"
  },
  {
      "value": "Finland",
      "label": "regions.Finland"
  },
  {
      "value": "Gibraltar",
      "label": "regions.Gibraltar"
  },
  {
      "value": "Bhutan",
      "label": "regions.Bhutan"
  },
  {
      "value": "Spain",
      "label": "regions.Spain"
  },
  {
      "value": "Venezuela",
      "label": "regions.Venezuela"
  },
  {
      "value": "Qatar",
      "label": "regions.Qatar"
  },
  {
      "value": "Czechia",
      "label": "regions.Czechia"
  },
  {
      "value": "Kuwait",
      "label": "regions.Kuwait"
  },
  {
      "value": "Montenegro",
      "label": "regions.Montenegro"
  },
  {
      "value": "Bouvet Island",
      "label": "regions.BouvetIsland"
  },
  {
      "value": "India",
      "label": "regions.India"
  },
  {
      "value": "New Zealand",
      "label": "regions.NewZealand"
  },
  {
      "value": "Jamaica",
      "label": "regions.Jamaica"
  },
  {
      "value": "Cayman Islands",
      "label": "regions.CaymanIslands"
  },
  {
      "value": "San Marino",
      "label": "regions.SanMarino"
  },
  {
      "value": "Republic of the Congo",
      "label": "regions.RepublicOfTheCongo"
  },
  {
      "value": "Pakistan",
      "label": "regions.Pakistan"
  },
  {
      "value": "France",
      "label": "regions.France"
  },
  {
      "value": "Kazakhstan",
      "label": "regions.Kazakhstan"
  },
  {
      "value": "Bahrain",
      "label": "regions.Bahrain"
  },
  {
      "value": "Fiji",
      "label": "regions.Fiji"
  },
  {
      "value": "Iceland",
      "label": "regions.Iceland"
  },
  {
      "value": "Myanmar",
      "label": "regions.Myanmar"
  },
  {
      "value": "Bangladesh",
      "label": "regions.Bangladesh"
  },
  {
      "value": "Philippines",
      "label": "regions.Philippines"
  },
  {
      "value": "Equatorial Guinea",
      "label": "regions.EquatorialGuinea"
  },
  {
      "value": "Ireland",
      "label": "regions.Ireland"
  },
  {
      "value": "Nepal",
      "label": "regions.Nepal"
  },
  {
      "value": "Yemen",
      "label": "regions.Yemen"
  },
  {
      "value": "South Korea",
      "label": "regions.SouthKorea"
  },
  {
      "value": "Denmark",
      "label": "regions.Denmark"
  },
  {
      "value": "Oman",
      "label": "regions.Oman"
  },
  {
      "value": "Saint Vincent and the Grenadines",
      "label": "regions.SaintVincentAndTheGrenadines"
  },
  {
      "value": "Eritrea",
      "label": "regions.Eritrea"
  },
  {
      "value": "Australia",
      "label": "regions.Australia"
  },
  {
      "value": "Iran",
      "label": "regions.Iran"
  },
  {
      "value": "El Salvador",
      "label": "regions.ElSalvador"
  },
  {
      "value": "Tanzania",
      "label": "regions.Tanzania"
  },
  {
      "value": "Guadeloupe",
      "label": "regions.Guadeloupe"
  },
  {
      "value": "Solomon Islands",
      "label": "regions.SolomonIslands"
  },
  {
      "value": "Kenya",
      "label": "regions.Kenya"
  },
  {
      "value": "Dominican Republic",
      "label": "regions.DominicanRepublic"
  },
  {
      "value": "Greece",
      "label": "regions.Greece"
  },
  {
      "value": "Guernsey",
      "label": "regions.Guernsey"
  },
  {
      "value": "Rwanda",
      "label": "regions.Rwanda"
  },
  {
      "value": "Saint Pierre and Miquelon",
      "label": "regions.SaintPierreAndMiquelon"
  },
  {
      "value": "Tuvalu",
      "label": "regions.Tuvalu"
  },
  {
      "value": "Taiwan",
      "label": "regions.Taiwan"
  },
  {
      "value": "Guyana",
      "label": "regions.Guyana"
  },
  {
      "value": "Seychelles",
      "label": "regions.Seychelles"
  },
  {
      "value": "North Korea",
      "label": "regions.NorthKorea"
  },
  {
      "value": "Botswana",
      "label": "regions.Botswana"
  },
  {
      "value": "Cambodia",
      "label": "regions.Cambodia"
  },
  {
      "value": "Barbados",
      "label": "regions.Barbados"
  },
  {
      "value": "Colombia",
      "label": "regions.Colombia"
  },
  {
      "value": "Ukraine",
      "label": "regions.Ukraine"
  },
  {
      "value": "Ivory Coast",
      "label": "regions.IvoryCoast"
  },
  {
      "value": "Nauru",
      "label": "regions.Nauru"
  },
  {
      "value": "Namibia",
      "label": "regions.Namibia"
  },
  {
      "value": "Chad",
      "label": "regions.Chad"
  },
  {
      "value": "Tonga",
      "label": "regions.Tonga"
  },
  {
      "value": "Argentina",
      "label": "regions.Argentina"
  },
  {
      "value": "Christmas Island",
      "label": "regions.ChristmasIsland"
  },
  {
      "value": "Niger",
      "label": "regions.Niger"
  },
  {
      "value": "Marshall Islands",
      "label": "regions.MarshallIslands"
  },
  {
      "value": "Costa Rica",
      "label": "regions.CostaRica"
  },
  {
      "value": "Ghana",
      "label": "regions.Ghana"
  },
  {
      "value": "Austria",
      "label": "regions.Austria"
  },
  {
      "value": "Saint Helena, Ascension and Tristan da Cunha",
      "label": "regions.SaintHelenaAscensionAndTristanDaCunha"
  },
  {
      "value": "Palestine",
      "label": "regions.Palestine"
  },
  {
      "value": "Cuba",
      "label": "regions.Cuba"
  },
  {
      "value": "Hungary",
      "label": "regions.Hungary"
  },
  {
      "value": "Micronesia",
      "label": "regions.Micronesia"
  },
  {
      "value": "Belize",
      "label": "regions.Belize"
  },
  {
      "value": "Bahamas",
      "label": "regions.Bahamas"
  },
  {
      "value": "Aruba",
      "label": "regions.Aruba"
  },
  {
      "value": "São Tomé and Príncipe",
      "label": "regions.SaoTomeAndPrincipe"
  },
  {
      "value": "Russia",
      "label": "regions.Russia"
  },
  {
      "value": "Luxembourg",
      "label": "regions.Luxembourg"
  },
  {
      "value": "Faroe Islands",
      "label": "regions.FaroeIslands"
  },
  {
      "value": "Nicaragua",
      "label": "regions.Nicaragua"
  },
  {
      "value": "Tunisia",
      "label": "regions.Tunisia"
  },
  {
      "value": "DR Congo",
      "label": "regions.DrCongo"
  },
  {
      "value": "Svalbard and Jan Mayen",
      "label": "regions.SvalbardAndJanMayen"
  },
  {
      "value": "South Africa",
      "label": "regions.SouthAfrica"
  },
  {
      "value": "Sierra Leone",
      "label": "regions.SierraLeone"
  },
  {
      "value": "Lesotho",
      "label": "regions.Lesotho"
  },
  {
      "value": "South Georgia",
      "label": "regions.SouthGeorgia"
  },
  {
      "value": "Italy",
      "label": "regions.Italy"
  },
  {
      "value": "Djibouti",
      "label": "regions.Djibouti"
  },
  {
      "value": "Heard Island and McDonald Islands",
      "label": "regions.HeardIslandAndMcdonaldIslands"
  },
  {
      "value": "Sri Lanka",
      "label": "regions.SriLanka"
  },
  {
      "value": "Saint Lucia",
      "label": "regions.SaintLucia"
  },
  {
      "value": "Samoa",
      "label": "regions.Samoa"
  },
  {
      "value": "Cocos (Keeling) Islands",
      "label": "regions.CocosKeelingIslands"
  },
  {
      "value": "Puerto Rico",
      "label": "regions.PuertoRico"
  },
  {
      "value": "Gabon",
      "label": "regions.Gabon"
  },
  {
      "value": "Turkmenistan",
      "label": "regions.Turkmenistan"
  },
  {
      "value": "Latvia",
      "label": "regions.Latvia"
  },
  {
      "value": "Senegal",
      "label": "regions.Senegal"
  },
  {
      "value": "Belgium",
      "label": "regions.Belgium"
  },
  {
      "value": "Moldova",
      "label": "regions.Moldova"
  },
  {
      "value": "Liechtenstein",
      "label": "regions.Liechtenstein"
  },
  {
      "value": "Malawi",
      "label": "regions.Malawi"
  },
  {
      "value": "Lebanon",
      "label": "regions.Lebanon"
  },
  {
      "value": "Mongolia",
      "label": "regions.Mongolia"
  },
  {
      "value": "Norway",
      "label": "regions.Norway"
  },
  {
      "value": "British Virgin Islands",
      "label": "regions.BritishVirginIslands"
  },
  {
      "value": "Cameroon",
      "label": "regions.Cameroon"
  },
  {
      "value": "Thailand",
      "label": "regions.Thailand"
  },
  {
      "value": "Nigeria",
      "label": "regions.Nigeria"
  },
  {
      "value": "Réunion",
      "label": "regions.Réunion"
  },
  {
      "value": "Cape Verde",
      "label": "regions.CapeVerde"
  },
  {
      "value": "Jersey",
      "label": "regions.Jersey"
  },
  {
      "value": "Algeria",
      "label": "regions.Algeria"
  },
  {
      "value": "Laos",
      "label": "regions.Laos"
  },
  {
      "value": "Caribbean Netherlands",
      "label": "regions.CaribbeanNetherlands"
  },
  {
      "value": "Azerbaijan",
      "label": "regions.Azerbaijan"
  },
  {
      "value": "Sint Maarten",
      "label": "regions.SintMaarten"
  },
  {
      "value": "Morocco",
      "label": "regions.Morocco"
  },
  {
      "value": "Åland Islands",
      "label": "regions.ÅlandIslands"
  },
  {
      "value": "Bulgaria",
      "label": "regions.Bulgaria"
  },
  {
      "value": "Burundi",
      "label": "regions.Burundi"
  },
  {
      "value": "Uganda",
      "label": "regions.Uganda"
  },
  {
      "value": "Kosovo",
      "label": "regions.Kosovo"
  },
  {
      "value": "Mozambique",
      "label": "regions.Mozambique"
  },
  {
      "value": "Georgia",
      "label": "regions.Georgia"
  },
  {
      "value": "United Kingdom",
      "label": "regions.UnitedKingdom"
  },
  {
      "value": "Wallis and Futuna",
      "label": "regions.WallisAndFutuna"
  },
  {
      "value": "Pitcairn Islands",
      "label": "regions.PitcairnIslands"
  },
  {
      "value": "Romania",
      "label": "regions.Romania"
  },
  {
      "value": "Vietnam",
      "label": "regions.Vietnam"
  },
  {
      "value": "French Southern and Antarctic Lands",
      "label": "regions.FrenchSouthernAndAntarcticLands"
  },
  {
      "value": "Western Sahara",
      "label": "regions.WesternSahara"
  },
  {
      "value": "Japan",
      "label": "regions.Japan"
  },
  {
      "value": "Egypt",
      "label": "regions.Egypt"
  },
  {
      "value": "Liberia",
      "label": "regions.Liberia"
  },
  {
      "value": "Lithuania",
      "label": "regions.Lithuania"
  },
  {
      "value": "Haiti",
      "label": "regions.Haiti"
  },
  {
      "value": "United States Virgin Islands",
      "label": "regions.UnitedStatesVirginIslands"
  },
  {
      "value": "Papua New Guinea",
      "label": "regions.PapuaNewGuinea"
  },
  {
      "value": "Hong Kong",
      "label": "regions.HongKong"
  },
  {
      "value": "Kyrgyzstan",
      "label": "regions.Kyrgyzstan"
  },
  {
      "value": "Palau",
      "label": "regions.Palau"
  },
  {
      "value": "Madagascar",
      "label": "regions.Madagascar"
  },
  {
      "value": "Gambia",
      "label": "regions.Gambia"
  },
  {
      "value": "Togo",
      "label": "regions.Togo"
  },
  {
      "value": "Tokelau",
      "label": "regions.Tokelau"
  },
  {
      "value": "Slovenia",
      "label": "regions.Slovenia"
  },
  {
      "value": "Singapore",
      "label": "regions.Singapore"
  },
  {
      "value": "French Guiana",
      "label": "regions.FrenchGuiana"
  },
  {
      "value": "United States",
      "label": "regions.UnitedStates"
  },
  {
      "value": "Montserrat",
      "label": "regions.Montserrat"
  },
  {
      "value": "Malaysia",
      "label": "regions.Malaysia"
  },
  {
      "value": "Germany",
      "label": "regions.Germany"
  },
  {
      "value": "Antigua and Barbuda",
      "label": "regions.AntiguaAndBarbuda"
  },
  {
      "value": "Saint Martin",
      "label": "regions.SaintMartin"
  },
  {
      "value": "Somalia",
      "label": "regions.Somalia"
  },
  {
      "value": "Macau",
      "label": "regions.Macau"
  },
  {
      "value": "Albania",
      "label": "regions.Albania"
  },
  {
      "value": "Mayotte",
      "label": "regions.Mayotte"
  },
  {
      "value": "Dominica",
      "label": "regions.Dominica"
  },
  {
      "value": "Zambia",
      "label": "regions.Zambia"
  },
  {
      "value": "Anguilla",
      "label": "regions.Anguilla"
  },
  {
      "value": "Cook Islands",
      "label": "regions.CookIslands"
  },
  {
      "value": "Martinique",
      "label": "regions.Martinique"
  },
  {
      "value": "Guinea",
      "label": "regions.Guinea"
  },
  {
      "value": "Comoros",
      "label": "regions.Comoros"
  },
  {
      "value": "Niue",
      "label": "regions.Niue"
  },
  {
      "value": "Switzerland",
      "label": "regions.Switzerland"
  },
  {
      "value": "Norfolk Island",
      "label": "regions.NorfolkIsland"
  },
  {
      "value": "Isle of Man",
      "label": "regions.IsleOfMan"
  },
  {
      "value": "Sudan",
      "label": "regions.Sudan"
  },
  {
      "value": "Tajikistan",
      "label": "regions.Tajikistan"
  },
  {
      "value": "Uruguay",
      "label": "regions.Uruguay"
  },
  {
      "value": "Cyprus",
      "label": "regions.Cyprus"
  },
  {
      "value": "Guinea-Bissau",
      "label": "regions.GuineaBissau"
  }
];
