import { memo, useState, ChangeEvent, useEffect } from "react";
import { Button } from "@mui/material";
import { getFileMd5 } from "@/utils/utils";
import * as writingApi from "@/api/writing";
import * as uploadApi from "@/api/upload";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import styles from "./StoryCover.module.css";
import config from "@/config/config";
import UploadIcon from "@mui/icons-material/Upload";

interface Props {
  isEdit?: boolean;
  cover?: string;
  story_id?: number;
  onCoverChange?: (cover: string) => void;
}

const SIZE_LIMIT = 1024 * 1024 * 2;

const StoryCover = (props: Props) => {
  const { t } = useTranslation();
  const [cover, setCover] = useState(props.cover || config.defaultImage);

  useEffect(() => {
    if (props.cover) {
      setCover(props.cover);
    }
  }, [props.cover]);

  const uploadImg = async (file: File) => {
    const md5 = await getFileMd5(file);

    const { path } = await uploadApi.uploadImage({
      file,
      md5,
    });
    return {
      path,
      md5,
    };
  };

  const updateCover = async (file: File) => {
    const { path } = await uploadImg(file);
    await writingApi.updateCover({ story_id: props.story_id!, cover: path });
    toast.success(t("writing.coverUpdated"));
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    if (file.size > SIZE_LIMIT) {
      toast.error(t("writing.coverSizeLimit"));
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const cover = e.target!.result;
      setCover(cover as string);
    };
    reader.readAsDataURL(file);

    if (props.isEdit) {
      await updateCover(file);
    } else {
      const { path } = await uploadImg(file);
      props.onCoverChange!(path);
    }
  };

  return (
    <div className={styles.cover}>
      <img src={cover} alt="" />
      <label className="mt-4 inline-block">
        <input hidden accept="image/*" type="file" onChange={handleFileChange} />
        <Button variant="contained" color="primary" component="span" size="large" startIcon={<UploadIcon />}>
          {t("writing.uploadCover")}
        </Button>
      </label>
      <p className="font-color-lightgrey text-base mt-1">{t("writing.uploadCoverTip")}</p>
    </div>
  );
};

export default memo(StoryCover);
