import { useLayoutEffect } from "react";
import { useLocation } from "react-router";

const AutoScrollToTop = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      // behavior: "smooth",
    });
  }, [location.pathname]);
  return null;
};

export default AutoScrollToTop;
