import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  FormLabel,
  OutlinedInput,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import * as writingApi from "@/api/writing";

interface Props {
  part_id: number;
  story_id: number;
  onClose: () => void;
}

export default function SetPriceDialog(props: Props) {
  const { t } = useTranslation();
  const [partTitle, setPartTitle] = useState("");

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      price: 0,
    },
  });

  const rules = {
    price: {
      required: t("writing.priceRequired"),
      max: { value: 1000000, message: t("writing.priceMax") },
    },
  };

  useEffect(() => {
    const load = async () => {
      const ret = await writingApi.getPart({
        story_id: props.story_id,
        part_id: props.part_id,
      });
      setValue("price", ret.price);
      setPartTitle(ret.title);
    };

    load();
  }, [props.part_id, props.part_id]);

  const onSubmit = async (params: { price: number }) => {
    await writingApi.setPartPrice({
      ...params,
      story_id: props.story_id,
      part_id: props.part_id,
    });
    toast.success(t("common.success"));
    props.onClose();
  };

  return (
    <Dialog open onClose={props.onClose} maxWidth="lg" scroll="paper">
      <DialogTitle>
        <div>{t("writing.setPrice")}</div>
      </DialogTitle>
      <form id="setPriceForm" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <DialogContent sx={{ width: 500 }}>
          <div className="mb-6">
            <FormControl className="w-full">
              <FormLabel sx={{ fontWeight: 500 }}>{t("common.chapter")}</FormLabel>
              <span className="text-base">{partTitle}</span>
            </FormControl>
          </div>
          <div className="mb-6">
            <Controller
              name="price"
              control={control}
              rules={rules.price}
              render={({ field }) => (
                <FormControl error={!!errors.price} className="w-full">
                  <FormLabel sx={{ fontWeight: 500 }} htmlFor="price">
                    {t("writing.priceLabel")}
                  </FormLabel>
                  <OutlinedInput
                    type="number"
                    id="price"
                    placeholder={t("writing.pricePlaceholder")!}
                    size="small"
                    {...field}
                  />
                  {errors.price && (
                    <FormHelperText error={!!errors.price}>{errors.price.message! as string}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </div>
        </DialogContent>
        <DialogActions sx={{ padding: "16px" }}>
          <Button variant="contained" color="info" onClick={props.onClose} sx={{ marginRight: "16px", width: "96px" }}>
            {t("common.cancel")}
          </Button>
          <Button variant="contained" type="submit" sx={{ width: "96px" }}>
            {t("common.confirm")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
