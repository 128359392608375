/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import { Pagination } from "@mui/material";
import { StoryEntity } from "@/entity/story";
import { formatCover } from "@/utils/format";
import AddToLibrary from "@/components/AddToLibrary";
import { ReactComponent as PartsIcon } from "@/assets/icons/parts-12.svg";
import { ReactComponent as AddIcon } from "@/assets/icons/add-12.svg";

interface Props {
  page?: number;
  total?: number;
  page_size?: number;
  items: StoryEntity[];
  allowAddLibrary?: boolean;
  onPageChange?: (page: number) => void;
  className?: string;
}
export default function StoryListMain({ page, page_size, total, items, onPageChange, allowAddLibrary = true, className = "" }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = (story: StoryEntity) => {
    navigate("/story/" + story.id);
  };

  return (
    <>
      <div className={"storyListMain clearfix -ml-4 min-h-[168px] " + className}>
        {items.map(item => (
          <div
            className="story-box float-left cursor-pointer mb-4 flex w-1/2"
            key={item.id}
            css={css`
              padding-left: 16px;
            `}
          >
            <div className="cover" onClick={() => handleClick(item)}>
              <img
                {...formatCover(item.cover, "small")}
                alt={item.title}
                css={css`
                  width: 126px;
                  height: 168px;
                `}
                className="rounded-md"
              />
            </div>
            <div className="flex-1 min-w-0 ml-3">
              <div className="tags mb-0.5 truncate" style={{ lineHeight: "18px" }}>
                {(item.tags || []).map(tag => (
                  <Link key={tag.id} className="tag text-xs mr-3 font-color-primary" to={`/tag/${tag.id}`}>
                    #{tag.name}
                  </Link>
                ))}
              </div>
              <Link to={"/story/" + item.id} className="block title truncate text-base font-medium">
                {item.title}
              </Link>
              <div className="my-1 text-xs">
                <span className="category font-color-grey mr-2">{item.category?.name}</span>
                <span className="author">{item.author?.username}</span>
              </div>
              <div className="descriptiom font-color-grey h-10 line-clamp-2 mb-8">{item.description}</div>
              <div
                className="action"
                css={css`
                  font-size: 12px;
                  line-height: 18px;
                `}
              >
                <span className="inline-block font-color-grey mr-6">
                  <PartsIcon
                    className="inline-block"
                    css={css`
                      vertical-align: top;
                      margin-right: 3px;
                      margin-top: 2px;
                    `}
                  />
                  <span>
                    {item.parts_count}
                    {t("common.chapters")}
                  </span>
                </span>
                {
                  allowAddLibrary && (
                    <AddToLibrary story_id={item.id}>
                      <span className="inline-block font-color-primary">
                        <AddIcon
                          className="inline-block"
                          css={css`
                            vertical-align: top;
                            margin-right: 3px;
                            margin-top: 2px;
                          `}
                        />
                        <span>{t("common.add")}</span>
                      </span>
                    </AddToLibrary>
                  )
                }
              </div>
            </div>
          </div>
        ))}
      </div>
      {
        page && page_size && onPageChange && (
        <div className="clearfix">
          <Pagination
            className="float-right"
            count={Math.ceil(total! / page_size)}
            page={page}
            variant="outlined"
            shape="rounded"
            color="primary"
            boundaryCount={2}
            onChange={(_, page) => onPageChange(page)}
          />
        </div>
        )
      }
    </>
  );
}
