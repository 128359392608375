import { Fragment, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Box, Button, MenuItem, MenuList, Typography } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import styles from "./StoryParts.module.css";
import { useTranslation } from "react-i18next";
import { PartEntity } from "@/entity/part";
import * as writingApi from "@/api/writing";
import Spinner from "@/components/Spinner";
import { ClickableRichTooltip } from "@/components/RichTooltip";
import StoryStat from "./StoryStat";
import { formatTime } from "@/utils/format";
import { useConfirm } from "@/hooks";
import { toast } from "react-toastify";
import SetPriceDialog from "./SetPriceDialog";

interface Props {
  story: {
    id: number;
    parts?: PartEntity[];
  };
  onReload: () => void;
}

const StoryParts = (props: Props) => {
  const { t } = useTranslation();
  const { confirm } = useConfirm();
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pricePart, setPricePart] = useState<PartEntity | null>(null);

  const handleNewPart = async () => {
    setLoading(true);
    try {
      const part = await writingApi.createPart({
        story_id: +params.story_id!,
        content: "",
      });

      navigate(`/writing/story/${params.story_id!}/part/${part.id}`);
    } finally {
      setLoading(false);
    }
  };

  const handlePreview = async (part: PartEntity) => {
    navigate(`/part/${part.story_id}/${part.id}`);
  };

  const handleUnpublish = async (part: PartEntity) => {
    if (
      await confirm({
        title: t("writing.unpublishConfirmTitle"),
        content: t("writing.unpublishConfirmMsg"),
      })
    ) {
      await writingApi.unpublishPart({ part_id: part.id, story_id: part.story_id });
      props.onReload();
      toast.success(t("writing.unpublishSuccess"));
    }
  };

  const handleDelete = async (part: PartEntity) => {
    if (
      await confirm({
        title: t("writing.deleteStoryConfirmTitle"),
        content: t("writing.deleteStoryConfirmMsg"),
      })
    ) {
      await writingApi.deletePart({ part_id: part.id, story_id: part.story_id });
      props.onReload();
      toast.success(t("writing.deletedSuccess"));
    }
  };

  const handleSetPrice = (part: PartEntity) => {
    setPricePart(part);
  };

  const getPartMenu = (part: PartEntity) => {
    const menuItems = [
      {
        label: t("common.preview"),
        onClick: handlePreview,
      },
      ...(part.is_published
        ? [
            {
              label: t("common.unpublish"),
              onClick: handleUnpublish,
            },
          ]
        : []),
      {
        label: t("writing.deletePart"),
        onClick: handleDelete,
      },
      {
        label: t("writing.setPrice"),
        onClick: handleSetPrice,
      },
    ].map((item) => [
      <MenuItem key={item.label} onClick={() => item.onClick(part)}>
        {item.label}
      </MenuItem>,
    ]);

    return (
      <MenuList
        dense
        sx={{
          minWidth: "160px"
        }}
      >
        {menuItems}
      </MenuList>
    );
  };

  return (
    <>
      {pricePart && (
        <SetPriceDialog story_id={pricePart.story_id} part_id={pricePart?.id} onClose={() => setPricePart(null)} />
      )}
      <Box className={styles.storyParts + " container"}>
        <Spinner loading={loading}></Spinner>
        <div className="header"></div>
        <dl className={styles.list}>
          <dt className={styles.listItem}>
            <Button
              onClick={handleNewPart}
              variant="contained"
              startIcon={<AddOutlinedIcon />}
              sx={{ paddingLeft: "40px", paddingRight: "40px" }}
            >
              {t("writing.newPart")}
            </Button>
          </dt>
          {props.story.parts!.map((part) => (
            <Fragment key={part.id}>
              <dd className={styles.listItem}>
                <div className={styles.listItemL}>
                  <Link className={styles.partLink} to={`/writing/story/${params.story_id!}/part/${part.id}`}>
                    <Typography variant="h3" className={styles.partTitle}>
                      {part.title}
                    </Typography>
                  </Link>
                  <div className="flex justify-between">
                    <div className={styles.partInfo}>
                      <span className={styles.partStatus}>
                        {part.is_published ? (
                          <span className={styles.published}>{t("common.published")}</span>
                        ) : (
                          <span className={styles.draft}>{t("common.draft")}</span>
                        )}
                      </span>
                      <span className="font-color-lightgrey">
                        {t("writing.updateTimeLabel")} {formatTime(part.update_time, true)}
                      </span>
                    </div>
                    <div className="mr-24">
                      <StoryStat {...part} />
                    </div>
                  </div>
                </div>
                <div className={styles.listItemR}>
                  <ClickableRichTooltip padding={0} content={getPartMenu(part)} placement="bottom-end">
                    <Button size="small" variant="outlined" color="info">
                      <MoreHorizIcon className="font-color-grey" />
                    </Button>
                  </ClickableRichTooltip>
                </div>
              </dd>
            </Fragment>
          ))}
        </dl>
      </Box>
    </>
  );
};
export default StoryParts;
