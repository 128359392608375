import { PropsWithChildren, useState } from "react";
import { ConfirmContext, IOptions } from "./ConfirmContext";
import Confirm from "./Confirm";

export default function ConfirmProvider(props: PropsWithChildren) {
  const [options, setOptions] = useState<null | IOptions>(null);

  return (
    <ConfirmContext.Provider value={{ options, setOptions }}>
			{props.children}
      <Confirm />
    </ConfirmContext.Provider>
  );
}
