import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import WorksPanel from "./WorksPanel";
// import ReadingListListPanel from "./ReadingListListPanel";

export default function WorksAndReadingLists() {
  const { t } = useTranslation();

  const [tab, setTab] = useState("works");
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  return (
    <div className="content-wrap">
      <TabContext value={tab}>
        <TabList sx={{ paddingLeft: 0 }} onChange={handleTabChange}>
          <Tab label={t("profile.works")} value="works" sx={{ fontSize: "20px", marginLeft: 0 }} />
          {/* <Tab label={t("library.readingList")} value="readingList" sx={{ fontSize: "20px", marginLeft: "48px" }} /> */}
        </TabList>
        <TabPanel value="works" sx={{ padding: 0, marginTop: '20px', minHeight: '400px' }}>
          <WorksPanel />
        </TabPanel>
        {/* <TabPanel value="readingList" sx={{ padding: 0, minHeight: '400px' }}>
          <ReadingListListPanel />
        </TabPanel> */}
      </TabContext>
    </div>
  );
}
