/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { KeyboardEvent, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { TextareaAutosize } from "@mui/material";
import * as writingApi from "@/api/writing";
import { useConfirm } from "@/hooks";
import PartEditNav from "./components/PartEditNav";
import PublishStoryDialog from "./components/PublishStoryDialog";

interface Props {}

export default function PartEdit(props: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { confirm } = useConfirm();
  const defaultTitle = t("writing.defaultPartTitle")!;
  const params = useParams();
  const delayTimeRef = useRef<number | null>(null);
  const [title, setTitle] = useState(defaultTitle);
  const [isPublished, setIsPublished] = useState(false);
  const [content, setContent] = useState("");
  const [part, setPart] = useState<Awaited<ReturnType<typeof writingApi.getPart>> | null>(null);
  const [btnLock, setBtnLock] = useState("");
  const [publishVisible, setPublishVisible] = useState(false);

  useEffect(() => {
    const fetchPart = async () => {
      const part = await writingApi.getPart({ story_id: +params?.story_id! || 0, part_id: +params?.part_id! || 0 });
      setTitle(part.title);
      setIsPublished(!!part.is_published);
      setContent(part.content || "");
      setPart(part);
    };
    fetchPart();
  }, [params.story_id, params.part_id]);

  useEffect(() => {
    if (part?.id && (part?.content !== content || part?.title !== title)) {
      if (delayTimeRef.current) {
        clearTimeout(delayTimeRef.current);
        delayTimeRef.current = null;
      }
      if (!isPublished) {
        delayTimeRef.current = window.setTimeout(() => {
          onSave();
          delayTimeRef.current = null;
        }, 1e3);
      }
    }
  }, [part, content, title, isPublished]);

  const handleTitleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
    if (event.currentTarget.value.length > 80 && event.key !== "Backspace" && event.key !== "Delete") {
      event.preventDefault();
    }
  };

  const onSave = async () => {
    setBtnLock("save");
    try {
      await writingApi.editPart({
        story_id: +params?.story_id! || 0,
        part_id: +params?.part_id! || 0,
        title,
        content,
      });
      setPart(Object.assign({}, part, { title, content }));
      if (isPublished) {
        backToList();
      }
    } finally {
      setBtnLock("");
    }
  };

  const onPublish = async () => {
    await onSave();
    setBtnLock("publish");
    setPublishVisible(true);
  };

  const cancelPublish = () => {
    setPublishVisible(false);
    setBtnLock("");
  };

  const afterStoryPublish = async () => {
    try {
      await writingApi.publishPart({
        story_id: +params?.story_id! || 0,
        part_id: +params?.part_id! || 0,
      });
      toast.success(t("writing.publishedSuccess")!);
      backToList();
    } finally {
      setBtnLock("");
    }
  };

  const onPreview = async () => {
    await onSave();
    navigate("/part/" + params?.story_id! + "/" + params?.part_id!);
  };

  const onCancel = async () => {
    if (part?.content !== content || part?.title !== title) {
      if (
        await confirm({
          title: t("writing.saveConfirmTitle"),
          content: t("writing.saveConfirmMsg"),
          cancelText: t("writing.saveConfirmCancel")!,
          confirmText: t("writing.saveConfirmOk")!,
        })
      ) {
        await onSave();
      } else {
        backToList();
      }
    } else {
      backToList();
    }
  };

  const onDelete = async () => {
    if (
      await confirm({
        title: t("writing.deleteConfirmTitle"),
        content: t("writing.deleteConfirmMsg"),
      })
    ) {
      await onDeleteConfirm();
    }
  };

  const onDeleteConfirm = async () => {
    setBtnLock("delete");
    try {
      await writingApi.deletePart({
        story_id: +params?.story_id! || 0,
        part_id: +params?.part_id! || 0,
      });
      toast.success(t("writing.deletedSuccess")!);
      backToList();
    } finally {
      setBtnLock("");
    }
  };

  const backToList = () => {
    navigate("/writing/story/" + params?.story_id! + "?active=parts");
  };

  const isValid = content.trim().length > 0;

  return (
    <>
      {part && (
        <PublishStoryDialog part={part} open={publishVisible} onClose={cancelPublish} onPublish={afterStoryPublish} />
      )}
      <PartEditNav
        story_id={part?.story_id!}
        part_id={part?.id!}
        storyTitle={part?.story_title}
        hasUnsavedChanges={part?.content !== content || part?.title !== title}
        isDelaySaving={!!delayTimeRef.current}
        wordsCount={content.length}
        partTitle={title}
        isValid={isValid}
        btnLock={btnLock}
        isPublished={isPublished}
        onPublish={onPublish}
        onSave={onSave}
        onPreview={onPreview}
        onCancel={onCancel}
        onDelete={onDelete}
        onBack={backToList}
      />
      <div
        css={css`
          max-width: 1100px;
          width: auto;
          margin: 0 auto;
          padding: 36px 90px;
          color: #222;
          background-color: #fff;
        `}
      >
        <TextareaAutosize
          css={css`
            width: 100%;
            text-align: center;
            margin-top: 0;
            text-align: center;
            border-bottom: 1px solid #eee;
            font-size: 32px;
            padding-bottom: 12px;
            margin-bottom: 12px;
            font-weight: 400;
            line-height: 60px;
            outline: 0;
            resize: none;
          `}
          value={title}
          placeholder={defaultTitle}
          onKeyDown={handleTitleKeyDown}
          onChange={(event) => setTitle(event.target.value)}
        ></TextareaAutosize>
        <TextareaAutosize
          placeholder={t("writing.chapterContentPlaceholder")!}
          css={css`
            width: 100%;
            min-height: 500px;
            font-size: 18px;
            font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
            line-height: 40px;
            word-wrap: break-word;
            outline: 0;
            &::placeholder {
              font-style: italic;
            }
          `}
          value={content}
          onChange={(event) => setContent(event.target.value)}
        />
      </div>
    </>
  );
}
