/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import BlockTitle from "./BlockTitle";

export default function EditorPicks(props: { className?: string }) {
  const className = props.className || "";
  const { t } = useTranslation();
  const items = [
    {
      id: 1,
      cover: "/example/1.png",
      title: "我可以召唤所有的神",
      category: {
        id: 10,
        name: "玄幻",
      },
      author: {
        id: 10,
        username: "Meng RuShen",
      },
      description:
        "那是 2169 年。世界末日终于到来了。全球变暖、丧尸、核战争，同时发生。只要一个临界点，世界就会崩溃。然后，企业界的霸主 Nova Corp 似乎说服了全球超级大国去做不可能的事情：让每个人都上网。他们做到了。每个人，无论贫富，都被带入了 VRMMORPG：World Online。一个一切皆有可能的奇幻世界。一个乌......",
    },
    {
      id: 2,
      cover: "/example/2.png",
      title: "游戏背后的老板",
      category: {
        id: 10,
        name: "玄幻",
      },
      author: {
        id: 10,
        username: "Meng RuShen",
      },
      description:
        "那是 2169 年。世界末日终于到来了。全球变暖、丧尸、核战争，同时发生。只要一个临界点，世界就会崩溃。然后，企业界的霸主 Nova Corp 似乎说服了全球超级大国去做不可能的事情：让每个人都上网。他们做到了。每个人，无论贫富，都被带入了 VRMMORPG：World Online。一个一切皆有可能的奇幻世界。一个乌......",
    },
    {
      id: 3,
      cover: "/example/3.png",
      title: "The Imbecile Lord Is Married",
      category: {
        id: 20,
        name: "玄幻",
      },
      author: {
        id: 10,
        username: "Meng Ru Shen Ji",
      },
      description:
        "那是 2169 年。世界末日终于到来了。全球变暖、丧尸、核战争，同时发生。只要一个临界点，世界就会崩溃。然后，企业界的霸主 Nova Corp 似乎说服了全球超级大国去做不可能的事情：让每个人都上网。他们做到了。每个人，无论贫富，都被带入了 VRMMORPG：World Online。一个一切皆有可能的奇幻世界。一个乌......",
    },
    {
      id: 4,
      cover: "/example/1.png",
      title: "我可以召唤所有的神",
      category: {
        id: 10,
        name: "玄幻",
      },
      author: {
        id: 10,
        username: "Meng RuShen",
      },
      description:
        "那是 2169 年。世界末日终于到来了。全球变暖、丧尸、核战争，同时发生。只要一个临界点，世界就会崩溃。然后，企业界的霸主 Nova Corp 似乎说服了全球超级大国去做不可能的事情：让每个人都上网。他们做到了。每个人，无论贫富，都被带入了 VRMMORPG：World Online。一个一切皆有可能的奇幻世界。一个乌......",
    },
    {
      id: 5,
      cover: "/example/2.png",
      title: "游戏背后的老板",
      category: {
        id: 10,
        name: "玄幻",
      },
      author: {
        id: 10,
        username: "Meng RuShen",
      },
      description:
        "那是 2169 年。世界末日终于到来了。全球变暖、丧尸、核战争，同时发生。只要一个临界点，世界就会崩溃。然后，企业界的霸主 Nova Corp 似乎说服了全球超级大国去做不可能的事情：让每个人都上网。他们做到了。每个人，无论贫富，都被带入了 VRMMORPG：World Online。一个一切皆有可能的奇幻世界。一个乌......",
    },
    {
      id: 6,
      cover: "/example/3.png",
      title: "The Imbecile Lord Is Married",
      category: {
        id: 20,
        name: "玄幻",
      },
      author: {
        id: 10,
        username: "Meng Ru Shen Ji",
      },
      description:
        "那是 2169 年。世界末日终于到来了。全球变暖、丧尸、核战争，同时发生。只要一个临界点，世界就会崩溃。然后，企业界的霸主 Nova Corp 似乎说服了全球超级大国去做不可能的事情：让每个人都上网。他们做到了。每个人，无论贫富，都被带入了 VRMMORPG：World Online。一个一切皆有可能的奇幻世界。一个乌......",
    },
  ];

  const switchList = () => {
    // todo
  };

  return (
    <div className={className + " editor-picks"}>
      <BlockTitle divider actionText={t("home.switch")!} onActionClick={switchList}>
        {t("home.editorPicks")}
      </BlockTitle>
      <ul className="clearfix -ml-6 -mt-2">
        {items.map(item => (
          <li key={item.id} className="float-left w-1/2 pl-6 mt-6">
            <div className="flex">
              <Link to={`/story/${item.id}`}>
                <img
                  src={item.cover}
                  alt={item.title}
                  css={css`
                    object-fit: cover;
                    width: 54px;
                    height: 72px;
                    border-radius: 3px;
                  `}
                />
              </Link>
              <div className="flex-1 ml-4 min-w-0">
                <Link to={`/story/${item.id}`}>
                  <Typography
                    variant="h4"
                    className="truncate"
                    sx={{
                      fontSize: "14px",
                      fontWeight: 500,
                      margin: "0",
                    }}
                  >
                    {item.title}
                  </Typography>
                </Link>
                <Link to={`/category/${item.category?.id}`}>
                  <Typography variant="body2" sx={{ fontSize: "12px", marginTop: "6px" }}>
                    {item.category?.name}
                  </Typography>
                </Link>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
