/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactComponent as ErrorIcon } from "@/assets/icons/error.svg";
import { ReactComponent as CloseIcon } from "@/assets/icons/close.svg";
import { useEffect, useRef } from "react";

interface Props {
  className?: string;
  open?: boolean;
  message?: string;
  duration?: number;
  onClose?: () => void;
}

export default function Alert({ message, className, onClose, open, duration }: Props) {
	const timer = useRef<number | null>(null);
	
  useEffect(() => {
    if (duration && open) {
      timer.current = window.setTimeout(() => {
				onClose?.();
      }, duration * 1e3);
    }

		return () => {
			if (timer.current) {
				clearTimeout(timer.current);
			}
		}
  }, [duration, open]);

	const handleClose = () => {
		if (timer.current) {
			clearTimeout(timer.current);
		}
		onClose?.();
	}

  return (
    <div
      className={`alert flex items-center ${className} ${!open ? " hidden" : ""}`}
      css={css`
        border: 1px solid #ffccc7;
        border-radius: 2px;
        background: #fff1f0;
        height: 2.5rem;
        line-height: 2.5rem;
        padding: 0 1rem;
        color: rgba(0, 0, 0, 0.65);
      `}
    >
      <ErrorIcon
        css={css`
          vertical-align: top;
        `}
      />
      <div className="truncate flex-1 mx-2">{message}</div>
      <CloseIcon className="cursor-pointer" onClick={handleClose} />
    </div>
  );
}
