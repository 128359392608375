import { Avatar } from "@mui/material";
import { PublicUserEntity } from "@/entity/user";

interface Props {
  user?: PublicUserEntity;
  size?: "s" | "m" | "l" | "xl";
	className?: string;
}

export default function UserAvatar({ user, className, size = "m" }: Props) {
  const sizeCssMap = {
    s: { width: 32, height: 32, bgcolor: "#02877B" },
    m: { width: 40, height: 40, bgcolor: "#02877B" },
    l: { width: 80, height: 80, bgcolor: "#02877B", fontSize: 32, fontWeight: 500 },
    xl: { width: 120, height: 120, bgcolor: "#02877B", fontSize: 48, fontWeight: 500 },
  };

  return (
    <Avatar sx={sizeCssMap[size]} src={user?.avatar} className={className}>
      {(!user?.avatar && user?.username!.charAt(0)?.toUpperCase()) || ""}
    </Avatar>
  );
}
