import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import * as readingListApi from "@/api/reading-list";

interface Props {
  open: boolean;
  onClose: () => void;
  onCreated: () => void;
}
export default function AddReadingListDialog(props: Props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [needValidate, setNeedValidate] = useState(false);

  const handleCreate = async () => {
    if (validate(true) != null) {
      setNeedValidate(true);
    }

    const trimedName = name.trim();

    try {
      setLoading(true);
      await readingListApi.add({
        name: trimedName,
      });
    } finally {
      setLoading(false);
    }
    props.onClose();
    props.onCreated();
    setName("");
  };

  const validate = (force = false) => {
    if (!needValidate && !force) {
      return null;
    }

    if (!name) {
      return {
        error: true,
        helperText: t("readingList.nameRequired"),
      };
    }

    if (name.trim().length > 40) {
      return {
        error: true,
        helperText: t("readingList.nameMaxLength"),
      };
    }

    return null;
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>{t("readingList.addReadingListTitle")}</DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{
            fontSize: "1.125rem",
            lineHeight: "1.625rem",
            marginBottom: "1rem",
          }}
          className="font-color-gray"
        >
          {t("readingList.readingListTip")}
        </DialogContentText>
        <TextField
          value={name}
          onChange={(e) => setName(e.target.value)}
          variant="outlined"
          autoFocus
          size="small"
          fullWidth
          {...validate()}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="inherit" onClick={props.onClose} sx={{ marginRight: "8px" }}>
          {t("common.close")}
        </Button>
        <LoadingButton loading={loading} variant="contained" onClick={handleCreate}>
          {t("common.create")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
