import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import SwitchLang from "@/components/SwitchLang";
import styles from "./Header.module.css";

export default function Header() {
  const { t } = useTranslation();

  return (
    <header className={styles.header}>
      <div className="content-wrap flex flex-row h-full justify-between">
        <a href="/" className={styles.logo}>
          <img src="/logo.png" alt="" />
        </a>
        <ul className={styles.nav}>
          <li>
            <NavLink className="active nav-link" to="/">
              {t("layout.nav.home")}
            </NavLink>
          </li>
          <li>
            <NavLink className="active nav-link" to="/support">
              {t("layout.nav.support")}
            </NavLink>
          </li>
        </ul>
        <SwitchLang className={`mr-4 ${styles.switchLang}`} />
      </div>
    </header>
  );
}
