import config from "@/config/config";
import { copyRightOptions, rechargeMethodOptions, regionOptions, subscribeStatusOptions } from "@/config/options";
import { SyntheticEvent } from "react";
import i18n from "@/utils/i18n";
import { t } from "i18next";

export function formatTime(time: string, onlyDate = false) {
  const date = new Date(time);
  if (date.toString() === "Invalid Date") {
    return "-";
  }

  return date.toLocaleDateString(i18n.language, {
    hour12: false,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    ...(!onlyDate
      ? {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }
      : null),
  });
}

export function handleCoverError(e: SyntheticEvent) {
  const target = e.currentTarget as HTMLImageElement;
  if (target.getAttribute("src") !== config.defaultImage) {
    target.src = config.defaultImage;
  }
}

export function formatCover(cover?: string, size: "normal" | "small" = "normal") {
  if (!cover) {
    return { src: config.defaultImage };
  }

  const sizeMap = {
    normal: "252w",
    small: "126w",
  };

  const dir = cover.split("/").slice(0, -1).join("/");
  const filename = cover.split("/").pop()!;

  const src =
    dir + "/" + filename.replace(/-[^.]+/, `-${sizeMap[size]}${/* window.devicePixelRatio >= 2 ? "@2x" :  */ ""}`);

  return {
    src,
    onError: handleCoverError,
  };
}

export function formatByOptions<T = any>(
  value: T | undefined,
  options: Array<{ label: string; value: T }>,
  useI18n = true
) {
  if (value == null) {
    return "-";
  }

  const label = options.find((item) => item.value === value)?.label;
  if (label) {
    return useI18n ? t(label) : label;
  }
  return "-";
}

function formatByOptionsFactory<T = any>(options: Array<{ label: string; value: T }>, useI18n = true) {
  return (value?: T) => formatByOptions(value, options, useI18n);
}

export const formatCopyright = formatByOptionsFactory(copyRightOptions, false);
export const formatLocation = formatByOptionsFactory(regionOptions);
export const formatRechargeMethod = formatByOptionsFactory(rechargeMethodOptions);
export const formatSubscribeStatus = formatByOptionsFactory(subscribeStatusOptions);