import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { StoryEntity } from "@/entity/story";
import * as readingApi from "@/api/reading";
import PageTitle from "@/components/PageTitle";
import Spinner from "@/components/Spinner";
import StoryListAside from "./components/StoryListAside";
import StoryListHead from "./components/StoryListHead";
import StoryListMain from "./components/StoryListMain";
import { StoryOrder } from "@/config/enum";
import { serializeQueryParams } from "@/utils/utils";
import StoryListTags from "./components/StoryListTags";

export default function Tag() {
  const query = useParams();
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const tag_ids = query?.tag_ids?.split("_").map((v) => +v) || void 0;
  const params = useMemo(
    () => ({
      page: +searchParams.get("page")! || 1,
      page_size: 10,
      category_id: +searchParams.get("category_id")! || void 0,
      order: (searchParams.get("order") as StoryOrder) || StoryOrder.UpdateTime,
      status: +searchParams.get("status")! || void 0,
      tag_ids,
    }),
    [query]
  );
  const [state, setState] = useState({
    total: 0,
    items: [] as StoryEntity[],
  });


  const handleTag = (new_tag_ids?: number[]) => {
    navigateWithParams({ page: 1 }, new_tag_ids);
  };

  const navigateWithParams = (updateParams: Partial<readingApi.StoryListParams>, new_tag_ids?: number[]) => {
    navigate(
      "/tag/" +
        (new_tag_ids ?? []).join("_") +
        "?" +
        serializeQueryParams({
          ...params,
          ...updateParams,
          tag_ids: void 0,
          page_size: void 0,
        })
    );
  };

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        const data = await readingApi.storyList(params);
        setState({
          total: data.total,
          items: data.items,
        });
      } finally {
        setLoading(false);
      }
    };
    load();
  }, [params]);

  return (
    <>
      {/* todo: title */}
      <PageTitle name="tag" />
      <Spinner loading={loading}>
        <div className="content-wrap flex justify-around pt-4">
          <StoryListAside
            value={params.category_id}
            onChange={(v?: number) => navigateWithParams({ ...params, category_id: v })}
          />
          <div className="main flex-1 ml-8">
            <StoryListTags value={params.tag_ids} onChange={handleTag} />
            <StoryListHead params={params} onParamsChange={(v) => navigateWithParams(v)} />
            <StoryListMain page={params.page!} page_size={params.page_size!} {...state} onPageChange={(page) => navigateWithParams({ ...params, page })} />
          </div>
        </div>
      </Spinner>
    </>
  );
}
