/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import NotifyList from "./NotifyList";
import { ReactComponent as NotifyIcon } from "@/assets/icons/notify.svg";

const navCss = css`
  display: flex;
  align-items: center;
  height: 40px;
  line-height: 40px;
  padding: 0 16px;
  cursor: pointer;
  margin-bottom: 16px;
  &:hover {
    color: var(--primary-color);
  }
  &.active {
    background: #f5f7f8;
    color: var(--primary-color);
    border-radius: 4px;
  }
`;

export default function NotifyMain() {
  const { t } = useTranslation();
  return (
    <>
      <div className="content-wrap">
        <div className="flex pt-8 gap-10">
          <ul className="w-[224px]">
            <li>
              <NavLink to="/notify" css={navCss}>
                <NotifyIcon className="mr-2" />
                {t("notify.title")}
              </NavLink>
            </li>
          </ul>
          <div className="flex-1 min-h-[60vh] ">
            <NotifyList />
          </div>
        </div>
      </div>
    </>
  );
}
