/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import BlockTitle from "./BlockTitle";
import EveryWeekRecSlider from "./EveryWeekRecSlider";

export default function EveryWeekRecommend() {
  const items = [
    {
      id: 1,
      cover: "/example/1.png",
      title: "我可以召唤所有的神",
      category: {
        id: 10,
        name: "玄幻",
      },
      author: {
        id: 10,
        username: "Meng RuShen",
      },
      description:
        "那是 2169 年。世界末日终于到来了。全球变暖、丧尸、核战争，同时发生。只要一个临界点，世界就会崩溃。然后，企业界的霸主 Nova Corp 似乎说服了全球超级大国去做不可能的事情：让每个人都上网。他们做到了。每个人，无论贫富，都被带入了 VRMMORPG：World Online。一个一切皆有可能的奇幻世界。一个乌......",
    },
    {
      id: 2,
      cover: "/example/2.png",
      title: "游戏背后的老板",
      category: {
        id: 10,
        name: "玄幻",
      },
      author: {
        id: 10,
        username: "Meng RuShen",
      },
      description:
        "那是 2169 年。世界末日终于到来了。全球变暖、丧尸、核战争，同时发生。只要一个临界点，世界就会崩溃。然后，企业界的霸主 Nova Corp 似乎说服了全球超级大国去做不可能的事情：让每个人都上网。他们做到了。每个人，无论贫富，都被带入了 VRMMORPG：World Online。一个一切皆有可能的奇幻世界。一个乌......",
    },
    {
      id: 3,
      cover: "/example/3.png",
      title: "The Imbecile Lord Is Married",
      category: {
        id: 20,
        name: "玄幻",
      },
      author: {
        id: 10,
        username: "Meng Ru Shen Ji",
      },
      description:
        "那是 2169 年。世界末日终于到来了。全球变暖、丧尸、核战争，同时发生。只要一个临界点，世界就会崩溃。然后，企业界的霸主 Nova Corp 似乎说服了全球超级大国去做不可能的事情：让每个人都上网。他们做到了。每个人，无论贫富，都被带入了 VRMMORPG：World Online。一个一切皆有可能的奇幻世界。一个乌......",
    },
    {
      id: 4,
      cover: "/example/1.png",
      title: "我可以召唤所有的神",
      category: {
        id: 10,
        name: "玄幻",
      },
      author: {
        id: 10,
        username: "Meng RuShen",
      },
      description:
        "那是 2169 年。世界末日终于到来了。全球变暖、丧尸、核战争，同时发生。只要一个临界点，世界就会崩溃。然后，企业界的霸主 Nova Corp 似乎说服了全球超级大国去做不可能的事情：让每个人都上网。他们做到了。每个人，无论贫富，都被带入了 VRMMORPG：World Online。一个一切皆有可能的奇幻世界。一个乌......",
    },
    {
      id: 5,
      cover: "/example/2.png",
      title: "游戏背后的老板",
      category: {
        id: 10,
        name: "玄幻",
      },
      author: {
        id: 10,
        username: "Meng RuShen",
      },
      description:
        "那是 2169 年。世界末日终于到来了。全球变暖、丧尸、核战争，同时发生。只要一个临界点，世界就会崩溃。然后，企业界的霸主 Nova Corp 似乎说服了全球超级大国去做不可能的事情：让每个人都上网。他们做到了。每个人，无论贫富，都被带入了 VRMMORPG：World Online。一个一切皆有可能的奇幻世界。一个乌......",
    },
    {
      id: 6,
      cover: "/example/3.png",
      title: "The Imbecile Lord Is Married",
      category: {
        id: 20,
        name: "玄幻",
      },
      author: {
        id: 10,
        username: "Meng Ru Shen Ji",
      },
      description:
        "那是 2169 年。世界末日终于到来了。全球变暖、丧尸、核战争，同时发生。只要一个临界点，世界就会崩溃。然后，企业界的霸主 Nova Corp 似乎说服了全球超级大国去做不可能的事情：让每个人都上网。他们做到了。每个人，无论贫富，都被带入了 VRMMORPG：World Online。一个一切皆有可能的奇幻世界。一个乌......",
    },
    {
      id: 11,
      cover: "/example/1.png",
      title: "我可以召唤所有的神",
      category: {
        id: 10,
        name: "玄幻",
      },
      author: {
        id: 10,
        username: "Meng RuShen",
      },
      description:
        "那是 2169 年。世界末日终于到来了。全球变暖、丧尸、核战争，同时发生。只要一个临界点，世界就会崩溃。然后，企业界的霸主 Nova Corp 似乎说服了全球超级大国去做不可能的事情：让每个人都上网。他们做到了。每个人，无论贫富，都被带入了 VRMMORPG：World Online。一个一切皆有可能的奇幻世界。一个乌......",
    },
    {
      id: 12,
      cover: "/example/2.png",
      title: "游戏背后的老板",
      category: {
        id: 10,
        name: "玄幻",
      },
      author: {
        id: 10,
        username: "Meng RuShen",
      },
      description:
        "那是 2169 年。世界末日终于到来了。全球变暖、丧尸、核战争，同时发生。只要一个临界点，世界就会崩溃。然后，企业界的霸主 Nova Corp 似乎说服了全球超级大国去做不可能的事情：让每个人都上网。他们做到了。每个人，无论贫富，都被带入了 VRMMORPG：World Online。一个一切皆有可能的奇幻世界。一个乌......",
    },
    {
      id: 13,
      cover: "/example/3.png",
      title: "The Imbecile Lord Is Married",
      category: {
        id: 20,
        name: "玄幻",
      },
      author: {
        id: 10,
        username: "Meng Ru Shen Ji",
      },
      description:
        "那是 2169 年。世界末日终于到来了。全球变暖、丧尸、核战争，同时发生。只要一个临界点，世界就会崩溃。然后，企业界的霸主 Nova Corp 似乎说服了全球超级大国去做不可能的事情：让每个人都上网。他们做到了。每个人，无论贫富，都被带入了 VRMMORPG：World Online。一个一切皆有可能的奇幻世界。一个乌......",
    },
    {
      id: 14,
      cover: "/example/1.png",
      title: "我可以召唤所有的神",
      category: {
        id: 10,
        name: "玄幻",
      },
      author: {
        id: 10,
        username: "Meng RuShen",
      },
      description:
        "那是 2169 年。世界末日终于到来了。全球变暖、丧尸、核战争，同时发生。只要一个临界点，世界就会崩溃。然后，企业界的霸主 Nova Corp 似乎说服了全球超级大国去做不可能的事情：让每个人都上网。他们做到了。每个人，无论贫富，都被带入了 VRMMORPG：World Online。一个一切皆有可能的奇幻世界。一个乌......",
    },
    {
      id: 15,
      cover: "/example/2.png",
      title: "游戏背后的老板",
      category: {
        id: 10,
        name: "玄幻",
      },
      author: {
        id: 10,
        username: "Meng RuShen",
      },
      description:
        "那是 2169 年。世界末日终于到来了。全球变暖、丧尸、核战争，同时发生。只要一个临界点，世界就会崩溃。然后，企业界的霸主 Nova Corp 似乎说服了全球超级大国去做不可能的事情：让每个人都上网。他们做到了。每个人，无论贫富，都被带入了 VRMMORPG：World Online。一个一切皆有可能的奇幻世界。一个乌......",
    },
    {
      id: 16,
      cover: "/example/3.png",
      title: "The Imbecile Lord Is Married",
      category: {
        id: 20,
        name: "玄幻",
      },
      author: {
        id: 10,
        username: "Meng Ru Shen Ji",
      },
      description:
        "那是 2169 年。世界末日终于到来了。全球变暖、丧尸、核战争，同时发生。只要一个临界点，世界就会崩溃。然后，企业界的霸主 Nova Corp 似乎说服了全球超级大国去做不可能的事情：让每个人都上网。他们做到了。每个人，无论贫富，都被带入了 VRMMORPG：World Online。一个一切皆有可能的奇幻世界。一个乌......",
    },
    {
      id: 21,
      cover: "/example/1.png",
      title: "我可以召唤所有的神",
      category: {
        id: 10,
        name: "玄幻",
      },
      author: {
        id: 10,
        username: "Meng RuShen",
      },
      description:
        "那是 2169 年。世界末日终于到来了。全球变暖、丧尸、核战争，同时发生。只要一个临界点，世界就会崩溃。然后，企业界的霸主 Nova Corp 似乎说服了全球超级大国去做不可能的事情：让每个人都上网。他们做到了。每个人，无论贫富，都被带入了 VRMMORPG：World Online。一个一切皆有可能的奇幻世界。一个乌......",
    },
    {
      id: 22,
      cover: "/example/2.png",
      title: "游戏背后的老板",
      category: {
        id: 10,
        name: "玄幻",
      },
      author: {
        id: 10,
        username: "Meng RuShen",
      },
      description:
        "那是 2169 年。世界末日终于到来了。全球变暖、丧尸、核战争，同时发生。只要一个临界点，世界就会崩溃。然后，企业界的霸主 Nova Corp 似乎说服了全球超级大国去做不可能的事情：让每个人都上网。他们做到了。每个人，无论贫富，都被带入了 VRMMORPG：World Online。一个一切皆有可能的奇幻世界。一个乌......",
    },
    {
      id: 23,
      cover: "/example/3.png",
      title: "The Imbecile Lord Is Married",
      category: {
        id: 20,
        name: "玄幻",
      },
      author: {
        id: 10,
        username: "Meng Ru Shen Ji",
      },
      description:
        "那是 2169 年。世界末日终于到来了。全球变暖、丧尸、核战争，同时发生。只要一个临界点，世界就会崩溃。然后，企业界的霸主 Nova Corp 似乎说服了全球超级大国去做不可能的事情：让每个人都上网。他们做到了。每个人，无论贫富，都被带入了 VRMMORPG：World Online。一个一切皆有可能的奇幻世界。一个乌......",
    },
  ];
  const { t } = useTranslation();

  return (
    <div className="this-week">
      <BlockTitle>{t("home.everyWeekRecommend")}</BlockTitle>
      <div className="flex">
        <EveryWeekRecSlider items={items.slice(0, 3)} />
        <ul
          className="clearfix flex-1 min-w-0"
  
        >
          {items.slice(3).map((item, index) => (
            <li
              key={item.id}
              css={css`
                float: left;
                width: 110px;
                margin-left: 24px;
                margin-top: ${index >= 6 ? '26px' : 0}
              `}
            >
              <Link to={`/story/${item.id}`}>
                <img
                  src={item.cover}
                  alt={item.title}
                  css={css`
                    object-fit: cover;
                    width: 110px;
                    height: 147px;
                    border-radius: 5px;
                  `}
                />
              </Link>

              <Link to={`/story/${item.id}`}>
                <Typography
                  variant="h4"
                  className="line-clamp-2"
                  sx={{
                    fontSize: "1rem",
                    fontWeight: 500,
                    marginTop: "8px",
                    marginBottom: "6px",
                  }}
                >
                  {item.title}
                </Typography>
              </Link>
              <Link to={`/category/${item.category?.id}`}>
                <Typography variant="body2">{item.category?.name}</Typography>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
