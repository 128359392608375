import { ReadHistoryEntity } from "@/entity/read-history";
import $http from "@/utils/http";

export function list(params: ApiListParams): Promise<ApiListResult<ReadHistoryEntity>> {
  return $http.request({
    url: "readHistory/list",
    method: "get",
    params,
  });
}

export type AddBookmarkParams = Pick<ReadHistoryEntity, "story_id" | "part_id" | "is_free">;
export function add(params: AddBookmarkParams): Promise<ReadHistoryEntity> {
  return $http.request({
    url: "readHistory/add",
    method: "post",
    data: params,
  });
}

export type DeleteBookmarkParams = Pick<ReadHistoryEntity, "story_id" | "part_id">;
export function del(params: DeleteBookmarkParams): Promise<boolean> {
  return $http.request({
    url: "readHistory/delete",
    method: "post",
    data: params,
  });
}
