import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Drawer, Typography } from "@mui/material";
import { PartEntity } from "@/entity/part";
import * as readingApi from "@/api/reading";
import { StoryEntity } from "@/entity/story";
import { formatCover } from "@/utils/format";
import Spinner from "@/components/Spinner";
import { ReactComponent as SortIcon } from "@/assets/icons/sort.svg";
import { ReactComponent as LockIcon } from "@/assets/icons/lock.svg";
import { ReactComponent as UnlockIcon } from "@/assets/icons/unlock.svg";
import styles from "./Catalog.module.css";

interface Props {
  open: boolean;
  story: StoryEntity;
  part?: PartEntity | null;
  part_id?: number;
  onClose: () => void;
}

export default function Catalog(props: Props) {
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState("ASC");
  const [loadLock, setLoadLock] = useState(-1);
  const [parts, setParts] = useState<PartEntity[]>([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        const data = await readingApi.catalog({
          story_id: props.story.id,
        });
        setParts(data.items);
        setTotal(data.total);
      } finally {
        setLoading(false);
      }
    };

    if (props.open && (!loadLock || loadLock !== props.story.id)) {
      load();
      setLoadLock(props.story.id);
    }
  }, [props.story.id, props.open, loadLock]);

  useEffect(() => {
    if (props.part && props.part.is_paid !== parts.find((p) => p.id === props.part!.id)?.is_paid) {
      setParts((parts) =>
        parts.map((p) => {
          if (p.id === props.part!.id) {
            return {
              ...p,
              is_paid: props.part!.is_paid,
            };
          }
          return p;
        })
      );
    }
  }, [props.part]);

  const toggleSort = () => {
    setSort(sort === "ASC" ? "DESC" : "ASC");
  };

  const renderLockIcon = (part: PartEntity) => {
    if (part.is_free) {
      return;
    }

    if (part.is_paid) {
      return <UnlockIcon className={styles.itemLock} />;
    }

    return <LockIcon className={styles.itemLock} />;
  };

  return (
    <Drawer
      anchor="right"
      open={props.open}
      onClose={() => props.onClose()}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <Spinner loading={loading} className="h-full">
        <div className={styles.drawerContent}>
          <div className={styles.header}>
            <div className={styles.storyInfo}>
              <img className={styles.storyCover} {...formatCover(props.story.cover, "small")} alt="" />
              <div>
                <Typography className={styles.storyTitle + " line-clamp-2"} variant="h3">
                  {props.story.title}
                </Typography>
                <Typography className={styles.storyAuthor} variant="h5">
                  {props.story.author?.username}
                </Typography>
              </div>
            </div>
            <div className={styles.sorterContainer}>
              <span className={styles.sorter + " " + (sort === "DESC" ? "desc" : "asc")}>
                <SortIcon onClick={toggleSort} />
              </span>
            </div>
          </div>
          <ol className={styles.list}>
            {(sort === "ASC" ? parts : parts.slice(0).reverse()).map((p, index) => (
              <li
                key={p.id}
                className={
                  styles.item +
                  " " +
                  (!!props.part_id && p.id === props.part_id ? styles.selected : "") +
                  " " +
                  (!p.is_free && !p.is_paid ? styles.locked : "")
                }
              >
                <Link to={`/part/${p.story_id}/${p.id}`} onClick={props.onClose}>
                  <div className={styles.itemInner}>
                    <div className={styles.itemContent}>
                      <span className={styles.itemIndex}>{sort === "ASC" ? index + 1 : total - index}</span>
                      <Typography className="inline-block flex-1 min-w-0 truncate pr-2" variant="body1">
                        {p.title}
                      </Typography>
                    </div>
                    {renderLockIcon(p)}
                  </div>
                </Link>
              </li>
            ))}
          </ol>
        </div>
      </Spinner>
    </Drawer>
  );
}
