import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigateLogin } from "@/hooks";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import * as readingApi from "@/api/reading";
import { AuthContext } from "@/context";
import { PartEntity } from "@/entity/part";
import { ReactComponent as SortIcon } from "@/assets/icons/sort.svg";
import { ReactComponent as LockIcon } from "@/assets/icons/lock.svg";
import { ReactComponent as UnlockIcon } from "@/assets/icons/unlock.svg";
import styles from "./PartMenu.module.css";
import Spinner from "@/components/Spinner";

interface Props {
  story_id: number;
  part_id?: number;
}
export default function PartMenu(props: Props) {
  const { navigateLogin } = useNavigateLogin();
  const { isLogin } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState("ASC");
  const [hasMore, setHasMore] = useState(false);
  const [parts, setParts] = useState<PartEntity[]>([]);
  const [total, setTotal] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        const data = await readingApi.catalog({
          story_id: props.story_id,
        });
        if (data.total > data.items.length) {
          setHasMore(true);
        }
        setParts(data.items);
        setTotal(data.total);
      } finally {
        setLoading(false);
      }
    };
    load();
  }, [props.story_id]);

  const toggleSort = () => {
    setSort(sort === "ASC" ? "DESC" : "ASC");
  };

  const renderLockIcon = (part: PartEntity) => {
    if (part.is_free) {
      return;
    }

    if (part.is_paid) {
      return <UnlockIcon className={styles.itemLock} />;
    }

    return <LockIcon className={styles.itemLock} />;
  };

  const renderLatest = () => {
    const latest = parts[parts.length - 1];
    if (latest) {
      return (
        <div className="text-base">
          <strong className="font-medium">{t("reading.latestPublished")}</strong>
          <Link className="font-color-primary ml-4" to={`/part/${latest.story_id}/${latest.id}`}>
            {latest.title}
          </Link>
          <span className="font-color-grey ml-4">{latest.update_time}</span>
        </div>
      );
    }

    return <div></div>;
  };

  return (
    <Spinner loading={loading}>
      <div className="part-list min-h-[200px]">
        <div className={styles.header + " text-base"}>
          <div>{renderLatest()} </div>
          <span className={styles.sorter + " " + (sort === "DESC" ? "desc" : "asc")}>
            <SortIcon onClick={toggleSort} />
          </span>
        </div>
        <ol className={styles.list + " clearfix"}>
          {(sort === "ASC" ? parts : parts.slice(0).reverse()).map((p, index) => (
            <li
              key={p.id}
              className={styles.item + " " + (!!props.part_id && p.id === props.part_id ? styles.selected : "")}
            >
              <Link to={`/part/${p.story_id}/${p.id}`}>
                <div className={styles.itemInner}>
                  <span className={styles.itemIndex + (!p.is_free && !p.is_paid ? " font-color-grey" : "")}>
                    {sort === "ASC" ? index + 1 : total - index}
                  </span>
                  <div className={styles.itemContent}>
                    <div className={"text-base mb-1 truncate pr-2" + (!p.is_free && !p.is_paid ? " font-color-grey" : "")}>
                      {p.title}
                    </div>
                    <div className="time font-color-grey text-xs">{p.update_time}</div>
                  </div>
                  {renderLockIcon(p)}
                </div>
              </Link>
            </li>
          ))}
        </ol>
        {!isLogin && hasMore && (
          <div className={styles.buttonWrap}>
            <Button
              className={styles.loginButton}
              color="primary"
              variant="contained"
              size="large"
              onClick={navigateLogin}
            >
              {t("reading.getMoreAfterLogin")}
            </Button>
          </div>
        )}
      </div>
    </Spinner>
  );
}
