import { useTranslation } from "react-i18next";
import BlockTitle from "./BlockTitle";
import ThisWeekRecSlider from "./ThisWeekRecSlider";

export default function ThisWeekRecommend() {
  const items = [
    {
      id: 1,
      cover: "/example/1.png",
      title: "我可以召唤所有的神",
      description: "Before he could enter the afterlife,he was pulled away by aGod that wants him to fix hisworld.",
    },
    {
      id: 2,
      cover: "/example/2.png",
      title: "游戏背后的老板",
      description: "Before he could enter the afterlife,he was pulled away by aGod that wants him to fix hisworld.",
    },
    {
      id: 3,
      cover: "/example/3.png",
      title: "The Imbecile Lord Is Married",
      description: "Before he could enter the afterlife,he was pulled away by aGod that wants him to fix hisworld.",
    },
  ];
  const { t } = useTranslation();

  return (
    <div className="this-week">
      <BlockTitle>{t("home.thisWeekRecommend")}</BlockTitle>
      <ThisWeekRecSlider items={items} />
    </div>
  );
}
