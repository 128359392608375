import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import styles from "./Header.module.css";
import { AuthContext } from "@/context";
import { useNavigateLogin } from "@/hooks";
import BrowsePopover from "./BrowsePopover";
import WritePoppover from "./WritePoppover";
import SwitchLang from "@/components/SwitchLang";
import UserInfoPopover from "../components/UserInfoPopover";
import UserAvatar from "@/components/UserAvatar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ReactComponent as BrowseIcon } from "@/assets/icons/nav-browse.svg";
import { ReactComponent as WriteIcon } from "@/assets/icons/nav-write.svg";
import { ReactComponent as LibraryIcon } from "@/assets/icons/nav-library.svg";
import { ReactComponent as SearchIcon } from "@/assets/icons/nav-search.svg";
import { ReactComponent as PreferencesIcon } from "@/assets/icons/preferences.svg";
import Preferences from "./Preferences";

const Header = () => {
  const { t } = useTranslation();
  const { loginPath, signupPath } = useNavigateLogin();
  const { isLogin, user } = useContext(AuthContext);
  const [preferencesOpen, setPreferencesOpen] = useState(false);

  return (
    <header className={styles.header}>
      <div className="content-wrap flex flex-row h-full justify-between">
        <Link to="/" className={styles.logo}>
          <img src="/logo.png" alt="" />
        </Link>
        <ul className={styles.nav}>
          <li>
            <BrowsePopover>
              <NavLink className={({ isActive }) => (isActive ? "active" : "") + " nav-link"} to="/category">
                <BrowseIcon />
                {t("layout.nav.browse")}
              </NavLink>
            </BrowsePopover>
          </li>
          <li>
            <WritePoppover>
              <NavLink className={({ isActive }) => (isActive ? "active" : "") + " nav-link"} to="/writing">
                <WriteIcon />
                {t("layout.nav.write")}
              </NavLink>
            </WritePoppover>
          </li>
          <li>
            <NavLink className={({ isActive }) => (isActive ? "active" : "") + " nav-link"} to="/library/">
              <LibraryIcon />
              {t("layout.nav.library")}
            </NavLink>
          </li>
          <li>
            <NavLink className={({ isActive }) => (isActive ? "active" : "") + " nav-link"} to="/search">
              <SearchIcon />
              {t("layout.nav.search")}
            </NavLink>
          </li>
        </ul>
        <SwitchLang className={styles.switchLang} />
        {isLogin && (
          <span className="mx-4">
            <PreferencesIcon
              className="inline-block cursor-pointer"
              title={t("layout.preferences.title")!}
              onClick={() => setPreferencesOpen(true)}
            />
          </span>
        )}
        <div className={styles.right}>
          {isLogin ? (
            <UserInfoPopover>
              <div className={styles.user}>
                <UserAvatar user={user!} size="s" />
                <ArrowDropDownIcon className="align-top" />
              </div>
            </UserInfoPopover>
          ) : (
            <>
              <NavLink className={styles.login} to={loginPath} replace>
                {t("common.login")}
              </NavLink>
              <NavLink className={styles.signup} to={signupPath} replace>
                {t("common.signup")}
              </NavLink>
            </>
          )}
        </div>
      </div>
      {preferencesOpen && <Preferences onClose={() => setPreferencesOpen(false)} />}
    </header>
  );
};
export default Header;
