import { ForwardedRef, forwardRef, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, TextareaAutosize } from "@mui/material";
import { AuthContext } from "@/context";
import { useNavigateLogin } from "@/hooks";
import * as commentApi from "@/api/comment";
import { CommentEntity } from "@/entity/comment";
import { ReactComponent as SendIcon } from "@/assets/icons/send.svg";
import styles from "./CommentInput.module.css";

interface Props {
  story_id: number;
  part_id: number;
  replyTo?: CommentEntity;
  onSuccess?: (comment: Awaited<ReturnType<typeof commentApi.createComment>>) => void;
}
const CommentInput = forwardRef((props: Props, ref: ForwardedRef<HTMLTextAreaElement>) => {
  const { t } = useTranslation();
  const { isLogin } = useContext(AuthContext);
  const { navigateLogin } = useNavigateLogin();
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    if (!value) return;

    try {
      setLoading(true);
      const comment = await commentApi.createComment({
        story_id: props.story_id,
        part_id: props.part_id,
        parent_id: props.replyTo?.parent_id || props.replyTo?.id || 0,
        reply_id: props.replyTo?.id || 0,
        reply_user_id: props.replyTo?.parent_id ? props.replyTo?.user?.id || 0 : 0,
        content: value.trim(),
      });
      if (props.onSuccess) {
        props.onSuccess(comment);
      }
      setValue("");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.wrap}>
      {!!props.replyTo && (
        <div className={styles.replyTo}>
          <span className="font-color-default">{t("reading.replying")}</span>
          <span className={styles.replyToUser}>@{props.replyTo.user!.username}:</span>
          <span className="font-color-grey">{props.replyTo.content}</span>
        </div>
      )}
      {isLogin ? (
        <div className={styles.box}>
          <TextareaAutosize
            className={styles.textarea}
            maxRows={3}
            placeholder={
              /* props.replyTo
                ? t("reading.replying") + " @" + props.replyTo.user?.username
                :  */ t("reading.commentPlaceholder")!
            }
            value={value}
            onChange={(e) => setValue(e.target.value)}
            ref={ref}
          ></TextareaAutosize>
          <button className={styles.button} disabled={!value || loading} onClick={handleClick}>
            <SendIcon className={styles.icon} />
          </button>
        </div>
      ) : (
        <div className="text-center -my-1">
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{
              width: "320px",
              height: "56px",
              fontWeight: 500,
              borderRadius: "28px",
              fontSize: "16px",
            }}
            onClick={navigateLogin}
          >
            {t("reading.commentAfterLogin")}
          </Button>
        </div>
      )}
    </div>
  );
});
export default CommentInput;
