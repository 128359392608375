import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Pagination, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import PrivDatePicker from "@/components/PrivDatePicker";
import Spinner from "@/components/Spinner";
import Empty from "@/components/Empty";
import { RechargeEntity } from "@/entity/recharge";
import * as fundApi from "@/api/fund";
import { formatRechargeMethod, formatTime } from "@/utils/format";
import { serializeQueryParams } from "@/utils/utils";
import dayjs, { Dayjs } from "dayjs";

export default function RechargeRecord() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState<Dayjs | null>(dayjs());
  const [searchParams] = useSearchParams();
  const page = +searchParams.get("page")! || 1;
  const page_size = 20;
  const [state, setState] = useState({
    total: 0,
    items: [] as RechargeEntity[],
  });

  const onPageChange = (page: number) => {
    navigate(
      "/fund/recharge/record?" +
        serializeQueryParams({
          page,
        })
    );
  };

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        const result = await fundApi.getRechargeRecords({
          page,
          page_size,
          month: month?.format("YYYY-MM"),
        });
        setState(result);
      } finally {
        setLoading(false);
      }
    };
    load();
  }, [month]);

  return (
    <>
      <div className="absolute right-0 -top-14">
        <PrivDatePicker
          openTo="month"
          views={["year", "month"]}
          slotProps={{
            textField: { size: "small", placeholder: t("fund.monthFilterPlaceholder")!, sx: { width: 192 } },
          }}
          disableFuture
          value={month}
          onChange={(newValue) => setMonth(newValue)}
        />
      </div>
      <Table sx={{ minWidth: "100%" }} size="medium">
        <TableHead>
          <TableRow>
            <TableCell>{t("fund.orderId")}</TableCell>
            <TableCell>{t("fund.rechargeWay")}</TableCell>
            <TableCell>{t("fund.rechargeAmount")}</TableCell>
            <TableCell>{t("fund.rechargePoints")}</TableCell>
            <TableCell>{t("fund.rechargeTime")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading || !state.items.length ? (
            <TableRow>
              <TableCell colSpan={5}>
                {loading ? <Spinner loading={loading} /> : <Empty />}
              </TableCell>
            </TableRow>
          ) : (
            state.items.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{formatRechargeMethod(row.method)}</TableCell>
                <TableCell>
                  {row.amount} {row.currency}
                </TableCell>
                <TableCell>{row.points}</TableCell>
                <TableCell>{formatTime(row.create_time)}</TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      <div className="clearfix mt-3">
        <Pagination
          className="float-right"
          count={Math.ceil(state.total / page_size)}
          page={page}
          variant="outlined"
          shape="rounded"
          color="primary"
          boundaryCount={2}
          onChange={(_, page) => onPageChange(page)}
        />
      </div>
    </>
  );
}
