/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";

export default function SensitiveChip() {
  const {t} = useTranslation();

  return (
    <span
      css={css`
        font-size: 12px;
        line-height: 18px;
        padding: 2px 9px;
        color: #fff;
        margin-right: 8px;
        background: #DF4545;
        border-radius: 11px;
      `}
    >{t('common.sensitive')}</span>
  );
}
