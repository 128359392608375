import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { languageOptions } from "@/config/i18n";
import * as userApi from "@/api/user";
import config from "@/config/config";
import i18n from "@/utils/i18n";

interface Props {
  className?: string;
}

export default function SwitchLang(props: Props) {
  const options = languageOptions.filter(item => config.siteLangs.includes(item.value));
  const lang = i18n.language;
  const langText = options.find(item => item.value === lang)?.label;

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();
  const open = Boolean(anchorEl);
  const handleAnchorClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(void 0);
  };
  const handleItemClick = (value: string) => () => {
    i18n.changeLanguage(value);
    userApi.changeLanguage({lang: value});
    handleClose();
  };

  return (
    <>
      <span
        id="i18n-button"
        aria-controls={open ? "i18n-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleAnchorClick}
        className={props.className + " h-12 cursor-pointer py-3 px-4 text-base"}
      >
        {langText}
        <ArrowDropDownIcon className="align-top" />
      </span>
      <Menu
        id="i18n-menu"
        MenuListProps={{
          "aria-labelledby": "i18n-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 0.5,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 30,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        disableAutoFocusItem
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        sx={{
          transform: "translateX(10px)",
        }}
      >
        {options.map(item => (
          <MenuItem onClick={handleItemClick(item.value)} key={item.value} selected={lang === item.value} sx={{
            width: '144px',
            height: '48px',
          }}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
