/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import BlockTitle from "./BlockTitle";

export default function Tags() {
  const items = [
    {
      id: 1,
      name: "异世界",
    },
    {
      id: 2,
      name: "玄幻",
    },
    {
      id: 3,
      name: "都市",
    },
    {
      id: 4,
      name: "游戏",
    },
    {
      id: 5,
      name: "八尾",
    },
    {
      id: 6,
      name: "历史",
    },
    {
      id: 11,
      name: "游戏体育",
    },
    {
      id: 12,
      name: "科幻悬疑",
    },
    {
      id: 13,
      name: "诸天无限",
    },
    {
      id: 14,
      name: "仙侠",
    },
    {
      id: 15,
      name: "异世界",
    },
    {
      id: 21,
      name: "游戏体育",
    },
    {
      id: 22,
      name: "科幻悬疑",
    },
    {
      id: 23,
      name: "诸天无限",
    },
    {
      id: 24,
      name: "仙侠",
    },
    {
      id: 25,
      name: "异世界",
    },
  ];
  const { t } = useTranslation();

  const handleMore = () => {
    // todo
  };

  return (
    <div className="hotStory">
      <BlockTitle divider actionText={t("common.more")!} onActionClick={handleMore}>
        {t("home.hotTags")}
      </BlockTitle>
      <ul
        className="clearfix"
        css={css`
          margin-left: -24px;
        `}
      >
        {items.map(item => (
          <li key={item.id} className="mt-6 float-left ml-6">
            <Link
              className="block font-color-primary"
              css={css`
                padding: 13.5px 16px 13.5px;
                background: #fff;
                border-radius: 5px;
                box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
              `}
              to={`/category/${item.id}`}
              key={item.id}
            >
              {item.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
