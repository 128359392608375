import { PropsWithChildren } from "react";
import { Typography, useTheme } from "@mui/material";

interface Props {
	divider?: boolean;
	actionText?: string;
	onActionClick?: () => void;
}
const BlockTitle = (props: PropsWithChildren<Props>) => {
  const theme = useTheme();
  return (
		<div className="relative">
			<Typography
				variant="h2"
				sx={{
					fontSize: "1.5rem",
					fontWeight: 500,
					lineHeight: '2rem',
					padding: "0",
					marginBottom: "1.5rem",
				}}
			>
				{props.children}
			</Typography>
			{props.divider && (
				<div
					style={{
						width: "100%",
						height: "1px",
						marginTop: '-1px',
						backgroundColor: theme.palette.divider,
					}}
				></div>
			)}
			{props.actionText && (
				<span className="absolute right-4 bottom-0 font-color-primary mb-4 cursor-pointer" onClick={props.onActionClick}>
					{props.actionText}
				</span>
			)}
		</div>
  );
};

export default BlockTitle