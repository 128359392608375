import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import { NotifyType } from "@/config/enum";
import * as commentApi from "@/api/comment";
import Spinner from "@/components/Spinner";
import CommentCard from "./CommentCard";
import CommentDrawer from "./CommentDrawer";
import { ReactComponent as CommentEmptyIcon } from "@/assets/icons/comment-empty.svg";
import { ReactComponent as EditIcon } from "@/assets/icons/edit.svg";
import styles from "./CommentFeatured.module.css";

interface Props {
  width?: string;
  title?: string;
  story_id: number;
  part_id: number;
}
export default function CommentFeatured(props: Props) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const notify_type = searchParams.has("notify_type") ? +searchParams.get("notify_type")! : 0;
  const notify_id = searchParams.has("notify_id") ? +searchParams.get("notify_id")! : 0;
  // 通过通知跳转过来的，需要打开评论
  const autoOpenComment = [NotifyType.CommentAutor, NotifyType.CommentReply].includes(notify_type) && !!notify_id;
  const [drawerOpen, setDrawerOpen] = useState(autoOpenComment);
  const [state, setState] = useState<Awaited<ReturnType<typeof commentApi.commentFeaturedList>>>({
    items: [],
    total: 0,
  });

  const showCommentDrawer = () => {
    setDrawerOpen(true);
  };

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        const data = await commentApi.commentFeaturedList({
          story_id: props.story_id,
          part_id: props.part_id,
          limit: 10,
        });
        setState(data);
      } finally {
        setLoading(false);
      }
    };
    load();
  }, [props.story_id, props.part_id]);

  return (
    <>
      <div className={styles.commentList} style={{ width: props.width }}>
        <div className={styles.commentHead}>
          <Typography
            variant="h3"
            sx={{
              fontSize: "24px",
              lineHeight: "35px",
              fontWeight: 500,
            }}
          >
            {props.title || t("common.comments")}
          </Typography>
          <span className={styles.commentCount}>{state.total}</span>
        </div>
        <Spinner loading={loading} />
        {state.items.length > 0 ? (
          state.items.map((item) => <CommentCard key={item.id} comment={item} />)
        ) : (
          <div className="text-center">
            <CommentEmptyIcon className="mb-4 mx-auto" />
            <Typography variant="body1">{t("reading.noComment")}</Typography>
          </div>
        )}
        <div className="py-8 text-center">
          <Button
            variant="contained"
            color="primary"
            onClick={showCommentDrawer}
            startIcon={<EditIcon className="mr-1" />}
            sx={{
              paddingLeft: "40px",
              paddingRight: "40px",
              borderRadius: "20px",
            }}
          >
            {t("reading.joinDiscussion")}
          </Button>
        </div>
      </div>
      <CommentDrawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        story_id={props.story_id}
        part_id={props.part_id}
      />
    </>
  );
}
