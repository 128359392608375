import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ConfirmContext } from "./ConfirmContext";

export default function Confirm() {
	const {options} = useContext(ConfirmContext);
	const {t} = useTranslation();

	if (!options) {
		return null;
	}

  return (
		<Dialog
			open={options.open}
			onClose={options.onCancel}
		>
			<DialogTitle>{options.title}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{options.content}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={options.onCancel} size="small">{options.cancelText || t("common.cancel")}</Button>
				<Button onClick={options.onConfirm} variant="contained" size="small"  autoFocus>
					{options.confirmText || t("common.confirm")}
				</Button>
			</DialogActions>
		</Dialog>
  );
}
