import { ComponentProps, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/en";
import "dayjs/locale/zh-cn";

const locales = ["en", "zh-cn"];
const i18nMap = {
  "en-US": "en",
  "zh-CN": "zh-cn",
};

type LocaleKey = (typeof locales)[number];

export default function PrivDatePicker<TData>(props: ComponentProps<typeof DatePicker<TData>>) {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const [locale, setLocale] = useState<LocaleKey>(i18nMap[lang as keyof typeof i18nMap] || "en");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setLocale(i18nMap[lang as keyof typeof i18nMap] || "en");
  }, [lang]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <DatePicker
        {...props}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        slotProps={{
          textField: { ...props.slotProps?.textField, onClick: () => setOpen(true) },
        }}
      />
    </LocalizationProvider>
  );
}
