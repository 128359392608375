import { ReadingListEntity } from "@/entity/reading-list";
import { StoryEntity } from "@/entity/story";
import { UserEntity } from "@/entity/user";
import $http from "@/utils/http";

export interface PublicInfoResult {
  id: number;
  username: string;
  avatar?: string;
  about?: string;
  location?: string;
  works_count: number;
  create_time: string;
  readingList_count: number;
}
export function getPublicInfo(params: {user_id: number}): Promise<PublicInfoResult> {
  return $http.request({
    url: "profile/publicInfo",
    method: "get",
    params,
  });
}

export interface PrivateInfoResult {
  id: number;
  email: string;
  username: string;
  location?: string;
  about?: string;
  avatar?: string;
  works_count: number;
  readingList_count: number;
  create_time: string;
}
export function getPrivateInfo(params?: {user_id: number}): Promise<PrivateInfoResult> {
  return $http.request({
    url: "profile/privateInfo",
    method: "get",
    params,
  });
}

export type UpdateInfoParams = Pick<UserEntity, "username" | "location" | "about" | "avatar">;
export function updateInfo(data: UpdateInfoParams): Promise<UserEntity> {
  return $http.request({
    url: "profile/updateInfo",
    method: "post",
    data,
  });
}

export interface GetWorksParams extends ApiListParams {
  user_id?: number;
}
export function getWorks(params: GetWorksParams): Promise<ApiListResult<StoryEntity>> {
  return $http.request({
    url: "profile/works",
    method: "get",
    params,
  });
}

export interface GetReadingListParams {
  user_id?: number;
}
export function getReadingList(params?: GetReadingListParams): Promise<ApiListResult<ReadingListEntity>> {
  return $http.request({
    url: "profile/readingList",
    method: "get",
    params,
  });
}