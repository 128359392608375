import { ReadingListEntity, ReadingListStoryEntity } from "@/entity/reading-list";
import $http from "@/utils/http";

export interface ReadingListListParams {
  with_story_ids?: boolean;
}
export function list(params?: ReadingListListParams): Promise<{ items: ReadingListEntity[] }> {
  return $http.request({
    url: "readinglist/list",
    method: "get",
    params: params,
  });
}

export interface AddReadingListParams {
  name: string;
  story_id?: number;
}
export function add(params: AddReadingListParams): Promise<ReadingListEntity> {
  return $http.request({
    url: "readinglist/add",
    method: "post",
    data: params,
  });
}

export function info(params: { id: number }): Promise<ReadingListEntity> {
  return $http.request({
    url: "readinglist/info",
    method: "get",
    params,
  });
}

export function updateName(params: { id: number; name: string }): Promise<ReadingListEntity> {
  return $http.request({
    url: "readinglist/updateName",
    method: "post",
    data: params,
  });
}

export interface DeleteReadingListParams {
  id: number;
}
export function del(params: DeleteReadingListParams): Promise<ReadingListEntity> {
  return $http.request({
    url: "readinglist/delete",
    method: "post",
    data: params,
  });
}

export interface ReadingListStoryListParams extends ApiListParams {
  reading_list_id: number;
}
export function storyList(params: ReadingListStoryListParams): Promise<{
  items: ReadingListStoryEntity[];
}> {
  return $http.request({
    url: "readinglist/storyList",
    method: "get",
    params,
  });
}

export type AddReadingListStoryParams = Pick<ReadingListStoryEntity, "reading_list_id" | "story_id">;
export function addStory(params: AddReadingListStoryParams): Promise<ReadingListStoryEntity> {
  return $http.request({
    url: "readinglist/addStory",
    method: "post",
    data: params,
  });
}

export type RemoveReadingListStoryParams = Pick<ReadingListStoryEntity, "reading_list_id" | "story_id">;
export function removeStory(params: RemoveReadingListStoryParams): Promise<ReadingListStoryEntity> {
  return $http.request({
    url: "readinglist/removeStory",
    method: "post",
    data: params,
  });
}
