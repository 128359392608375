/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AccordionSummary, AccordionDetails, Typography, TypographyProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import { ReactComponent as ExpandIcon } from "@/assets/icons/expand.svg";
import { CategoryEntity } from "@/entity/category";
import * as categoryApi from "@/api/category";

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    "&:not(.Mui-expanded)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    "&:before": {
      display: "none",
    },
    ".MuiAccordionSummary-root": {
      padding: 0,
    },
    ".MuiAccordionSummary-content": {
      margin: "16px 0"
    },
    ".MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      webkitTransform: "rotate(90deg)",
      mozTransform: "rotate(90deg)",
      msTransform: "rotate(90deg)",
      transform: "rotate(90deg)",
    },
    ".MuiAccordionDetails-root": {
      padding: 0,
    }
  })
);

const AccordionTitle = styled((props: TypographyProps) => <Typography variant="h3" {...props} />)(() => ({
  width: "80%",
  fontSize: "1.25rem",
  lineHeight: "1.8125rem",
  fontWeight: 500,
}));


interface Props {
  value?: number;
  onChange: (v?: number) => void;
}
export default function StoryListAside(props: Props) {
  const { t } = useTranslation();
  const [categoryItems, setCategoryItems] = useState<Array<CategoryEntity>>([]);

  const handleClick = (item: CategoryEntity) => {
    props.onChange(item.id);
  }

  useEffect(() => {
    const load = async () => {
      const { items } = await categoryApi.categoryList();
      setCategoryItems([{ id: 0, name: t("common.all") }, ...items]);
    };

    load();
  }, []);

  const [expanded, setExpanded] = useState<string | false>("story");
  const handleAccordChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <section
      className="storyListAside"
      css={css`
        width: 225px;
      `}
    >
      <Accordion expanded={expanded === "story"} onChange={handleAccordChange("story")}>
        <AccordionSummary expandIcon={<ExpandIcon />}>
          <AccordionTitle> {t("common.story")} </AccordionTitle>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            {categoryItems.map(item => (
              <li key={item.id} className="w-full" onClick={() => handleClick(item)}>
                <span
                  className="block truncate w-full cursor-pointer"
                  css={css`
                    font-size: 1rem;
                    border-radius: 5px;
                    padding: 0.5rem 2rem;
                    line-height: 1.5rem;
                    ${props.value === item.id || (!props.value && item.id === 0)
                      ? `
                        background: var(--primary-color); 
                        color: #fff; 
                        `
                      : ""}
                  `}
                >
                  {item.name}
                </span>
              </li>
            ))}
          </ul>
        </AccordionDetails>
      </Accordion>
        {/* <Accordion expanded={expanded === "comic"} onChange={handleAccordChange("comic")}>
        <AccordionSummary expandIcon={<ExpandIcon />}>
          <AccordionTitle> {t("common.comic")} </AccordionTitle>
        </AccordionSummary>
        <AccordionDetails>Comic Content</AccordionDetails>
      </Accordion> */}
    </section>
  );
}
