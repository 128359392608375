import $http from "@/utils/http";

export interface RemoteConfigObject {
	siteName: string;
}
export function getServerConfig(): Promise<RemoteConfigObject> {
	return $http.request({
		url: "config",
		method: "get",
	});
}