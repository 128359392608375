import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ClickableRichTooltip } from "@/components/RichTooltip";
import { MenuItem, MenuList } from "@mui/material";

interface Props {
  children: ReactElement;
}

export default function WritePoppover(props: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const content = (
    <MenuList>
      <MenuItem onClick={() => navigate("writing/story/new")}>{t("layout.nav.newStory")}</MenuItem>
      <MenuItem onClick={() => navigate("/writing")}>{t("layout.nav.myStories")}</MenuItem>
    </MenuList>
  );

  return (
    <ClickableRichTooltip content={content} placement="bottom-start" trigger="hover" padding={0}>
      {props.children}
    </ClickableRichTooltip>
  );
}
