/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { StoryEntity } from "@/entity/story";
import * as readingApi from "@/api/reading";
import CompleteChip from "@/components/CompleteChip";
import { ReactComponent as PartsIcon } from "@/assets/icons/parts-12.svg";
import { formatCover } from "@/utils/format";

export default function MaybeLike(props: { className?: string }) {
	const className = props.className || "";
  const { t } = useTranslation();
	const [items, setItems] = useState<StoryEntity[]>([]);

	useEffect(() => {
		const load = async () => {
			const data = await readingApi.maybeLike({
				count: 7
			});
			setItems(data.items);
		}

		load();
	}, []);

  return (
    <div className={className + " completed-stories"}>
      <ul className="flex -mx-4 mt-4">
        {items.map((item) => (
          <li key={item.id} className="mx-4 w-[calc(100%/7-32px)]">
            <Link to={`/story/${item.id}`}>
              <img
                {...formatCover(item.cover)}
                alt={item.title}
                css={css`
                  width: 100%;
                  border-radius: 5px;
                `}
              />
            </Link>
            <Link to={`/story/${item.id}`} className="block mt-2 mb-1 h-10">
              <Typography
                variant="h4"
                className="line-clamp-2"
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  margin: "0",
                }}
              >
                {item.title}
              </Typography>
            </Link>
            <div
              css={css`
                line-height: 18px;
								display: flex;
								flex-wrap: wrap;
              `}
            >
              <div className="parts mb-2 mr-2 whitespace-nowrap">
                <PartsIcon
                  className="inline-block"
                  css={css`
                    vertical-align: top;
                    margin-right: 3px;
                    margin-top: 3px;
                  `}
                />
                <span>
                  {item.parts_count}
                  {t("common.chapters")}
                </span>
              </div>
              <div className="mb-2">
                <CompleteChip is_completed={item.is_completed} />
              </div>
            </div>
            <Typography variant="body2" sx={{ fontSize: "12px", textAlign: "justify" }} className="line-clamp-3">
              {item.description}
            </Typography>
          </li>
        ))}
      </ul>
    </div>
  );
}
