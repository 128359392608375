import { useContext, useEffect, useRef, useState } from "react";
import { Link, Navigate, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Button, ClickAwayListener, Divider, Typography } from "@mui/material";
import { Errors, NotifyType } from "@/config/enum";
import { useNavigateLogin } from "@/hooks";
import { AuthContext } from "@/context";
import { PartEntity } from "@/entity/part";
import * as readingApi from "@/api/reading";
import * as fundApi from "@/api/fund";
import * as historyApi from "@/api/read-history";
import * as bookmarkApi from "@/api/bookmark";
import Spinner from "@/components/Spinner";
import PageTitle from "@/components/PageTitle";
import AddToLibraryButton from "./components/AddToLibraryButton";
import CommentDrawer from "./components/CommentDrawer";
import Catalog from "./components/Catalog";
import UserInfoPopover from "@/layout/components/UserInfoPopover";
import UserAvatar from "@/components/UserAvatar";
import Footer from "@/layout/components/Footer";
import Page404 from "../404";
import { themeOptions, fontSizeOptions } from "@/config/options";
import { ReactComponent as PaymentRequiredIcon } from "@/assets/icons/payment-required.svg";
import { ReactComponent as BCurrencyIcon } from "@/assets/icons/b-currency.svg";
import { ReactComponent as CatalogIcon } from "@/assets/icons/catalog.svg";
import { ReactComponent as CommentIcon } from "@/assets/icons/comment.svg";
import { ReactComponent as LikeIcon } from "@/assets/icons/like.svg";
import { ReactComponent as LikeActiveIcon } from "@/assets/icons/like-active.svg";
import { ReactComponent as BookmarkIcon } from "@/assets/icons/bookmark.svg";
import { ReactComponent as BookmarkActiveIcon } from "@/assets/icons/bookmark-active.svg";
import { ReactComponent as FontDnIcon } from "@/assets/icons/font-dn.svg";
import { ReactComponent as FontUpIcon } from "@/assets/icons/font-up.svg";
import { ReactComponent as ReadCountIcon } from "@/assets/icons/read-count-32.svg";
import { ReactComponent as CommentCountIcon } from "@/assets/icons/comment-count-32.svg";
import styles from "./ReadingPart.module.css";

const themeCacheKey = "read_theme";
const fontSizeCacheKey = "read_font_size";
export default function ReadingPart() {
  const navigate = useNavigate();
  const { loginPath, signupPath } = useNavigateLogin();
  const [searchParams] = useSearchParams();
  const notify_type = searchParams.has("notify_type") ? +searchParams.get("notify_type")! : 0;
  const notify_id = searchParams.has("notify_id") ? +searchParams.get("notify_id")! : 0;
  const { t } = useTranslation();
  const { isLogin, user } = useContext(AuthContext);
  const params = useParams();
  const delayTrack = useRef<number | null>(null);
  const [loadingCount, setLoadingCount] = useState(0);
  const [catalogOpen, setCatalogOpen] = useState(false);
  // 通过通知跳转过来的，需要打开评论
  const autoOpenComment = [NotifyType.CommentAutor, NotifyType.CommentReply].includes(notify_type) && !!notify_id;
  const [commentOpen, setCommentOpen] = useState(autoOpenComment);
  // 操作锁，避免重复操作
  const handleLock = useRef({
    like: false,
    bookmark: false,
  });
  const [like, setLike] = useState(false);
  const [bookmark, setBookmark] = useState(false);
  const [theme, setTheme] = useState(
    localStorage.getItem(themeCacheKey) || themeOptions.find((item) => item.default)!.value
  );
  const isDark = theme === "dark";
  const [themeActionOpen, setThemeActionOpen] = useState(false);
  const [fontSize, setFontSize] = useState(
    localStorage.getItem(fontSizeCacheKey) || fontSizeOptions.find((item) => item.default)!.value
  );
  const [fontSizeActionOpen, setFontSizeActionOpen] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [part, setPart] = useState<undefined | Awaited<ReturnType<typeof readingApi.getPart>>>();
  const [story, setStory] = useState<undefined | Awaited<ReturnType<typeof readingApi.getStory>>>();

  const trackHistory = async (part: PartEntity) => {
    await historyApi.add({
      story_id: part.story_id,
      part_id: part.id,
      is_free: part.is_free!,
    });
  };
  const loadStory = async () => {
    try {
      setLoadingCount((preCount) => preCount + 1);
      const data = await readingApi.getStory({
        story_id: +params.story_id!,
        with_percent: true,
        with_in_library: true,
      });
      setStory(data);
    } catch (err: any) {
      if (err === Errors.NotFound) {
        setNotFound(true);
      }
    } finally {
      setLoadingCount((preCount) => preCount + 1);
    }
  };
  const loadPart = async () => {
    try {
      setLoadingCount((preCount) => preCount + 1);
      const data = await readingApi.getPart({
        story_id: +params.story_id!,
        part_id: +params.part_id! || 0,
      });
      setPart(data);
      setLike(data.is_liked!);
      setBookmark(data.in_bookmark!);

      if (data && isLogin) {
        trackHistory(data);
      }
    } catch (err: any) {
      if (err === Errors.NotFound) {
        setNotFound(true);
      }
    } finally {
      setLoadingCount((preCount) => preCount + 1);
    }
  };

  const reload = () => {
    loadStory();
    loadPart();
  };

  const openComment = () => {
    if (!isLogin) {
      toast.warning(t("reading.loginRequired"));
      return;
    }
    setCommentOpen(true);
  };

  const isLocked = () => {
    if (!part) {
      return false;
    }

    return !part.is_free && !part.is_paid;
  };

  const handleLike = async () => {
    if (!isLogin) {
      toast.warning(t("reading.loginRequired"));
      return;
    }

    if (handleLock.current.like) {
      return;
    }
    handleLock.current.like = true;

    setLike(!like);
    try {
      if (!like) {
        await readingApi.likePart({
          story_id: story!.id,
          part_id: part!.id,
        });
      } else {
        await readingApi.undoLikePart({
          story_id: story!.id,
          part_id: part!.id,
        });
      }
    } finally {
      handleLock.current.like = false;
    }

  };

  const handleBookmark = async () => {
    if (!isLogin) {
      toast.warning(t("reading.loginRequired"));
      return;
    }

    if (handleLock.current.bookmark) {
      return;
    }
    handleLock.current.bookmark = true;
    try {
      if (bookmark) {
        await bookmarkApi.del({
          story_id: story!.id,
          part_id: part!.id,
        });
      } else {
        await bookmarkApi.add({
          story_id: story!.id,
          part_id: part!.id,
        });
      }
      setBookmark(!bookmark);
    } finally {
      handleLock.current.bookmark = false;
    }
  };

  const changeTheme = (value: string) => {
    if (!themeActionOpen) {
      return;
    }
    if (value !== theme) {
      setTheme(value);
      localStorage.setItem(themeCacheKey, value);
    }
  };

  const changeFontSize = (value: string) => {
    if (value !== fontSize) {
      setFontSize(value);
      localStorage.setItem(fontSizeCacheKey, value);
    }
  };

  const handlePrev = () => {
    if (part?.prev_part) {
      navigate("/part/" + part.story_id + "/" + part.prev_part.id);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      toast.warning(t("reading.noPrevChapter"));
    }
  };

  const handleNext = () => {
    if (part?.next_part) {
      navigate("/part/" + part.story_id + "/" + part.next_part.id);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      toast.warning(t("reading.noNextChapter"));
    }
  };

  const handleBuy = async () => {
    const result = await fundApi.buyPart({
      story_id: part!.story_id!,
      part_id: part!.id,
    });
    if (result.success) {
      toast.success(t("fund.purchaseChapterSuccess"));
      reload();
    } else if (result.reason === "not_enough_fund") {
      // todo: 提示余额不足并引导充值
    } else {
      toast.error(t("fund.purchaseChapterFailed"));
    }
  };

  const handleSubscribe = async () => {
    await fundApi.subscribeStory({
      story_id: part!.story_id!,
    });
    toast.success(t("fund.subscribeStorySuccess"));
    reload();
  };

  const renderHeader = () => {
    return (
      <div className={styles.header}>
        <div className={styles.headerContet + " content-wrap"}>
          <div className={styles.headerLeft}>
            <Link to="/" className={styles.logo}>
              <img src={isDark ? "/logo-dark.png" : "/logo.png"} alt="" />
            </Link>
            <div className={styles.breadcrumb}>
              <Link to={`/story/${story!.id}`}>
                <Typography className={styles.storyTitle} variant="h3">
                  {story!.title}
                </Typography>
              </Link>
              <Typography className={styles.partTitle} variant="h3">
                /
              </Typography>
              <Typography className={styles.partTitle} variant="h3">
                {part!.title}
              </Typography>
            </div>
            <Divider orientation="vertical" sx={{ height: "24px", margin: "0 24px" }} />
            <Typography className={styles.readProcess} variant="h5">
              {(story!.percent || 0) + "%"}
            </Typography>
          </div>
          <div className={styles.headerRight}>
            {isLogin ? (
              <>
                <Link to="/library" className={styles.link + " text-base font-medium"}>
                  {t("layout.nav.library")}
                </Link>
                <AddToLibraryButton story={story} className={styles.addTo} />
                <UserInfoPopover>
                  <div className="cursor-pointer">
                    <UserAvatar user={user!} size="s" />
                  </div>
                </UserInfoPopover>
              </>
            ) : (
              <div>
                <Link className={styles.login} to={loginPath} replace>
                  {t("common.login")}
                </Link>
                <Link className={styles.signup} to={signupPath} replace>
                  {t("common.signup")}
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderPayment = () => {
    return (
      <div className={styles.paymentWrap}>
        <PaymentRequiredIcon />
        <div className={styles.paymentActions}>
          <div className={styles.paymentButton} onClick={handleBuy}>
            <div>{t("fund.purchaseThisChapter")}</div>
            <div className="flex items-center mt-[2px]">
              <BCurrencyIcon />
              <span className="ml-2.5">{part!.price!} Points</span>
            </div>
          </div>
          <div className={styles.paymentButton} onClick={handleSubscribe}>
            <div>{t("fund.subscribeStory")}</div>
            <div className="flex items-center mt-[2px]">
              <BCurrencyIcon />
              <span className="ml-2.5 text-base">{t("fund.subscribeStoryTip")}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    const content = part!.content!;
    return (
      <div className={styles.content}>
        {content.split("\n").map((item, index) => {
          return (
            <Typography key={index} variant="body1" className={styles.contentItem} sx={{ fontSize }}>
              {item}
            </Typography>
          );
        })}
      </div>
    );
  };

  const renderFontSizeAction = () => {
    return (
      <ClickAwayListener onClickAway={() => setFontSizeActionOpen(false)}>
        <div
          className={[styles.action, styles.fontSizeAction, fontSizeActionOpen ? "open" : ""].join(" ")}
          onClick={() => setFontSizeActionOpen(!fontSizeActionOpen)}
        >
          <FontDnIcon className={styles.icon} />
          <div className={styles.fsCircleWrap}>
            {fontSizeOptions.map((item) => (
              <div
                key={item.value}
                className={styles.fsCircle + " " + (item.value === fontSize ? "active" : "")}
                title={item.label}
                onClick={() => changeFontSize(item.value)}
              ></div>
            ))}
          </div>
          <FontUpIcon className={styles.icon + " " + styles.iconRight} />
        </div>
      </ClickAwayListener>
    );
  };

  const renderThemeAction = () => {
    return (
      <ClickAwayListener onClickAway={() => setThemeActionOpen(false)}>
        <div
          className={[styles.action, styles.themeAction, themeActionOpen ? "open" : ""].join(" ")}
          onClick={() => setThemeActionOpen(!themeActionOpen)}
        >
          {themeOptions.map((item) => (
            <div
              key={item.value}
              className={`${styles.themeCircle} ${item.value === theme ? "active" : ""}`}
              style={{backgroundColor: item.color}}
              title={item.label}
              onClick={() => changeTheme(item.value)}
            ></div>
          ))}
        </div>
      </ClickAwayListener>
    );
  };

  const renderActions = () => {
    if (isLocked()) {
      return;
    }

    return (
      <div className={styles.actionsWrap}>
        <div className={styles.action} onClick={() => setCatalogOpen(true)}>
          <CatalogIcon className={styles.icon} />
        </div>
        <div className={styles.action} onClick={() => openComment()}>
          <CommentIcon className={styles.icon} />
        </div>
        <div className={[styles.action, styles.likeActive, like ? "active" : ""].join(" ")} onClick={handleLike}>
          <LikeIcon className={styles.icon} />
          <LikeActiveIcon className={styles.activeIcon} />
        </div>
        <div
          className={[styles.action, styles.bookmarkActive, bookmark ? "active" : ""].join(" ")}
          onClick={handleBookmark}
        >
          <BookmarkIcon className={styles.icon} />
          <BookmarkActiveIcon className={styles.activeIcon} />
        </div>
        {renderFontSizeAction()}
        {renderThemeAction()}
      </div>
    );
  };

  const renderBottom = () => {
    if (isLocked()) {
      return;
    }

    return (
      <div className={styles.foot}>
        <div className={styles.statWrap}>
          <div className={styles.readStat}>
            <ReadCountIcon />
            <Typography variant="body1" className={styles.statLabel}>
              {t("reading.read")}
            </Typography>
            <span className={styles.statNum}>{story!.read_count}</span>
          </div>
          <div className={styles.commentStat} onClick={() => openComment()}>
            <CommentCountIcon />
            <Typography variant="body1" className={styles.statLabel}>
              {t("reading.comment")}
            </Typography>
            <span className={styles.statNum}>
              {t("reading.commonsWithCount", { comment_count: story!.comment_count })}
            </span>
          </div>
        </div>
        <div className={styles.pagination}>
          <Button variant="contained" size="large" sx={{ height: "56px" }} onClick={handlePrev}>
            {t("reading.prevChapter")}
          </Button>
          <Button variant="contained" size="large" sx={{ height: "56px" }} onClick={handleNext}>
            {t("reading.nextChapter")}
          </Button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    loadPart();
    delayTrack.current = window.setTimeout(() => {
      readingApi.trackRead({
        story_id: +params.story_id!,
        part_id: +params.part_id!,
      });
    }, 3e3);

    return () => {
      if (delayTrack.current) {
        clearTimeout(delayTrack.current);
        delayTrack.current = null;
      }
    };
  }, [params.story_id, params.part_id]);

  useEffect(() => {
    loadStory();
  }, [params.story_id]);

  if (notFound) {
    return <Page404 />;
  }

  if (isLocked() && !isLogin) {
    return <Navigate to={loginPath} replace />;
  }

  const themeProps = {theme};

  return (
    <>
      <PageTitle title={(story?.title || "") + (part ? "-" + part.title : "")} />
      {!part || !story ? (
        <Spinner loading={loadingCount > 0} height="200px" />
      ) : (
        <div className={styles.page} {...themeProps}>
          {renderHeader()}
          <div className={styles.body}>
            <div className={styles.main}>
              <Typography variant="h2" className={styles.title}>
                {part?.title}
              </Typography>
              {isLocked() ? renderPayment() : renderContent()}
              {renderBottom()}
            </div>
            {renderActions()}
          </div>
          <CommentDrawer
            open={commentOpen}
            onClose={() => setCommentOpen(false)}
            story_id={+params.story_id!}
            part_id={+params.part_id!}
            title={t("reading.chapterComments")!}
          />
        </div>
      )}
      {story && !!params.part_id! && (
        <Catalog
          open={catalogOpen}
          story={story}
          part_id={+params.part_id!}
          part={part}
          onClose={() => setCatalogOpen(false)}
        />
      )}
      <Footer />
    </>
  );
}
