import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useNavigateLogin } from "@/hooks";
import FooterText from "./FooterText";

export default function LoginFooter() {
  const { t } = useTranslation();
  const {backPath: redirect} = useNavigateLogin();

  let link = "/signup";
  if (redirect) {
    link += "?redirect=" + encodeURIComponent(redirect);
  }

  return (
    <>
      <div className="text-center" style={{ marginTop: "130px" }}>
        <Link to={link} className="font-color-primary">
          <span className="font-color-grey mr-2">{t("login.noAccount")}</span>
          {t("common.signup")}
        </Link>
      </div>
      <FooterText />
    </>
  );
}
