import { StoryStatus, StoryOrder } from "@/config/enum";
import { PartEntity } from "@/entity/part";
import { StoryEntity } from "@/entity/story";
import $http from "@/utils/http";

export interface StoryListParams extends ApiListParams {
  category_id?: number;
  tag_id?: number;
  tag_ids?: number[];
  order?: StoryOrder;
  status?: StoryStatus;
}

export function storyList(params?: StoryListParams): Promise<ApiListResult<StoryEntity>> {
  return $http.request({
    url: "reading/storyList",
    method: "get",
    params,
  });
}

export function maybeLike(params: { count: number }): Promise<ApiListResult<StoryEntity>> {
  return $http.request({
    url: "reading/maybeLike",
    method: "get",
    params,
  });
}

export interface GetStoryParams {
  story_id: number;
  with_parts?: boolean;
  with_parts_count?: boolean;
  with_percent?: boolean;
  with_history_part?: boolean;
  with_first_part?: boolean;
  with_in_library?: boolean;
}
export function getStory(params: GetStoryParams): Promise<StoryEntity> {
  return $http.request({
    url: "reading/getStory",
    method: "get",
    params,
  });
}

export interface PartListParams {
  story_id: number;
}

export function catalog(params?: PartListParams): Promise<{ total: number; items: PartEntity[] }> {
  return $http.request({
    url: "reading/catalog",
    method: "get",
    params,
  });
}

export interface GetPartParams {
  story_id: number;
  part_id: number;
}
export function getPart(params: GetPartParams): Promise<PartEntity> {
  return $http.request({
    url: "reading/getPart",
    method: "get",
    params,
  });
}

export interface LikePartParams {
  story_id: number;
  part_id: number;
}
export const likePart = (params: LikePartParams) => {
  return $http.request({
    url: "reading/like",
    method: "post",
    data: params,
  });
};

export const undoLikePart = (params: LikePartParams) => {
  return $http.request({
    url: "reading/undoLike",
    method: "post",
    data: params,
  });
};

export function trackRead(params: { story_id: number; part_id: number }) {
  return $http.request({
    url: "reading/track",
    method: "post",
    data: params,
  });
}
