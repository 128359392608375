/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Link } from "react-router-dom";
import { StoryEntity } from "@/entity/story";
import { Typography } from "@mui/material";

interface Props {
  title: string;
	className?: string;
  items: Partial<StoryEntity>[];
}

export default function HotStoryList(props: Props) {
	const getNumColor = (index: number) => {
		const colorArr = ['#F14E71', '#FF8D29', '#39CCA0'];

		return colorArr[index] || 'var(--grey-color)';
	}

  return (
    <div className={props.className}>
      <div
        css={css`
          position: relative;
          height: 45px;
          line-height: 45px;
          background-color: var(--primary-color);
          width: 160px;
          text-align: center;
          color: #fff;
          font-size: 20px;
          font-weight: 500;
					margin-bottom: 24px;
          &:after {
            position: absolute;
            left: 100%;
            top: 0;
            display: block;
            content: "";
            width: 0;
            height: 0;
            width: 0;
            height: 0;
            border-left: 0;
            border-right: 20px solid transparent;
            border-bottom: 45px solid var(--primary-color);
          }
        `}
      >
        {props.title}
      </div>
      <ul className="pb-2">
        {props.items.map((item, index) => (
          <li key={item.id} className="flex mb-4">
            <Link to={`/story/${item.id}`}>
              <img
                src={item.cover}
                alt={item.title}
                css={css`
                  object-fit: cover;
                  width: 48px;
                  height: 64px;
                  border-radius: 3px;
                `}
              />
            </Link>
            <i css={css`
							color: ${getNumColor(index)};
							font-size: 16px;
							font-weight: 500;
							vertical-align: top;
							font-style: normal;
							margin: 0 8px;
						`} >{index >= 9 ? '' : '0'}{index + 1}</i>
            <div className="flex-1">
              <Link to={`/story/${item.id}`}>
                <Typography
                  variant="h4"
                  className="truncate"
                  sx={{
                    fontSize: "1rem",
                    fontWeight: 500,
                    margin: "0",
                  }}
                >
                  {item.title}
                </Typography>
              </Link>
              <Link to={`/category/${item.category?.id}`}>
                <Typography variant="body2" sx={{ fontSize: "12px", margin: '4px 0'}}>
                  {item.category?.name}
                </Typography>
              </Link>
              <Typography variant="body2" sx={{ fontSize: "12px" }}>
                {item.author?.username}
              </Typography>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
