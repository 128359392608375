import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputAdornment, OutlinedInput } from "@mui/material";
import { ReactComponent as SearchIcon } from "@/assets/icons/search2.svg";
import { ReactComponent as CloseIcon } from "@/assets/icons/close-bold.svg";

interface Props {
  search?: string;
  placeholder?: string;
  onSearch: (word?: string) => void;
}
export default function Search(props: Props) {
  const { t } = useTranslation();
  const placeholder = props.placeholder || t("common.search")!;
  const [value, setValue] = useState(props.search || "");

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setValue(e.target!.value || "");
  }

  function handleKeyDown(e: KeyboardEvent) {
    if (e.key === "Enter") {
      props.onSearch(value ? value?.trim() : "");
    }
  }

  function handleClear() {
    props.onSearch("");
  }

  useEffect(() => {
    setValue(props.search || "");
  }, [props.search]);

  return (
    <OutlinedInput
      className="w-full"
      value={value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      size="small"
      placeholder={placeholder}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="end">
          {props.search ? (
            <CloseIcon className="cursor-pointer" onClick={handleClear} />
          ) : (
            <span className="inline-block w-4 h-4"></span>
          )}
        </InputAdornment>
      }
    />
  );
}
