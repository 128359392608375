import $http from "@/utils/http";

interface UploadImgParams {
	file: File;
	md5: string;
}
export function uploadImage(params: UploadImgParams): Promise<{path: string}> {
	const data = new FormData();
	data.append("file", params.file);

	return $http.request({
		url: "upload/image",
		method: "post",
		data,
		params : {
			md5: params.md5,
		}
	});
}