import $http from "@/utils/http";
import { PageEntity } from "@/entity/page";

export interface PageListParams {
  lang: string;
}
export function list(params: PageListParams): Promise<{ items: Array<Omit<PageEntity, "content">> }> {
  return $http.request({
    url: "page/list",
    method: "get",
    params,
  });
}

export interface PageDetailParams {
  id: number;
}
export function detail(params: PageDetailParams): Promise<PageEntity> {
  return $http.request({
    url: "page/detail",
    method: "get",
    params,
  });
}
