import { useState, useRef, useEffect } from "react";
import { Box, Button, Grid, Dialog, Paper, Tab, DialogContent, DialogTitle } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as writingApi from "@/api/writing";
import { PartEntity } from "@/entity/part";
import { StoryEntity } from "@/entity/story";
import Spinner from "@/components/Spinner";
import StoryCover from "./StoryCover";
import StoryDetails from "./StoryDetails";
import styles from "./PublishStoryDialog.module.css";

interface Props {
  part: PartEntity;
  open: boolean;
  onClose: () => void;
  onPublish: () => void;
}

export default function PublishStoryDialog(props: Props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const formRef = useRef<HTMLFormElement>(null);
  const [story, setStory] = useState<StoryEntity | null>(null);

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        const data = await writingApi.myStoryDetail({ story_id: props.part?.story_id });
        setStory(data);
        if (data.is_published && data.is_commitment) {
          props.onPublish();
        }
      } finally {
        setLoading(false);
      }
    };

    if (props.open && props.part?.story_id) {
      load();
    }
  }, [props.open, props.part?.story_id]);

  const handlePublish = () => {
    formRef.current?.requestSubmit();
  };

  const afterDetailsSave = async () => {
    try {
      setLoading(true);
      await writingApi.publishStory({ story_id: props.part?.story_id! });
    } finally {
      setLoading(false);
    }
    props.onPublish();
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="lg" scroll="paper">
      <DialogTitle className={styles.header}>
        <Link to="/writing">
          <Button variant="text" color="info" className={styles.back}>
            <ArrowBackIosIcon />
          </Button>
        </Link>
        <div className={styles.meta}>
          <div className={styles.title1}>
            {t("writing.beforePublish")} {props.part.title}
          </div>
          <span className={styles.title2}>{story?.title || "-"}</span>
        </div>
        <div className={styles.actions}>
          <Button variant="outlined" color="info" size="small" className={styles.btn} onClick={props.onClose}>
            {t("common.close")}
          </Button>
          <Button variant="contained" color="primary" size="small" className={styles.btn} onClick={handlePublish}>
            {t("common.publish")}
          </Button>
        </div>
      </DialogTitle>
      <DialogContent>
        <Spinner loading={loading}>
          <Box className="p-6">
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs={3}>
                <StoryCover isEdit story_id={story?.id} cover={story?.cover} />
              </Grid>
              <Grid item xs={8}>
                <Paper elevation={3}>
                  <TabContext value="info">
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList>
                        <Tab label={t("writing.storyDetails")} value="info" />
                      </TabList>
                    </Box>
                    <TabPanel value="info">
                      {story ? (
                        <StoryDetails ref={formRef} isPublish story={story} onSaveSuccess={afterDetailsSave} />
                      ) : (
                        <div style={{width: "730px", height: "500px"}}></div>
                      )}
                    </TabPanel>
                  </TabContext>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Spinner>
      </DialogContent>
    </Dialog>
  );
}
