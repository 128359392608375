import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Button, Drawer, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CommentEntity } from "@/entity/comment";
import * as commentApi from "@/api/comment";
import { NotifyType } from "@/config/enum";
import { useConfirm } from "@/hooks";
import { omit } from "lodash-es";
import CommentInput from "./CommentInput";
import CommentCard from "./CommentCard";
import Spinner from "@/components/Spinner";
import { ReactComponent as CloseIcon } from "@/assets/icons/close-comment.svg";
import styles from "./CommentDrawer.module.css";

interface Props {
  open: boolean;
  onClose: () => void;
  story_id: number;
  part_id: number;
  title?: string;
}
export default function CommentDrawer(props: Props) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const notify_type = searchParams.has("notify_type") ? +searchParams.get("notify_type")! : 0;
  const notify_id = searchParams.has("notify_id") ? +searchParams.get("notify_id")! : 0;
  // 通过通知跳转过来的，需要加载通知评论
  const needLoadNotifyComment = [NotifyType.CommentAutor, NotifyType.CommentReply].includes(notify_type) && !!notify_id;
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { confirm } = useConfirm();
  const [loading, setLoading] = useState(false);
  const [loadLock, setLoadLock] = useState("");
  const [loadParentId, setLoadParentId] = useState<number | null>(null);
  const [replyto, setReplyto] = useState<CommentEntity>();
  const [state, setState] = useState<commentApi.CommentListResult>({
    items: [],
    total: 0,
    cursor: 0,
    has_more: false,
  });

  useEffect(() => {
    let notifyComments: commentApi.NotifyCommentListResultItem[] = [];
    const loadNotifyComment = async () => {
      try {
        setLoading(true);
        const data = await commentApi.notifyCommentList({
          notify_id,
        });
        notifyComments = data.items;
      } finally {
        setLoading(false);
      }
    };
    const load = async () => {
      if (needLoadNotifyComment) {
        await loadNotifyComment();
      }
      try {
        setLoading(true);
        const data = await commentApi.commentList({ story_id: props.story_id, part_id: props.part_id, limit: 2 });
        const ids = new Set(notifyComments.map((it) => it.id));
        setState({
          ...data,
          items: [
            ...[...notifyComments].map((cm) => ({
              ...omit(cm, "items"),
              replyData: {
                items: cm.items,
              },
            })),
            ...data.items.filter((item) => !ids.has(item.id)),
          ],
        });
      } finally {
        setLoading(false);
      }
    };
    if (props.open && (!loadLock || loadLock !== [props.story_id, props.part_id].join("/"))) {
      load();
      setLoadLock([props.story_id, props.part_id].join("/"));
    }
  }, [props.story_id, props.part_id, props.open, loadLock, needLoadNotifyComment, notify_id]);

  const loadMore = async () => {
    try {
      setLoading(true);
      setLoadParentId(0);
      const data = await commentApi.commentList({
        story_id: props.story_id,
        part_id: props.part_id,
        limit: 100,
        cursor: state.cursor,
      });
      state.items = [...state.items, ...data.items];
      state.cursor = data.cursor;
      state.total = data.total;
      state.has_more = data.has_more;
      setState(state);
    } finally {
      setLoading(false);
    }
  };

  const loadReplies = async (parent_id: number) => {
    try {
      setLoading(true);
      setLoadParentId(parent_id);
      const item = state.items.find((it) => it.id === parent_id);
      const data = await commentApi.commentList({
        story_id: props.story_id,
        part_id: props.part_id,
        parent_id,
        cursor: item?.replyData?.cursor || 0,
        limit: 10,
      });
      if (item) {
        const ids = new Set(item.replyData?.items?.map((it) => it.id) || []);
        item.replyData = {
          cursor: data.cursor,
          has_more: data.has_more,
          items: [...(item.replyData?.items || []), ...data.items.filter((item) => !ids.has(item.id))],
          expand: true,
        };
      }
      setState(state);
    } finally {
      setLoading(false);
    }
  };

  const collapseReplies = (parent_id: number) => {
    const item = state.items.find((it) => it.id === parent_id);
    if (item?.replyData) {
      item.replyData.expand = false;
      setState({ ...state });
    }
  };

  const afterCreateComment = (comment: CommentEntity) => {
    if (comment.parent_id) {
      const parent = state.items.find((item) => item.id === comment.parent_id);
      if (parent) {
        parent.reply_count += 1;
        parent.replyData = parent.replyData || {};
        parent.replyData.items = [...(parent.replyData.items || []), comment];
        setState({ ...state });
      }
    } else {
      state.items = [comment, ...state.items];
      setState({ ...state });
    }

    setReplyto(void 0);
  };

  const handleReply = (to: CommentEntity) => {
    setReplyto(to);
    inputRef.current?.focus();
  };

  const handleDelete = async (comment: CommentEntity) => {
    if (
      await confirm({
        title: t("reading.commentDeleteConfirmTitle"),
        content: t("reading.commentDeleteConfirmMsg"),
      })
    ) {
      await commentApi.deleteComment({ story_id: props.story_id, part_id: props.part_id, comment_id: comment.id });
      if (comment.parent_id) {
        const parent = state.items.find((item) => item.id === comment.parent_id);
        if (parent) {
          parent.reply_count -= 1;
          parent.replyData = parent.replyData || {};
          parent.replyData.items = parent.replyData.items?.filter((item) => item.id !== comment.id);
          setState({ ...state });
        }
      } else {
        state.items = state.items.filter((item) => item.id !== comment.id);
        setState({ ...state });
      }
    }
  };

  return (
    <Drawer anchor="right" open={props.open} onClose={props.onClose}>
      <Spinner loading={loading && !loadParentId && !state.cursor} className="h-full">
        <div className="w-[520px] h-full">
          <div className={styles.commentList}>
            <div className={styles.commentListHead}>
              <Typography
                variant="h3"
                sx={{
                  fontSize: "24px",
                  lineHeight: "35px",
                  fontWeight: 500,
                }}
              >
                {props.title || t("common.comments")}
              </Typography>
              <span className={styles.commentCount}>{state.total}</span>
              <CloseIcon className={styles.close} onClick={props.onClose} />
            </div>
            <div className={styles.commentListBody}>
              {state.items.length > 0 ? (
                state.items.map((item) => (
                  <div key={item.id}>
                    <CommentCard comment={item} handleReply={(to) => handleReply(to)} onDelete={handleDelete}>
                      <>
                        {(item.replyData?.expand || item.replyData?.items?.length) && (
                          <div className={styles.commentReplies}>
                            {item.replyData?.items?.map((child) => (
                              <CommentCard
                                key={child.id}
                                comment={child}
                                isChild
                                handleReply={(to) => handleReply(to)}
                                onDelete={handleDelete}
                              />
                            ))}
                          </div>
                        )}
                        {!!loading && loadParentId === item.id && <Spinner loading tip={false} />}
                        {item.reply_count > 0 && (
                          <div className={styles.expandReplies}>
                            {!item.replyData?.expand && (item.replyData?.items?.length || 0) < item.reply_count && (
                              <button className={styles.expandButton} onClick={() => loadReplies(item.id)}>
                                {t("reading.expandReplies", { count: item.reply_count })}
                              </button>
                            )}
                            {item.replyData?.expand && item.replyData?.has_more && (
                              <button className={styles.expandButton} onClick={() => loadReplies(item.id)}>
                                {t("reading.expandMoreReplies")}
                              </button>
                            )}
                            {item.replyData?.expand && (
                              <button className={styles.expandButton} onClick={() => collapseReplies(item.id)}>
                                {t("reading.collapseReplies")}
                              </button>
                            )}
                          </div>
                        )}
                      </>
                    </CommentCard>
                  </div>
                ))
              ) : (
                <div className="flex h-full justify-center items-center font-color-grey text-[18px]">
                  {t("reading.noComment")}
                </div>
              )}
              {!!loading && !loadParentId && state?.cursor > 0 && <Spinner loading tip={false} />}
              {state.has_more && !loading && (
                <div className="py-3 text-center">
                  <Button variant="contained" color="primary" onClick={loadMore}>
                    {t("reading.loadMore")}
                  </Button>
                </div>
              )}
            </div>
            <div className={styles.commentListFoot}>
              <CommentInput
                story_id={props.story_id}
                part_id={props.part_id}
                replyTo={replyto}
                onSuccess={(comment) => afterCreateComment(comment)}
                ref={inputRef}
              />
            </div>
          </div>
        </div>
      </Spinner>
    </Drawer>
  );
}
