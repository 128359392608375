import SparkMD5 from "spark-md5";
import i18n from "i18next";

export function isPC() {
  if (window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
    return false;
  }

  if (window.navigator.platform) {
    // XXX: Android的navigator.platform是Linux，所以这里不要加Linux
    return window.navigator.platform.indexOf("Win") > -1  || window.navigator.platform.indexOf("Mac") > -1;
  }

  if (typeof window.orientation === 'undefined') {
    return false;
  }
  
  if ('ontouchstart' in document.documentElement) {
    return false;
  }

  return true;
}

function isArray(obj: any) {
  return Array.isArray ? Array.isArray(obj) : Object.prototype.toString.call(obj) === '[object Array]';
}

export function serializeQueryParams(params: Record<string, void | null | string | number | Array<string|number>>) {
  var res = [];
  for (var name in params) {
    if (Object.prototype.hasOwnProperty.call(params, name) && params[name] != null) {
      var value = params[name];
      if (isArray(value)) {
        value = (value as Array<string | number>).join(",");
      }
      res.push(encodeURIComponent(name) + "=" + encodeURIComponent(value as string | number));
    }
  }
  return res.join("&");
}

export function isFormData(v: any) {
  return Object.prototype.toString.call(v) === "[object FormData]";
}

export function getFileMd5(file: File) {
  return new Promise<string>((resolve, reject) => {
    const blobSlice = File.prototype.slice || (File.prototype as any).mozSlice || (File.prototype as any).webkitSlice;
    const chunkSize = 2 * 1024 * 1024;
    const chunks = Math.ceil(file.size / chunkSize);
    let currentChunk = 0;
    const spark = new SparkMD5();
    const fileReader = new FileReader();

    fileReader.onload = (e: any) => {
      spark.appendBinary(e.target.result);
      currentChunk += 1;

      if (currentChunk < chunks) {
        loadNext();
      } else {
        resolve(spark.end());
      }
    };

    fileReader.onerror = () => {
      reject(new Error(i18n.t("oops, something went wrong.") as string));
    };

    function loadNext() {
      const start = currentChunk * chunkSize;
      const end = start + chunkSize >= file.size ? file.size : start + chunkSize;

      fileReader.readAsBinaryString(blobSlice.call(file, start, end));
    }

    loadNext();
  });
}

