/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { PropsWithChildren } from "react";
import { ReactComponent as BackIcon } from "@/assets/icons/back-arrow.svg";

interface Props {
	onBack: () => void;
}

export default function LoginTitle(props: PropsWithChildren<Props>) {
	return (
		<div className="font-color-default" css={css`
			display: flex;
			margin-left: -16px;
			ling-height: 24px;
			font-size: 18px;
			font-weight: 500;
			vertical-align: top;
			padding: 24px 0;
		`}>
			<BackIcon className="mr-4 cursor-pointer" onClick={props.onBack} />
			{props.children}
		</div>
	)

}