import { BookmarkEntity, BookmarkStoryEntity } from "@/entity/bookmark";
import $http from "@/utils/http";

export function storyList(params: ApiListParams): Promise<ApiListResult<BookmarkStoryEntity>> {
	return $http.request({
		url: "bookmark/story/list",
		method: "get",
		params,
	});
}

export interface BookmarkPartListParams extends ApiListParams {
	story_id: number;
}
export function partList(params: BookmarkPartListParams): Promise<ApiListResult<BookmarkEntity>> {
	return $http.request({
		url: "bookmark/part/list",
		method: "get",
		params,
	});
}

export type AddBookmarkParams = Pick<BookmarkEntity, "story_id" | "part_id">;
export function add(params: AddBookmarkParams): Promise<BookmarkEntity> {
	return $http.request({
		url: "bookmark/add",
		method: "post",
		data: params,
	});
}

export type DeleteBookmarkStoryParams = Pick<BookmarkEntity, "story_id">;
export function delStory(params: DeleteBookmarkStoryParams): Promise<boolean> {
	return $http.request({
		url: "bookmark/story/delete",
		method: "post",
		data: params,
	});
}

export type DeleteBookmarkParams = Pick<BookmarkEntity, "story_id" | "part_id">;
export function del(params: DeleteBookmarkParams): Promise<boolean> {
	return $http.request({
		url: "bookmark/delete",
		method: "post",
		data: params,
	});
}